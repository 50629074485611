@import '~@carbon/themes/scss/themes';

$feature-flags: (
        grid-columns-16: true
);

$carbon--theme: map-merge($carbon--theme--white, (
        'interactive-01': #005EB8,
        'interactive-02': #8c8c8c,
//        'interactive-02': #83786F,
        'interactive-03': #005EB8,
        'interactive-04': #005EB8,
        'ui-background': #ffffff,
        'ui-01': #f3f3f3,
        'ui-02': #ffffff,
        'ui-03': #dcdcdc,
        'ui-04': #8c8c8c,
        'ui-05': #171717,
        'text-01': #171717,
        'text-02': #8c8c8c,
//        'text-02': #565656,
        'text-03': #8c8c8c,
        'text-04': #ffffff,
        'icon-01': #171717,
//        'icon-02': #565656,
        'icon-02': #8c8c8c,
        'icon-03': #ffffff,
        'link-01': #005EB8,
        'link-02': #005EB8,
        'field-01': #f3f3f3,
        'field-02': #ffffff,
        'inverse-01': #ffffff,
        'inverse-02': #3d3d3d,
        'support-01': #da1e28,
        'support-02': #24a148,
        'support-03': #f2A900,
        'support-04': #005EB8,
        'inverse-support-01': #fb4b53,
        'inverse-support-02': #3dbb61,
        'inverse-support-03': #fdd13a,
        'inverse-support-04': #005EB8,
        'overlay-01': rgba(23, 23, 23, 0.5),
        'focus': #005EB8,
        'hover-primary': darken(#005EB8, 10%),
        'active-primary': #005EB8,
        'hover-primary-text': #005EB8,
        'hover-secondary': #4c4c4c,
//        'active-secondary': #6f6f6f,
        'active-secondary': #8c8c8c,

        'hover-tertiary': #005EB8,
        'active-tertiary': #005EB8,
 //       'hover-ui': #e5e5e5,
        'hover-ui': #E8EDEE,

        'active-ui': #bebebe,
        'selected-ui': #dcdcdc,
        'hover-selected-ui': #cacaca,
        'hover-danger': #ba1b23,
        'active-danger': #750e13,
//        'hover-row': #e5e5e5,
        'hover-row': #8c8c8c,
        'visited-link': #8a3ffc,
        'disabled-01': #f3f3f3,
        'disabled-02': #bebebe,
        'disabled-03': #8c8c8c,
        'highlight': #c9deff,
        'skeleton-01': #e5e5e5,
        'skeleton-02': #bebebe,
        'brand-01': #005EB8,
        'brand-02': #171717,
        'brand-03': #005EB8,
        'active-01': #bebebe,
        'hover-field': #e5e5e5,
        'inverse-link': #ffffff,
));

@include carbon--theme();




// import Carbon styles here
//@import '~carbon-components/scss/globals/scss/styles.scss';


@import '~carbon-components/scss/components/button/button';
@import '~carbon-components/scss/components/accordion/accordion';
@import '~carbon-components/scss/components/select/select';
@import '~carbon-components/scss/components/skeleton/skeleton';
@import '~carbon-components/scss/components/tabs/tabs';
@import '~carbon-components/scss/components/modal/modal';



@import '~carbon-components/scss/components/tooltip/tooltip';
@import '~carbon-components/scss/components/date-picker/date-picker';
@import '~carbon-components/scss/components/ui-shell/ui-shell';
@import '~carbon-components/scss/components/ui-shell/navigation-menu';
@import '~carbon-components/scss/components/text-input/text-input';
@import '~carbon-components/scss/components/notification/inline-notification';
@import '~carbon-components/scss/components/notification/tokens';
@import '~carbon-components/scss/components/structured-list/structured-list';
@import '~carbon-components/scss/components/notification/toast-notification';
@import '~carbon-components/scss/globals/grid/grid';
@import '~carbon-components/scss/components/file-uploader/file-uploader';






.ms-fabric {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, sans-serif;
}


.ms-Fabric {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, sans-serif;
}



.bx--tab-content:focus {
  outline: transparent none 0;
}



.ms-spinButton-input {
  background-color: #E8EDEE;
}


/* New Style for v11

@use '~@carbon/themes/scss/modules/themes';
@use '~@carbon/themes' with (
  $fallback: themes.$g100,
  $theme: (
    token-01: #000000,
  ),
);

@import 'node_modules/carbon-components/scss/globals/scss/styles';
*/
