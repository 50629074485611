:root {
  --interactiveObjectColour: #005EB8;
  --interactiveObjectHighlightColour: #74ad31;
  --titleColour: #323130;
  --blockBackgroundColour: #E8EDEE;
  --selectedServiceColour: #F2A900;
  --inverseTextColour: #ffffff;
}


$out-of-range-color: lighten(#333, 40%) ;
$out-of-range-bg-color: lighten(#333, 70%) ;

$calendar-border: var(--blockBackgroundColour);
$cell-border: #E8EDEE;
$border-color: var(--blockBackgroundColour);

// Each calendar segment is 1/7th as we're running a 7 day calendar
$segment-width: 0.14286% ;

$time-selection-color: white ;
$time-selection-bg-color: rgba(0, 0, 0, 0.5) ;
$date-selection-bg-color: rgba(0, 0, 0, 0.1) ;


$event-bg: #005EB8;
$event-border: var(--interactiveObjectColour) ;
$event-outline: var(--interactiveObjectColour);
$event-color: white;
$event-border-radius: 5px ;
$event-padding: 2px 5px ;
$event-zindex: 4 ;

$btn-color: var(--titleColour) ;
$btn-bg: white ;
$btn-border: #E8EDEE ;

$current-time-color: unset; //var(--interactiveObjectHighlightColour) ;

$rbc-css-prefix: rbc-i ;

$today-highlight-bg: unset;



@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles'; // if using DnD

.articleList {
    display: block;
    list-style-type: square;
    margin-top: 1em;
    margin-bottom: 2rem;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }

.articleList li {
  padding-top: 1rem;
}

div#articleBody p {padding-top: 5px}


.gridTitle, .ms-Label {
  outline: transparent none medium;
  position: relative;
  align-items: center;
  font-size: 14px;
  font-family: inherit;
  color: var(--titleColour);
//  display: inline-block;
  flex-grow: 0;
  font-weight: 600;
  padding: 0 4px 0 10px;
  border: medium none;
  background-color: transparent;
  border-radius: 2px;
  line-height: 28px;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
}

.cap_formPanel {
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: var(--blockBackgroundColour);
  padding-top: 1rem;
  padding-bottom: 1px;

}


.capacityColumn {
  max-width: 400px;
  width: 25%;

}

.selectedService {
  background-color: #FFB81C;
}

.rbc-header, .rbc-time-gutter, .rbc-time-header-gutter {
  background-color: var(--blockBackgroundColour);
}


.cOrganisation {
  background-color: var(--interactiveObjectColour);
  color: white;
  font-weight: 600;
  writing-mode: sideways-rl;
  width: 3rem;
  min-height: 100px;
  height: 200px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: 0;


}

.cName {
  background-color: var(--blockBackgroundColour);
  writing-mode: sideways-rl;
  width: 20px;
  font-weight: 600;
  padding-left: 0.5rem;
  padding-top: 1rem;
  padding-right: 0.1rem;

}

.cStudentId {
  background-color: var(--blockBackgroundColour);
  writing-mode: sideways-rl;
  width: 25px;
  font-weight: 600;
  padding-right: 0.5rem;
  padding-left: 0.1rem;
  padding-top: 1rem;
}

.cComment {
  padding: 1rem;
  line-height: 1.2rem;
}

.sectionHeading, .cQuestionTitle {
  padding-bottom: 1rem;

}


.cPageBreak {
  clear: both;
}


@media print {
  div.cPageBreak { page-break-after: always; }

}

.cQuestionTitle {
  font-size: 14px;
  background-color: var(--blockBackgroundColour);
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: bold;
}

.cAlphabetic {
  list-style-type: upper-alpha;

}

.cHeatMapContainer {
  width: 800px;
}



@media print {

  div.qLabelContainer {
    width: 12cm;
    height: 4cm;
  }

}


.qLabelContainer {

//  border: 1px solid black;
  width: 10cm;
  height: 4cm;
  background-color: #3A1B55;
  color: white;
  margin-bottom: 0.5cm;
  padding: 0.5cm;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
//  align-content: ;



}

.qModelLabel {

  font-weight: bold;
  font-size: 0.5cm;
  flex-grow: 0;

  padding-bottom: 2mm;

}

.qModelNumber {
  font-weight: bold;
  font-size: 0.5cm;
  flex-grow:0;
}

.qLinkType {
  width: 1cm;
//  height: 100%;
  writing-mode: sideways-lr;
  font-weight: bold;
//  background-color: white;
  flex-grow:0;
}



.qQRCODE {

}


.qrImage {
  width: 3cm;
  height: 3cm;
}



.qLeftColumn {
  flex-grow: 1;

  display:flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
}




.qLinkTypeColumn {
  flex-grow:0;
  flex-shrink: 0;
  flex-basis: 1cm;
  position: relative;
  left: 3mm;
//  align-content: center;
//  align-self: center;


}

.qrCodeColumn {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 2.5cm;
}

.qAECCLogo {
  flex-grow: 1;

}



.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor .rbc-addons-dnd-resize-ns-icon {
  display: none;
  border-top:  3px double;
  margin: 0 auto;
  width: 20%;
  min-width: 10px;
  cursor: ns-resize;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor {
//  background-color: white;
  color: white;
}


.selectableService {
  border: 1px solid black;
  padding: 10px;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  cursor: grab;
  color: white;
//  font-weight: bold;
  background-color: var(--interactiveObjectColour);
}

.selectableServiceEditContainer {
  display: inline;
  cursor: pointer;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.selectableServiceDragContainer {
  display: inline;

}

.selectableServiceOptionsContainer {

}

.selectableServiceTitleContainer {

}

.selectableServiceDropCount {
  display: inline;
  top: -2px;
  position: relative;
}

.serviceSelected {
  background-color: var(--selectedServiceColour) !important;
}

.rbc-event {
  border-radius: unset;
}


.informationIcon {
  position: relative;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  top: 2px;
}


.informationIconRight {
  position: relative;
  margin-left: 0.5rem;
  top: 2px;
}




.regularIcon {
  position: relative;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.tooltipContainer {

}

.tooltipContainer p {
  font-size: 14px;

  margin-bottom: 0.5rem;
}

.menuButton {
  float: right;
  cursor: pointer;
  overflow: hidden;
  height: 21px;
  top: -2px;
  position: relative;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  font-weight: normal;
  font-size: 14px;
}

.menuButton:hover {
  color: white;
  background-color: var(--interactiveObjectColour);

}


.menuButton2 {
  float: right;
  cursor: pointer;
  overflow: hidden;
  height: 21px;
  top: -2px;
  position: relative;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  font-weight: normal;
  font-size: 14px;
}

.menuButton2:hover {
  color: white;
  background-color: var(--interactiveObjectColour);

}


.rbc-toolbar button {
  border-radius: 0;
  border: 1px solid #adadad;
  box-shadow: none !important;
}

.capacityTitle {
  padding-top: 1rem;
  margin-bottom: 5px;
}

.capacityTable {
  width: 100%;
}

.capacityTable td {

  padding-bottom: 5px;
}

.maxWidth {
  width: 300px;
}

.inlineToggleRight {
  text-align: right;
  //background-color: red;
}

.inlineToggleRight .ms-Toggle-stateText {
  padding-right: 0rem;
  min-width: 1.5rem;
}

.inlineToggleRight .ms-Toggle-background {
  top: 3px;
}

.inlineToggleRight .ms-Toggle-label {
  font-weight: 400 !important;
  text-align: right;
  margin-right: 0.5rem;
}


///NEW STYLE
div.panelContainer h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 1rem;
}

div.panelContainer p {
  font-size: 14px;
  margin-bottom: 1rem;
}

.marginBelow {
  margin-bottom: 1rem;
}

.positionRight {
  display: block;
  float: right;
  clear: both;
  margin-bottom: 1rem;

}

.ms-TooltipHost {
  line-height: 28px;
  display: inline-block;
  align-items: center ;
  position: relative;
}


.permanentFooterContainer {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding-top: 0.5rem;
}

.permanentFooter {
  background-color: var(--blockBackgroundColour);
  color: black;
  font-weight: bold;
  padding-top: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
}

div.cds--chart-holder {
  top: -12px;
}



.targetCourse {
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
}



.targetCourseContainer {
  display: inline-flex;
  width: 100%;

}

.targetCourseTitle {
  width: 185px;
}

.sectionBreak {
  border-top: 2px solid white;
  border-bottom: unset;
  border-left: unset;
  border-right: unset;

}


table.sessionDetails {
  border-collapse: collapse;
  width: 100%;
}

table.sessionDetails td {
  border-right: 2px solid white;
  padding-right: 1rem;
}

table.sessionDetails td {
  border-right: 2px solid white;
  padding-left: 1rem;
  padding-right: 1rem;
}


table.sessionDetails td:first-child {
  padding-left: 0;
}

table.sessionDetails td:last-child {
  border-right: none;
}

table.sessionDetails label.ms-Label {
  padding-left: 0;
}


.notebookIcon {
  position: absolute;
  bottom: 0;
}


.notebookIconMonth {
  margin-right: 0.3rem;
  position: relative;
  top: 2px;

}

.eventMonth {
  display: block;
  height: 1.5rem;

}

.nudgeTitle {
  display: block;
  position: relative;
  top: 4px;
  font-weight: bold;
}

.downloadIcon {
  margin-left: 0.5rem;
}


.testStyle {
  font-weight: bold;
  color: yellow;
}

.extendBottom {
  padding-bottom: 10rem;
}

.eventTitle {
  padding-top: 0.2rem;
}

.eventLearners {
  padding-top: 0.2rem;
}


.pdfStuffContainer {
  display: none;
  visibility: hidden;
}

.pdfStuff {


}

.pageMargins {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  padding-top: 2rem;
}

span.answer {
  font-weight: normal;
  line-height: 1.2rem;
  display: inline-block;
}




h3.pdfSectionTitle {
  font-size: 24pt;
  font-weight: bold;
  border-bottom: 2px solid #0f7dc7;
  margin-bottom: 1rem;
}



.pdfQuestion {
  font-weight: bold;
  font-size: 16pt;
  line-height: 1.3rem;
  padding-bottom: 2mm;
  padding-right: 10mm;
}

.pdfAnswer {
  font-weight: normal;
  font-size: 16pt;
  line-height: 1.3rem;
  padding-bottom: 2mm;
  padding-top: 1mm;
}

.pdfTable {
  margin-top: 5mm;
  margin-bottom: 5mm;
}

.pdfTableHeader {
  font-weight: bold;
  font-size: 16pt;
  padding-bottom: 2mm;
}


table.pdfCapacityTable {
  margin-top: 10mm;
}

table.pdfCapacityTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.pageSectionBreak {
  display: block;
  page-break-after: always;
  height: 4cm;
}

.pdfMainLogoContainerOuter {

/*
  position: absolute;
  left: -20mm;
  width: 100%;
*/
}

div#selectableServiceContainer {
    overflow-y: scroll;
    max-height: 300px;
    scrollbar-width: thin;
    scroll-padding: 1rem 1rem 1rem 1rem;


    display: block;
}

