.formRow {
  margin-bottom: 15px;

}

.formPanel {
  width: 50%;
  padding-left: 30px;
  border-left: 3px solid #BFB8AF;
}

.welcome {
  padding: 15px 0 15px 0;
}

.validateWarn {
  border-top: 1px solid #e64313 !important;
  border-left: 1px solid #e64313 !important;
  border-right: 1px solid #e64313 !important;
  border-bottom: 1px solid #e64313 !important;
}


.defaultInput {
  border-left: 1px solid transparent ;
  border-right: 1px solid transparent ;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #cccccc ;
  padding: 10px 10px 10px 10px ;
  margin-top: 5px;
  margin-bottom: 5px;
  width:100% ;
  font-size: 1rem ;
  outline: 0px solid transparent ;
}


.validationWarning {
  padding: 5px 15px 5px 15px;
  color: #e64313;
  font-size: 12px;
  font-weight: bold;


  color: cmyk(0 71 90 10)
}