/*
 *
 * DARK/LIGHT THEME
 */
/*
 *
 * ELEMENTS
 */
/*
 *
 * GRID
 */
.cds--chart-holder {
  --cds-charts-1-1-1: #6929c4;
  --cds-charts-1-1-1-hovered: #5923a6;
  --cds-charts-1-2-1: #002d9c;
  --cds-charts-1-2-1-hovered: #002378;
  --cds-charts-1-3-1: #1192e8;
  --cds-charts-1-3-1-hovered: #0f7dc7;
  --cds-charts-1-4-1: #007d79;
  --cds-charts-1-4-1-hovered: #005956;
  --cds-charts-2-1-1: #6929c4;
  --cds-charts-2-1-1-hovered: #5923a6;
  --cds-charts-2-1-2: #009d9a;
  --cds-charts-2-1-2-hovered: #007977;
  --cds-charts-2-2-1: #8a3ffc;
  --cds-charts-2-2-1-hovered: #751cfb;
  --cds-charts-2-2-2: #520408;
  --cds-charts-2-2-2-hovered: #300205;
  --cds-charts-2-3-1: #9f1853;
  --cds-charts-2-3-1-hovered: #801343;
  --cds-charts-2-3-2: #520408;
  --cds-charts-2-3-2-hovered: #300205;
  --cds-charts-2-4-1: #1192e8;
  --cds-charts-2-4-1-hovered: #0f7dc7;
  --cds-charts-2-4-2: #005d5d;
  --cds-charts-2-4-2-hovered: #003939;
  --cds-charts-2-5-1: #009d9a;
  --cds-charts-2-5-1-hovered: #007977;
  --cds-charts-2-5-2: #002d9c;
  --cds-charts-2-5-2-hovered: #002378;
  --cds-charts-3-1-1: #ee5396;
  --cds-charts-3-1-1-hovered: #eb3382;
  --cds-charts-3-1-2: #1192e8;
  --cds-charts-3-1-2-hovered: #0f7dc7;
  --cds-charts-3-1-3: #6929c4;
  --cds-charts-3-1-3-hovered: #5923a6;
  --cds-charts-3-2-1: #9f1853;
  --cds-charts-3-2-1-hovered: #801343;
  --cds-charts-3-2-2: #fa4d56;
  --cds-charts-3-2-2-hovered: #f92a35;
  --cds-charts-3-2-3: #520408;
  --cds-charts-3-2-3-hovered: #300205;
  --cds-charts-3-3-1: #a56eff;
  --cds-charts-3-3-1-hovered: #8f4aff;
  --cds-charts-3-3-2: #005d5d;
  --cds-charts-3-3-2-hovered: #003939;
  --cds-charts-3-3-3: #002d9c;
  --cds-charts-3-3-3-hovered: #002378;
  --cds-charts-3-4-1: #a56eff;
  --cds-charts-3-4-1-hovered: #8f4aff;
  --cds-charts-3-4-2: #005d5d;
  --cds-charts-3-4-2-hovered: #003939;
  --cds-charts-3-4-3: #9f1853;
  --cds-charts-3-4-3-hovered: #801343;
  --cds-charts-3-5-1: #012749;
  --cds-charts-3-5-1-hovered: #011426;
  --cds-charts-3-5-2: #6929c4;
  --cds-charts-3-5-2-hovered: #5923a6;
  --cds-charts-3-5-3: #009d9a;
  --cds-charts-3-5-3-hovered: #007977;
  --cds-charts-4-1-1: #6929c4;
  --cds-charts-4-1-1-hovered: #5923a6;
  --cds-charts-4-1-2: #012749;
  --cds-charts-4-1-2-hovered: #011426;
  --cds-charts-4-1-3: #009d9a;
  --cds-charts-4-1-3-hovered: #007977;
  --cds-charts-4-1-4: #ee5396;
  --cds-charts-4-1-4-hovered: #eb3382;
  --cds-charts-4-2-1: #9f1853;
  --cds-charts-4-2-1-hovered: #801343;
  --cds-charts-4-2-2: #fa4d56;
  --cds-charts-4-2-2-hovered: #f92a35;
  --cds-charts-4-2-3: #520408;
  --cds-charts-4-2-3-hovered: #300205;
  --cds-charts-4-2-4: #a56eff;
  --cds-charts-4-2-4-hovered: #8f4aff;
  --cds-charts-4-3-1: #009d9a;
  --cds-charts-4-3-1-hovered: #007977;
  --cds-charts-4-3-2: #002d9c;
  --cds-charts-4-3-2-hovered: #002378;
  --cds-charts-4-3-3: #a56eff;
  --cds-charts-4-3-3-hovered: #8f4aff;
  --cds-charts-4-3-4: #9f1853;
  --cds-charts-4-3-4-hovered: #801343;
  --cds-charts-5-1-1: #6929c4;
  --cds-charts-5-1-1-hovered: #5923a6;
  --cds-charts-5-1-2: #1192e8;
  --cds-charts-5-1-2-hovered: #0f7dc7;
  --cds-charts-5-1-3: #005d5d;
  --cds-charts-5-1-3-hovered: #003939;
  --cds-charts-5-1-4: #9f1853;
  --cds-charts-5-1-4-hovered: #801343;
  --cds-charts-5-1-5: #520408;
  --cds-charts-5-1-5-hovered: #300205;
  --cds-charts-5-2-1: #002d9c;
  --cds-charts-5-2-1-hovered: #002378;
  --cds-charts-5-2-2: #009d9a;
  --cds-charts-5-2-2-hovered: #007977;
  --cds-charts-5-2-3: #9f1853;
  --cds-charts-5-2-3-hovered: #801343;
  --cds-charts-5-2-4: #520408;
  --cds-charts-5-2-4-hovered: #300205;
  --cds-charts-5-2-5: #a56eff;
  --cds-charts-5-2-5-hovered: #8f4aff;
  --cds-charts-14-1-1: #6929c4;
  --cds-charts-14-1-1-hovered: #5923a6;
  --cds-charts-14-1-2: #1192e8;
  --cds-charts-14-1-2-hovered: #0f7dc7;
  --cds-charts-14-1-3: #005d5d;
  --cds-charts-14-1-3-hovered: #003939;
  --cds-charts-14-1-4: #9f1853;
  --cds-charts-14-1-4-hovered: #801343;
  --cds-charts-14-1-5: #fa4d56;
  --cds-charts-14-1-5-hovered: #f92a35;
  --cds-charts-14-1-6: #520408;
  --cds-charts-14-1-6-hovered: #300205;
  --cds-charts-14-1-7: #198038;
  --cds-charts-14-1-7-hovered: #13622b;
  --cds-charts-14-1-8: #002d9c;
  --cds-charts-14-1-8-hovered: #002378;
  --cds-charts-14-1-9: #ee5396;
  --cds-charts-14-1-9-hovered: #eb3382;
  --cds-charts-14-1-10: #b28600;
  --cds-charts-14-1-10-hovered: #8e6b00;
  --cds-charts-14-1-11: #009d9a;
  --cds-charts-14-1-11-hovered: #007977;
  --cds-charts-14-1-12: #012749;
  --cds-charts-14-1-12-hovered: #011426;
  --cds-charts-14-1-13: #8a3800;
  --cds-charts-14-1-13-hovered: #662a00;
  --cds-charts-14-1-14: #a56eff;
  --cds-charts-14-1-14-hovered: #8f4aff;
  --cds-charts-mono-1-1: #ffffff;
  --cds-charts-mono-1-1-hovered: #ededed;
  --cds-charts-mono-1-2: #f6f2ff;
  --cds-charts-mono-1-2-hovered: #ddceff;
  --cds-charts-mono-1-3: #e8daff;
  --cds-charts-mono-1-3-hovered: #d2b6ff;
  --cds-charts-mono-1-4: #d4bbff;
  --cds-charts-mono-1-4-hovered: #bd97ff;
  --cds-charts-mono-1-5: #be95ff;
  --cds-charts-mono-1-5-hovered: #a871ff;
  --cds-charts-mono-1-6: #a56eff;
  --cds-charts-mono-1-6-hovered: #8f4aff;
  --cds-charts-mono-1-7: #8a3ffc;
  --cds-charts-mono-1-7-hovered: #751cfb;
  --cds-charts-mono-1-8: #6929c4;
  --cds-charts-mono-1-8-hovered: #5923a6;
  --cds-charts-mono-1-9: #491d8b;
  --cds-charts-mono-1-9-hovered: #39176d;
  --cds-charts-mono-1-10: #31135e;
  --cds-charts-mono-1-10-hovered: #220d40;
  --cds-charts-mono-1-11: #1c0f30;
  --cds-charts-mono-1-11-hovered: #0c0715;
  --cds-charts-mono-2-1: #ffffff;
  --cds-charts-mono-2-1-hovered: #ededed;
  --cds-charts-mono-2-2: #edf5ff;
  --cds-charts-mono-2-2-hovered: #c9e1ff;
  --cds-charts-mono-2-3: #d0e2ff;
  --cds-charts-mono-2-3-hovered: #acccff;
  --cds-charts-mono-2-4: #a6c8ff;
  --cds-charts-mono-2-4-hovered: #82b2ff;
  --cds-charts-mono-2-5: #78a9ff;
  --cds-charts-mono-2-5-hovered: #5492ff;
  --cds-charts-mono-2-6: #4589ff;
  --cds-charts-mono-2-6-hovered: #2172ff;
  --cds-charts-mono-2-7: #0f62fe;
  --cds-charts-mono-2-7-hovered: #0151e8;
  --cds-charts-mono-2-8: #0043ce;
  --cds-charts-mono-2-8-hovered: #0037aa;
  --cds-charts-mono-2-9: #002d9c;
  --cds-charts-mono-2-9-hovered: #002378;
  --cds-charts-mono-2-10: #001d6c;
  --cds-charts-mono-2-10-hovered: #001348;
  --cds-charts-mono-2-11: #001141;
  --cds-charts-mono-2-11-hovered: #00081d;
  --cds-charts-mono-3-1: #ffffff;
  --cds-charts-mono-3-1-hovered: #ededed;
  --cds-charts-mono-3-2: #e5f6ff;
  --cds-charts-mono-3-2-hovered: #c1eaff;
  --cds-charts-mono-3-3: #bae6ff;
  --cds-charts-mono-3-3-hovered: #96d9ff;
  --cds-charts-mono-3-4: #82cfff;
  --cds-charts-mono-3-4-hovered: #5ec1ff;
  --cds-charts-mono-3-5: #33b1ff;
  --cds-charts-mono-3-5-hovered: #0fa3ff;
  --cds-charts-mono-3-6: #1192e8;
  --cds-charts-mono-3-6-hovered: #0f7dc7;
  --cds-charts-mono-3-7: #0072c3;
  --cds-charts-mono-3-7-hovered: #005d9f;
  --cds-charts-mono-3-8: #00539a;
  --cds-charts-mono-3-8-hovered: #004076;
  --cds-charts-mono-3-9: #003a6d;
  --cds-charts-mono-3-9-hovered: #002749;
  --cds-charts-mono-3-10: #012749;
  --cds-charts-mono-3-10-hovered: #011426;
  --cds-charts-mono-3-11: #061727;
  --cds-charts-mono-3-11-hovered: #010508;
  --cds-charts-mono-4-1: #ffffff;
  --cds-charts-mono-4-1-hovered: #ededed;
  --cds-charts-mono-4-2: #d9fbfb;
  --cds-charts-mono-4-2-hovered: #b9f8f8;
  --cds-charts-mono-4-3: #9ef0f0;
  --cds-charts-mono-4-3-hovered: #7febeb;
  --cds-charts-mono-4-4: #3ddbd9;
  --cds-charts-mono-4-4-hovered: #26cecc;
  --cds-charts-mono-4-5: #08bdba;
  --cds-charts-mono-4-5-hovered: #079b98;
  --cds-charts-mono-4-6: #009d9a;
  --cds-charts-mono-4-6-hovered: #007977;
  --cds-charts-mono-4-7: #007d79;
  --cds-charts-mono-4-7-hovered: #005956;
  --cds-charts-mono-4-8: #005d5d;
  --cds-charts-mono-4-8-hovered: #003939;
  --cds-charts-mono-4-9: #004144;
  --cds-charts-mono-4-9-hovered: #001f20;
  --cds-charts-mono-4-10: #022b30;
  --cds-charts-mono-4-10-hovered: #010c0e;
  --cds-charts-mono-4-11: #081a1c;
  --cds-charts-mono-4-11-hovered: black;
  --cds-charts-diverge-1-1: #750e13;
  --cds-charts-diverge-1-1-hovered: #550a0e;
  --cds-charts-diverge-1-2: #a2191f;
  --cds-charts-diverge-1-2-hovered: #831419;
  --cds-charts-diverge-1-3: #da1e28;
  --cds-charts-diverge-1-3-hovered: #bb1a22;
  --cds-charts-diverge-1-4: #fa4d56;
  --cds-charts-diverge-1-4-hovered: #f92a35;
  --cds-charts-diverge-1-5: #ff8389;
  --cds-charts-diverge-1-5-hovered: #ff5f67;
  --cds-charts-diverge-1-6: #ffb3b8;
  --cds-charts-diverge-1-6-hovered: #ff8f97;
  --cds-charts-diverge-1-7: #ffd7d9;
  --cds-charts-diverge-1-7-hovered: #ffb3b7;
  --cds-charts-diverge-1-8: #fff1f1;
  --cds-charts-diverge-1-8-hovered: #ffcdcd;
  --cds-charts-diverge-1-9: #ffffff;
  --cds-charts-diverge-1-9-hovered: #ededed;
  --cds-charts-diverge-1-10: #e5f6ff;
  --cds-charts-diverge-1-10-hovered: #c1eaff;
  --cds-charts-diverge-1-11: #bae6ff;
  --cds-charts-diverge-1-11-hovered: #96d9ff;
  --cds-charts-diverge-1-12: #82cfff;
  --cds-charts-diverge-1-12-hovered: #5ec1ff;
  --cds-charts-diverge-1-13: #33b1ff;
  --cds-charts-diverge-1-13-hovered: #0fa3ff;
  --cds-charts-diverge-1-14: #1192e8;
  --cds-charts-diverge-1-14-hovered: #0f7dc7;
  --cds-charts-diverge-1-15: #0072c3;
  --cds-charts-diverge-1-15-hovered: #005d9f;
  --cds-charts-diverge-1-16: #00539a;
  --cds-charts-diverge-1-16-hovered: #004076;
  --cds-charts-diverge-1-17: #003a6d;
  --cds-charts-diverge-1-17-hovered: #002749;
  --cds-charts-diverge-2-1: #491d8b;
  --cds-charts-diverge-2-1-hovered: #39176d;
  --cds-charts-diverge-2-2: #6929c4;
  --cds-charts-diverge-2-2-hovered: #5923a6;
  --cds-charts-diverge-2-3: #8a3ffc;
  --cds-charts-diverge-2-3-hovered: #751cfb;
  --cds-charts-diverge-2-4: #a56eff;
  --cds-charts-diverge-2-4-hovered: #8f4aff;
  --cds-charts-diverge-2-5: #be95ff;
  --cds-charts-diverge-2-5-hovered: #a871ff;
  --cds-charts-diverge-2-6: #d4bbff;
  --cds-charts-diverge-2-6-hovered: #bd97ff;
  --cds-charts-diverge-2-7: #e8daff;
  --cds-charts-diverge-2-7-hovered: #d2b6ff;
  --cds-charts-diverge-2-8: #f6f2ff;
  --cds-charts-diverge-2-8-hovered: #ddceff;
  --cds-charts-diverge-2-9: #ffffff;
  --cds-charts-diverge-2-9-hovered: #ededed;
  --cds-charts-diverge-2-10: #d9fbfb;
  --cds-charts-diverge-2-10-hovered: #b9f8f8;
  --cds-charts-diverge-2-11: #9ef0f0;
  --cds-charts-diverge-2-11-hovered: #7febeb;
  --cds-charts-diverge-2-12: #3ddbd9;
  --cds-charts-diverge-2-12-hovered: #26cecc;
  --cds-charts-diverge-2-13: #08bdba;
  --cds-charts-diverge-2-13-hovered: #079b98;
  --cds-charts-diverge-2-14: #009d9a;
  --cds-charts-diverge-2-14-hovered: #007977;
  --cds-charts-diverge-2-15: #007d79;
  --cds-charts-diverge-2-15-hovered: #005956;
  --cds-charts-diverge-2-16: #005d5d;
  --cds-charts-diverge-2-16-hovered: #003939;
  --cds-charts-diverge-2-17: #004144;
  --cds-charts-diverge-2-17-hovered: #001f20;
}

.cds--chart-holder[data-carbon-theme=g90],
.cds--chart-holder[data-carbon-theme=g100] {
  --cds-charts-1-1-1: #d4bbff;
  --cds-charts-1-1-1-hovered: #bd97ff;
  --cds-charts-1-2-1: #4589ff;
  --cds-charts-1-2-1-hovered: #2172ff;
  --cds-charts-1-3-1: #33b1ff;
  --cds-charts-1-3-1-hovered: #0fa3ff;
  --cds-charts-1-4-1: #08bdba;
  --cds-charts-1-4-1-hovered: #079b98;
  --cds-charts-2-1-1: #8a3ffc;
  --cds-charts-2-1-1-hovered: #751cfb;
  --cds-charts-2-1-2: #08bdba;
  --cds-charts-2-1-2-hovered: #079b98;
  --cds-charts-2-2-1: #8a3ffc;
  --cds-charts-2-2-1-hovered: #751cfb;
  --cds-charts-2-2-2: #ff7eb6;
  --cds-charts-2-2-2-hovered: #ff5aa2;
  --cds-charts-2-3-1: #ff7eb6;
  --cds-charts-2-3-1-hovered: #ff5aa2;
  --cds-charts-2-3-2: #fff1f1;
  --cds-charts-2-3-2-hovered: #ffcdcd;
  --cds-charts-2-4-1: #4589ff;
  --cds-charts-2-4-1-hovered: #2172ff;
  --cds-charts-2-4-2: #bae6ff;
  --cds-charts-2-4-2-hovered: #96d9ff;
  --cds-charts-2-5-1: #007d79;
  --cds-charts-2-5-1-hovered: #005956;
  --cds-charts-2-5-2: #6fdc8c;
  --cds-charts-2-5-2-hovered: #52d575;
  --cds-charts-3-1-1: #8a3ffc;
  --cds-charts-3-1-1-hovered: #751cfb;
  --cds-charts-3-1-2: #08bdba;
  --cds-charts-3-1-2-hovered: #079b98;
  --cds-charts-3-1-3: #bae6ff;
  --cds-charts-3-1-3-hovered: #96d9ff;
  --cds-charts-3-2-1: #8a3ffc;
  --cds-charts-3-2-1-hovered: #751cfb;
  --cds-charts-3-2-2: #ff7eb6;
  --cds-charts-3-2-2-hovered: #ff5aa2;
  --cds-charts-3-2-3: #fff1f1;
  --cds-charts-3-2-3-hovered: #ffcdcd;
  --cds-charts-3-3-1: #4589ff;
  --cds-charts-3-3-1-hovered: #2172ff;
  --cds-charts-3-3-2: #08bdba;
  --cds-charts-3-3-2-hovered: #079b98;
  --cds-charts-3-3-3: #d4bbff;
  --cds-charts-3-3-3-hovered: #bd97ff;
  --cds-charts-3-4-1: #4589ff;
  --cds-charts-3-4-1-hovered: #2172ff;
  --cds-charts-3-4-2: #6fdc8c;
  --cds-charts-3-4-2-hovered: #52d575;
  --cds-charts-3-4-3: #fff1f1;
  --cds-charts-3-4-3-hovered: #ffcdcd;
  --cds-charts-3-5-1: #007d79;
  --cds-charts-3-5-1-hovered: #005956;
  --cds-charts-3-5-2: #6fdc8c;
  --cds-charts-3-5-2-hovered: #52d575;
  --cds-charts-3-5-3: #bae6ff;
  --cds-charts-3-5-3-hovered: #96d9ff;
  --cds-charts-4-1-1: #8a3ffc;
  --cds-charts-4-1-1-hovered: #751cfb;
  --cds-charts-4-1-2: #08bdba;
  --cds-charts-4-1-2-hovered: #079b98;
  --cds-charts-4-1-3: #bae6ff;
  --cds-charts-4-1-3-hovered: #96d9ff;
  --cds-charts-4-1-4: #4589ff;
  --cds-charts-4-1-4-hovered: #2172ff;
  --cds-charts-4-2-1: #4589ff;
  --cds-charts-4-2-1-hovered: #2172ff;
  --cds-charts-4-2-2: #08bdba;
  --cds-charts-4-2-2-hovered: #079b98;
  --cds-charts-4-2-3: #d4bbff;
  --cds-charts-4-2-3-hovered: #bd97ff;
  --cds-charts-4-2-4: #fff1f1;
  --cds-charts-4-2-4-hovered: #ffcdcd;
  --cds-charts-4-3-1: #007d79;
  --cds-charts-4-3-1-hovered: #005956;
  --cds-charts-4-3-2: #fff1f1;
  --cds-charts-4-3-2-hovered: #ffcdcd;
  --cds-charts-4-3-3: #33b1ff;
  --cds-charts-4-3-3-hovered: #0fa3ff;
  --cds-charts-4-3-4: #6fdc8c;
  --cds-charts-4-3-4-hovered: #52d575;
  --cds-charts-5-1-1: #8a3ffc;
  --cds-charts-5-1-1-hovered: #751cfb;
  --cds-charts-5-1-2: #08bdba;
  --cds-charts-5-1-2-hovered: #079b98;
  --cds-charts-5-1-3: #bae6ff;
  --cds-charts-5-1-3-hovered: #96d9ff;
  --cds-charts-5-1-4: #4589ff;
  --cds-charts-5-1-4-hovered: #2172ff;
  --cds-charts-5-1-5: #ff7eb6;
  --cds-charts-5-1-5-hovered: #ff5aa2;
  --cds-charts-5-2-1: #4589ff;
  --cds-charts-5-2-1-hovered: #2172ff;
  --cds-charts-5-2-2: #08bdba;
  --cds-charts-5-2-2-hovered: #079b98;
  --cds-charts-5-2-3: #d4bbff;
  --cds-charts-5-2-3-hovered: #bd97ff;
  --cds-charts-5-2-4: #fff1f1;
  --cds-charts-5-2-4-hovered: #ffcdcd;
  --cds-charts-5-2-5: #6fdc8c;
  --cds-charts-5-2-5-hovered: #52d575;
  --cds-charts-14-1-1: #8a3ffc;
  --cds-charts-14-1-1-hovered: #751cfb;
  --cds-charts-14-1-2: #33b1ff;
  --cds-charts-14-1-2-hovered: #0fa3ff;
  --cds-charts-14-1-3: #007d79;
  --cds-charts-14-1-3-hovered: #005956;
  --cds-charts-14-1-4: #ff7eb6;
  --cds-charts-14-1-4-hovered: #ff5aa2;
  --cds-charts-14-1-5: #fa4d56;
  --cds-charts-14-1-5-hovered: #f92a35;
  --cds-charts-14-1-6: #fff1f1;
  --cds-charts-14-1-6-hovered: #ffcdcd;
  --cds-charts-14-1-7: #6fdc8c;
  --cds-charts-14-1-7-hovered: #52d575;
  --cds-charts-14-1-8: #4589ff;
  --cds-charts-14-1-8-hovered: #2172ff;
  --cds-charts-14-1-9: #d02670;
  --cds-charts-14-1-9-hovered: #b22060;
  --cds-charts-14-1-10: #d2a106;
  --cds-charts-14-1-10-hovered: #af8605;
  --cds-charts-14-1-11: #08bdba;
  --cds-charts-14-1-11-hovered: #079b98;
  --cds-charts-14-1-12: #bae6ff;
  --cds-charts-14-1-12-hovered: #96d9ff;
  --cds-charts-14-1-13: #ba4e00;
  --cds-charts-14-1-13-hovered: #963f00;
  --cds-charts-14-1-14: #d4bbff;
  --cds-charts-14-1-14-hovered: #bd97ff;
  --cds-charts-mono-1-1: #ffffff;
  --cds-charts-mono-1-1-hovered: #ededed;
  --cds-charts-mono-1-2: #f6f2ff;
  --cds-charts-mono-1-2-hovered: #ddceff;
  --cds-charts-mono-1-3: #e8daff;
  --cds-charts-mono-1-3-hovered: #d2b6ff;
  --cds-charts-mono-1-4: #d4bbff;
  --cds-charts-mono-1-4-hovered: #bd97ff;
  --cds-charts-mono-1-5: #be95ff;
  --cds-charts-mono-1-5-hovered: #a871ff;
  --cds-charts-mono-1-6: #a56eff;
  --cds-charts-mono-1-6-hovered: #8f4aff;
  --cds-charts-mono-1-7: #8a3ffc;
  --cds-charts-mono-1-7-hovered: #751cfb;
  --cds-charts-mono-1-8: #6929c4;
  --cds-charts-mono-1-8-hovered: #5923a6;
  --cds-charts-mono-1-9: #491d8b;
  --cds-charts-mono-1-9-hovered: #39176d;
  --cds-charts-mono-1-10: #31135e;
  --cds-charts-mono-1-10-hovered: #220d40;
  --cds-charts-mono-1-11: #1c0f30;
  --cds-charts-mono-1-11-hovered: #0c0715;
  --cds-charts-mono-2-1: #ffffff;
  --cds-charts-mono-2-1-hovered: #ededed;
  --cds-charts-mono-2-2: #edf5ff;
  --cds-charts-mono-2-2-hovered: #c9e1ff;
  --cds-charts-mono-2-3: #d0e2ff;
  --cds-charts-mono-2-3-hovered: #acccff;
  --cds-charts-mono-2-4: #a6c8ff;
  --cds-charts-mono-2-4-hovered: #82b2ff;
  --cds-charts-mono-2-5: #78a9ff;
  --cds-charts-mono-2-5-hovered: #5492ff;
  --cds-charts-mono-2-6: #4589ff;
  --cds-charts-mono-2-6-hovered: #2172ff;
  --cds-charts-mono-2-7: #0f62fe;
  --cds-charts-mono-2-7-hovered: #0151e8;
  --cds-charts-mono-2-8: #0043ce;
  --cds-charts-mono-2-8-hovered: #0037aa;
  --cds-charts-mono-2-9: #002d9c;
  --cds-charts-mono-2-9-hovered: #002378;
  --cds-charts-mono-2-10: #001d6c;
  --cds-charts-mono-2-10-hovered: #001348;
  --cds-charts-mono-2-11: #001141;
  --cds-charts-mono-2-11-hovered: #00081d;
  --cds-charts-mono-3-1: #ffffff;
  --cds-charts-mono-3-1-hovered: #ededed;
  --cds-charts-mono-3-2: #e5f6ff;
  --cds-charts-mono-3-2-hovered: #c1eaff;
  --cds-charts-mono-3-3: #bae6ff;
  --cds-charts-mono-3-3-hovered: #96d9ff;
  --cds-charts-mono-3-4: #82cfff;
  --cds-charts-mono-3-4-hovered: #5ec1ff;
  --cds-charts-mono-3-5: #33b1ff;
  --cds-charts-mono-3-5-hovered: #0fa3ff;
  --cds-charts-mono-3-6: #1192e8;
  --cds-charts-mono-3-6-hovered: #0f7dc7;
  --cds-charts-mono-3-7: #0072c3;
  --cds-charts-mono-3-7-hovered: #005d9f;
  --cds-charts-mono-3-8: #00539a;
  --cds-charts-mono-3-8-hovered: #004076;
  --cds-charts-mono-3-9: #003a6d;
  --cds-charts-mono-3-9-hovered: #002749;
  --cds-charts-mono-3-10: #012749;
  --cds-charts-mono-3-10-hovered: #011426;
  --cds-charts-mono-3-11: #061727;
  --cds-charts-mono-3-11-hovered: #010508;
  --cds-charts-mono-4-1: #ffffff;
  --cds-charts-mono-4-1-hovered: #ededed;
  --cds-charts-mono-4-2: #d9fbfb;
  --cds-charts-mono-4-2-hovered: #b9f8f8;
  --cds-charts-mono-4-3: #9ef0f0;
  --cds-charts-mono-4-3-hovered: #7febeb;
  --cds-charts-mono-4-4: #3ddbd9;
  --cds-charts-mono-4-4-hovered: #26cecc;
  --cds-charts-mono-4-5: #08bdba;
  --cds-charts-mono-4-5-hovered: #079b98;
  --cds-charts-mono-4-6: #009d9a;
  --cds-charts-mono-4-6-hovered: #007977;
  --cds-charts-mono-4-7: #007d79;
  --cds-charts-mono-4-7-hovered: #005956;
  --cds-charts-mono-4-8: #005d5d;
  --cds-charts-mono-4-8-hovered: #003939;
  --cds-charts-mono-4-9: #004144;
  --cds-charts-mono-4-9-hovered: #001f20;
  --cds-charts-mono-4-10: #022b30;
  --cds-charts-mono-4-10-hovered: #010c0e;
  --cds-charts-mono-4-11: #081a1c;
  --cds-charts-mono-4-11-hovered: black;
  --cds-charts-diverge-1-1: #750e13;
  --cds-charts-diverge-1-1-hovered: #550a0e;
  --cds-charts-diverge-1-2: #a2191f;
  --cds-charts-diverge-1-2-hovered: #831419;
  --cds-charts-diverge-1-3: #da1e28;
  --cds-charts-diverge-1-3-hovered: #bb1a22;
  --cds-charts-diverge-1-4: #fa4d56;
  --cds-charts-diverge-1-4-hovered: #f92a35;
  --cds-charts-diverge-1-5: #ff8389;
  --cds-charts-diverge-1-5-hovered: #ff5f67;
  --cds-charts-diverge-1-6: #ffb3b8;
  --cds-charts-diverge-1-6-hovered: #ff8f97;
  --cds-charts-diverge-1-7: #ffd7d9;
  --cds-charts-diverge-1-7-hovered: #ffb3b7;
  --cds-charts-diverge-1-8: #fff1f1;
  --cds-charts-diverge-1-8-hovered: #ffcdcd;
  --cds-charts-diverge-1-9: #ffffff;
  --cds-charts-diverge-1-9-hovered: #ededed;
  --cds-charts-diverge-1-10: #e5f6ff;
  --cds-charts-diverge-1-10-hovered: #c1eaff;
  --cds-charts-diverge-1-11: #bae6ff;
  --cds-charts-diverge-1-11-hovered: #96d9ff;
  --cds-charts-diverge-1-12: #82cfff;
  --cds-charts-diverge-1-12-hovered: #5ec1ff;
  --cds-charts-diverge-1-13: #33b1ff;
  --cds-charts-diverge-1-13-hovered: #0fa3ff;
  --cds-charts-diverge-1-14: #1192e8;
  --cds-charts-diverge-1-14-hovered: #0f7dc7;
  --cds-charts-diverge-1-15: #0072c3;
  --cds-charts-diverge-1-15-hovered: #005d9f;
  --cds-charts-diverge-1-16: #00539a;
  --cds-charts-diverge-1-16-hovered: #004076;
  --cds-charts-diverge-1-17: #003a6d;
  --cds-charts-diverge-1-17-hovered: #002749;
  --cds-charts-diverge-2-1: #491d8b;
  --cds-charts-diverge-2-1-hovered: #39176d;
  --cds-charts-diverge-2-2: #6929c4;
  --cds-charts-diverge-2-2-hovered: #5923a6;
  --cds-charts-diverge-2-3: #8a3ffc;
  --cds-charts-diverge-2-3-hovered: #751cfb;
  --cds-charts-diverge-2-4: #a56eff;
  --cds-charts-diverge-2-4-hovered: #8f4aff;
  --cds-charts-diverge-2-5: #be95ff;
  --cds-charts-diverge-2-5-hovered: #a871ff;
  --cds-charts-diverge-2-6: #d4bbff;
  --cds-charts-diverge-2-6-hovered: #bd97ff;
  --cds-charts-diverge-2-7: #e8daff;
  --cds-charts-diverge-2-7-hovered: #d2b6ff;
  --cds-charts-diverge-2-8: #f6f2ff;
  --cds-charts-diverge-2-8-hovered: #ddceff;
  --cds-charts-diverge-2-9: #ffffff;
  --cds-charts-diverge-2-9-hovered: #ededed;
  --cds-charts-diverge-2-10: #d9fbfb;
  --cds-charts-diverge-2-10-hovered: #b9f8f8;
  --cds-charts-diverge-2-11: #9ef0f0;
  --cds-charts-diverge-2-11-hovered: #7febeb;
  --cds-charts-diverge-2-12: #3ddbd9;
  --cds-charts-diverge-2-12-hovered: #26cecc;
  --cds-charts-diverge-2-13: #08bdba;
  --cds-charts-diverge-2-13-hovered: #079b98;
  --cds-charts-diverge-2-14: #009d9a;
  --cds-charts-diverge-2-14-hovered: #007977;
  --cds-charts-diverge-2-15: #007d79;
  --cds-charts-diverge-2-15-hovered: #005956;
  --cds-charts-diverge-2-16: #005d5d;
  --cds-charts-diverge-2-16-hovered: #003939;
  --cds-charts-diverge-2-17: #004144;
  --cds-charts-diverge-2-17-hovered: #001f20;
}

.cds--cc--chart-wrapper .fill-1-1-1 {
  fill: var(--cds-charts-1-1-1, #6929c4);
}
.cds--cc--chart-wrapper .fill-1-1-1.hovered {
  fill: var(--cds-charts-1-1-1-hovered, #6929c4);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-1-1-1 {
  background-color: var(--cds-charts-1-1-1, #6929c4);
}
.cds--cc--chart-wrapper .background-1-1-1.hovered {
  background-color: var(--cds-charts-1-1-1-hovered, #6929c4);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-1-1-1 {
  stroke: var(--cds-charts-1-1-1, #6929c4);
}
.cds--cc--chart-wrapper .stop-color-1-1-1 {
  stop-color: var(--cds-charts-1-1-1, #6929c4);
}
.cds--cc--chart-wrapper .fill-1-2-1 {
  fill: var(--cds-charts-1-2-1, #002d9c);
}
.cds--cc--chart-wrapper .fill-1-2-1.hovered {
  fill: var(--cds-charts-1-2-1-hovered, #002d9c);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-1-2-1 {
  background-color: var(--cds-charts-1-2-1, #002d9c);
}
.cds--cc--chart-wrapper .background-1-2-1.hovered {
  background-color: var(--cds-charts-1-2-1-hovered, #002d9c);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-1-2-1 {
  stroke: var(--cds-charts-1-2-1, #002d9c);
}
.cds--cc--chart-wrapper .stop-color-1-2-1 {
  stop-color: var(--cds-charts-1-2-1, #002d9c);
}
.cds--cc--chart-wrapper .fill-1-3-1 {
  fill: var(--cds-charts-1-3-1, #1192e8);
}
.cds--cc--chart-wrapper .fill-1-3-1.hovered {
  fill: var(--cds-charts-1-3-1-hovered, #1192e8);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-1-3-1 {
  background-color: var(--cds-charts-1-3-1, #1192e8);
}
.cds--cc--chart-wrapper .background-1-3-1.hovered {
  background-color: var(--cds-charts-1-3-1-hovered, #1192e8);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-1-3-1 {
  stroke: var(--cds-charts-1-3-1, #1192e8);
}
.cds--cc--chart-wrapper .stop-color-1-3-1 {
  stop-color: var(--cds-charts-1-3-1, #1192e8);
}
.cds--cc--chart-wrapper .fill-1-4-1 {
  fill: var(--cds-charts-1-4-1, #007d79);
}
.cds--cc--chart-wrapper .fill-1-4-1.hovered {
  fill: var(--cds-charts-1-4-1-hovered, #007d79);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-1-4-1 {
  background-color: var(--cds-charts-1-4-1, #007d79);
}
.cds--cc--chart-wrapper .background-1-4-1.hovered {
  background-color: var(--cds-charts-1-4-1-hovered, #007d79);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-1-4-1 {
  stroke: var(--cds-charts-1-4-1, #007d79);
}
.cds--cc--chart-wrapper .stop-color-1-4-1 {
  stop-color: var(--cds-charts-1-4-1, #007d79);
}
.cds--cc--chart-wrapper .fill-2-1-1 {
  fill: var(--cds-charts-2-1-1, #6929c4);
}
.cds--cc--chart-wrapper .fill-2-1-1.hovered {
  fill: var(--cds-charts-2-1-1-hovered, #6929c4);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-2-1-1 {
  background-color: var(--cds-charts-2-1-1, #6929c4);
}
.cds--cc--chart-wrapper .background-2-1-1.hovered {
  background-color: var(--cds-charts-2-1-1-hovered, #6929c4);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-2-1-1 {
  stroke: var(--cds-charts-2-1-1, #6929c4);
}
.cds--cc--chart-wrapper .stop-color-2-1-1 {
  stop-color: var(--cds-charts-2-1-1, #6929c4);
}
.cds--cc--chart-wrapper .fill-2-1-2 {
  fill: var(--cds-charts-2-1-2, #009d9a);
}
.cds--cc--chart-wrapper .fill-2-1-2.hovered {
  fill: var(--cds-charts-2-1-2-hovered, #009d9a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-2-1-2 {
  background-color: var(--cds-charts-2-1-2, #009d9a);
}
.cds--cc--chart-wrapper .background-2-1-2.hovered {
  background-color: var(--cds-charts-2-1-2-hovered, #009d9a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-2-1-2 {
  stroke: var(--cds-charts-2-1-2, #009d9a);
}
.cds--cc--chart-wrapper .stop-color-2-1-2 {
  stop-color: var(--cds-charts-2-1-2, #009d9a);
}
.cds--cc--chart-wrapper .fill-2-2-1 {
  fill: var(--cds-charts-2-2-1, #8a3ffc);
}
.cds--cc--chart-wrapper .fill-2-2-1.hovered {
  fill: var(--cds-charts-2-2-1-hovered, #8a3ffc);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-2-2-1 {
  background-color: var(--cds-charts-2-2-1, #8a3ffc);
}
.cds--cc--chart-wrapper .background-2-2-1.hovered {
  background-color: var(--cds-charts-2-2-1-hovered, #8a3ffc);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-2-2-1 {
  stroke: var(--cds-charts-2-2-1, #8a3ffc);
}
.cds--cc--chart-wrapper .stop-color-2-2-1 {
  stop-color: var(--cds-charts-2-2-1, #8a3ffc);
}
.cds--cc--chart-wrapper .fill-2-2-2 {
  fill: var(--cds-charts-2-2-2, #520408);
}
.cds--cc--chart-wrapper .fill-2-2-2.hovered {
  fill: var(--cds-charts-2-2-2-hovered, #520408);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-2-2-2 {
  background-color: var(--cds-charts-2-2-2, #520408);
}
.cds--cc--chart-wrapper .background-2-2-2.hovered {
  background-color: var(--cds-charts-2-2-2-hovered, #520408);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-2-2-2 {
  stroke: var(--cds-charts-2-2-2, #520408);
}
.cds--cc--chart-wrapper .stop-color-2-2-2 {
  stop-color: var(--cds-charts-2-2-2, #520408);
}
.cds--cc--chart-wrapper .fill-2-3-1 {
  fill: var(--cds-charts-2-3-1, #9f1853);
}
.cds--cc--chart-wrapper .fill-2-3-1.hovered {
  fill: var(--cds-charts-2-3-1-hovered, #9f1853);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-2-3-1 {
  background-color: var(--cds-charts-2-3-1, #9f1853);
}
.cds--cc--chart-wrapper .background-2-3-1.hovered {
  background-color: var(--cds-charts-2-3-1-hovered, #9f1853);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-2-3-1 {
  stroke: var(--cds-charts-2-3-1, #9f1853);
}
.cds--cc--chart-wrapper .stop-color-2-3-1 {
  stop-color: var(--cds-charts-2-3-1, #9f1853);
}
.cds--cc--chart-wrapper .fill-2-3-2 {
  fill: var(--cds-charts-2-3-2, #520408);
}
.cds--cc--chart-wrapper .fill-2-3-2.hovered {
  fill: var(--cds-charts-2-3-2-hovered, #520408);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-2-3-2 {
  background-color: var(--cds-charts-2-3-2, #520408);
}
.cds--cc--chart-wrapper .background-2-3-2.hovered {
  background-color: var(--cds-charts-2-3-2-hovered, #520408);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-2-3-2 {
  stroke: var(--cds-charts-2-3-2, #520408);
}
.cds--cc--chart-wrapper .stop-color-2-3-2 {
  stop-color: var(--cds-charts-2-3-2, #520408);
}
.cds--cc--chart-wrapper .fill-2-4-1 {
  fill: var(--cds-charts-2-4-1, #1192e8);
}
.cds--cc--chart-wrapper .fill-2-4-1.hovered {
  fill: var(--cds-charts-2-4-1-hovered, #1192e8);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-2-4-1 {
  background-color: var(--cds-charts-2-4-1, #1192e8);
}
.cds--cc--chart-wrapper .background-2-4-1.hovered {
  background-color: var(--cds-charts-2-4-1-hovered, #1192e8);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-2-4-1 {
  stroke: var(--cds-charts-2-4-1, #1192e8);
}
.cds--cc--chart-wrapper .stop-color-2-4-1 {
  stop-color: var(--cds-charts-2-4-1, #1192e8);
}
.cds--cc--chart-wrapper .fill-2-4-2 {
  fill: var(--cds-charts-2-4-2, #005d5d);
}
.cds--cc--chart-wrapper .fill-2-4-2.hovered {
  fill: var(--cds-charts-2-4-2-hovered, #005d5d);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-2-4-2 {
  background-color: var(--cds-charts-2-4-2, #005d5d);
}
.cds--cc--chart-wrapper .background-2-4-2.hovered {
  background-color: var(--cds-charts-2-4-2-hovered, #005d5d);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-2-4-2 {
  stroke: var(--cds-charts-2-4-2, #005d5d);
}
.cds--cc--chart-wrapper .stop-color-2-4-2 {
  stop-color: var(--cds-charts-2-4-2, #005d5d);
}
.cds--cc--chart-wrapper .fill-2-5-1 {
  fill: var(--cds-charts-2-5-1, #009d9a);
}
.cds--cc--chart-wrapper .fill-2-5-1.hovered {
  fill: var(--cds-charts-2-5-1-hovered, #009d9a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-2-5-1 {
  background-color: var(--cds-charts-2-5-1, #009d9a);
}
.cds--cc--chart-wrapper .background-2-5-1.hovered {
  background-color: var(--cds-charts-2-5-1-hovered, #009d9a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-2-5-1 {
  stroke: var(--cds-charts-2-5-1, #009d9a);
}
.cds--cc--chart-wrapper .stop-color-2-5-1 {
  stop-color: var(--cds-charts-2-5-1, #009d9a);
}
.cds--cc--chart-wrapper .fill-2-5-2 {
  fill: var(--cds-charts-2-5-2, #002d9c);
}
.cds--cc--chart-wrapper .fill-2-5-2.hovered {
  fill: var(--cds-charts-2-5-2-hovered, #002d9c);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-2-5-2 {
  background-color: var(--cds-charts-2-5-2, #002d9c);
}
.cds--cc--chart-wrapper .background-2-5-2.hovered {
  background-color: var(--cds-charts-2-5-2-hovered, #002d9c);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-2-5-2 {
  stroke: var(--cds-charts-2-5-2, #002d9c);
}
.cds--cc--chart-wrapper .stop-color-2-5-2 {
  stop-color: var(--cds-charts-2-5-2, #002d9c);
}
.cds--cc--chart-wrapper .fill-3-1-1 {
  fill: var(--cds-charts-3-1-1, #ee5396);
}
.cds--cc--chart-wrapper .fill-3-1-1.hovered {
  fill: var(--cds-charts-3-1-1-hovered, #ee5396);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-3-1-1 {
  background-color: var(--cds-charts-3-1-1, #ee5396);
}
.cds--cc--chart-wrapper .background-3-1-1.hovered {
  background-color: var(--cds-charts-3-1-1-hovered, #ee5396);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-3-1-1 {
  stroke: var(--cds-charts-3-1-1, #ee5396);
}
.cds--cc--chart-wrapper .stop-color-3-1-1 {
  stop-color: var(--cds-charts-3-1-1, #ee5396);
}
.cds--cc--chart-wrapper .fill-3-1-2 {
  fill: var(--cds-charts-3-1-2, #1192e8);
}
.cds--cc--chart-wrapper .fill-3-1-2.hovered {
  fill: var(--cds-charts-3-1-2-hovered, #1192e8);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-3-1-2 {
  background-color: var(--cds-charts-3-1-2, #1192e8);
}
.cds--cc--chart-wrapper .background-3-1-2.hovered {
  background-color: var(--cds-charts-3-1-2-hovered, #1192e8);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-3-1-2 {
  stroke: var(--cds-charts-3-1-2, #1192e8);
}
.cds--cc--chart-wrapper .stop-color-3-1-2 {
  stop-color: var(--cds-charts-3-1-2, #1192e8);
}
.cds--cc--chart-wrapper .fill-3-1-3 {
  fill: var(--cds-charts-3-1-3, #6929c4);
}
.cds--cc--chart-wrapper .fill-3-1-3.hovered {
  fill: var(--cds-charts-3-1-3-hovered, #6929c4);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-3-1-3 {
  background-color: var(--cds-charts-3-1-3, #6929c4);
}
.cds--cc--chart-wrapper .background-3-1-3.hovered {
  background-color: var(--cds-charts-3-1-3-hovered, #6929c4);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-3-1-3 {
  stroke: var(--cds-charts-3-1-3, #6929c4);
}
.cds--cc--chart-wrapper .stop-color-3-1-3 {
  stop-color: var(--cds-charts-3-1-3, #6929c4);
}
.cds--cc--chart-wrapper .fill-3-2-1 {
  fill: var(--cds-charts-3-2-1, #9f1853);
}
.cds--cc--chart-wrapper .fill-3-2-1.hovered {
  fill: var(--cds-charts-3-2-1-hovered, #9f1853);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-3-2-1 {
  background-color: var(--cds-charts-3-2-1, #9f1853);
}
.cds--cc--chart-wrapper .background-3-2-1.hovered {
  background-color: var(--cds-charts-3-2-1-hovered, #9f1853);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-3-2-1 {
  stroke: var(--cds-charts-3-2-1, #9f1853);
}
.cds--cc--chart-wrapper .stop-color-3-2-1 {
  stop-color: var(--cds-charts-3-2-1, #9f1853);
}
.cds--cc--chart-wrapper .fill-3-2-2 {
  fill: var(--cds-charts-3-2-2, #fa4d56);
}
.cds--cc--chart-wrapper .fill-3-2-2.hovered {
  fill: var(--cds-charts-3-2-2-hovered, #fa4d56);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-3-2-2 {
  background-color: var(--cds-charts-3-2-2, #fa4d56);
}
.cds--cc--chart-wrapper .background-3-2-2.hovered {
  background-color: var(--cds-charts-3-2-2-hovered, #fa4d56);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-3-2-2 {
  stroke: var(--cds-charts-3-2-2, #fa4d56);
}
.cds--cc--chart-wrapper .stop-color-3-2-2 {
  stop-color: var(--cds-charts-3-2-2, #fa4d56);
}
.cds--cc--chart-wrapper .fill-3-2-3 {
  fill: var(--cds-charts-3-2-3, #520408);
}
.cds--cc--chart-wrapper .fill-3-2-3.hovered {
  fill: var(--cds-charts-3-2-3-hovered, #520408);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-3-2-3 {
  background-color: var(--cds-charts-3-2-3, #520408);
}
.cds--cc--chart-wrapper .background-3-2-3.hovered {
  background-color: var(--cds-charts-3-2-3-hovered, #520408);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-3-2-3 {
  stroke: var(--cds-charts-3-2-3, #520408);
}
.cds--cc--chart-wrapper .stop-color-3-2-3 {
  stop-color: var(--cds-charts-3-2-3, #520408);
}
.cds--cc--chart-wrapper .fill-3-3-1 {
  fill: var(--cds-charts-3-3-1, #a56eff);
}
.cds--cc--chart-wrapper .fill-3-3-1.hovered {
  fill: var(--cds-charts-3-3-1-hovered, #a56eff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-3-3-1 {
  background-color: var(--cds-charts-3-3-1, #a56eff);
}
.cds--cc--chart-wrapper .background-3-3-1.hovered {
  background-color: var(--cds-charts-3-3-1-hovered, #a56eff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-3-3-1 {
  stroke: var(--cds-charts-3-3-1, #a56eff);
}
.cds--cc--chart-wrapper .stop-color-3-3-1 {
  stop-color: var(--cds-charts-3-3-1, #a56eff);
}
.cds--cc--chart-wrapper .fill-3-3-2 {
  fill: var(--cds-charts-3-3-2, #005d5d);
}
.cds--cc--chart-wrapper .fill-3-3-2.hovered {
  fill: var(--cds-charts-3-3-2-hovered, #005d5d);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-3-3-2 {
  background-color: var(--cds-charts-3-3-2, #005d5d);
}
.cds--cc--chart-wrapper .background-3-3-2.hovered {
  background-color: var(--cds-charts-3-3-2-hovered, #005d5d);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-3-3-2 {
  stroke: var(--cds-charts-3-3-2, #005d5d);
}
.cds--cc--chart-wrapper .stop-color-3-3-2 {
  stop-color: var(--cds-charts-3-3-2, #005d5d);
}
.cds--cc--chart-wrapper .fill-3-3-3 {
  fill: var(--cds-charts-3-3-3, #002d9c);
}
.cds--cc--chart-wrapper .fill-3-3-3.hovered {
  fill: var(--cds-charts-3-3-3-hovered, #002d9c);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-3-3-3 {
  background-color: var(--cds-charts-3-3-3, #002d9c);
}
.cds--cc--chart-wrapper .background-3-3-3.hovered {
  background-color: var(--cds-charts-3-3-3-hovered, #002d9c);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-3-3-3 {
  stroke: var(--cds-charts-3-3-3, #002d9c);
}
.cds--cc--chart-wrapper .stop-color-3-3-3 {
  stop-color: var(--cds-charts-3-3-3, #002d9c);
}
.cds--cc--chart-wrapper .fill-3-4-1 {
  fill: var(--cds-charts-3-4-1, #a56eff);
}
.cds--cc--chart-wrapper .fill-3-4-1.hovered {
  fill: var(--cds-charts-3-4-1-hovered, #a56eff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-3-4-1 {
  background-color: var(--cds-charts-3-4-1, #a56eff);
}
.cds--cc--chart-wrapper .background-3-4-1.hovered {
  background-color: var(--cds-charts-3-4-1-hovered, #a56eff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-3-4-1 {
  stroke: var(--cds-charts-3-4-1, #a56eff);
}
.cds--cc--chart-wrapper .stop-color-3-4-1 {
  stop-color: var(--cds-charts-3-4-1, #a56eff);
}
.cds--cc--chart-wrapper .fill-3-4-2 {
  fill: var(--cds-charts-3-4-2, #005d5d);
}
.cds--cc--chart-wrapper .fill-3-4-2.hovered {
  fill: var(--cds-charts-3-4-2-hovered, #005d5d);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-3-4-2 {
  background-color: var(--cds-charts-3-4-2, #005d5d);
}
.cds--cc--chart-wrapper .background-3-4-2.hovered {
  background-color: var(--cds-charts-3-4-2-hovered, #005d5d);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-3-4-2 {
  stroke: var(--cds-charts-3-4-2, #005d5d);
}
.cds--cc--chart-wrapper .stop-color-3-4-2 {
  stop-color: var(--cds-charts-3-4-2, #005d5d);
}
.cds--cc--chart-wrapper .fill-3-4-3 {
  fill: var(--cds-charts-3-4-3, #9f1853);
}
.cds--cc--chart-wrapper .fill-3-4-3.hovered {
  fill: var(--cds-charts-3-4-3-hovered, #9f1853);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-3-4-3 {
  background-color: var(--cds-charts-3-4-3, #9f1853);
}
.cds--cc--chart-wrapper .background-3-4-3.hovered {
  background-color: var(--cds-charts-3-4-3-hovered, #9f1853);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-3-4-3 {
  stroke: var(--cds-charts-3-4-3, #9f1853);
}
.cds--cc--chart-wrapper .stop-color-3-4-3 {
  stop-color: var(--cds-charts-3-4-3, #9f1853);
}
.cds--cc--chart-wrapper .fill-3-5-1 {
  fill: var(--cds-charts-3-5-1, #012749);
}
.cds--cc--chart-wrapper .fill-3-5-1.hovered {
  fill: var(--cds-charts-3-5-1-hovered, #012749);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-3-5-1 {
  background-color: var(--cds-charts-3-5-1, #012749);
}
.cds--cc--chart-wrapper .background-3-5-1.hovered {
  background-color: var(--cds-charts-3-5-1-hovered, #012749);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-3-5-1 {
  stroke: var(--cds-charts-3-5-1, #012749);
}
.cds--cc--chart-wrapper .stop-color-3-5-1 {
  stop-color: var(--cds-charts-3-5-1, #012749);
}
.cds--cc--chart-wrapper .fill-3-5-2 {
  fill: var(--cds-charts-3-5-2, #6929c4);
}
.cds--cc--chart-wrapper .fill-3-5-2.hovered {
  fill: var(--cds-charts-3-5-2-hovered, #6929c4);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-3-5-2 {
  background-color: var(--cds-charts-3-5-2, #6929c4);
}
.cds--cc--chart-wrapper .background-3-5-2.hovered {
  background-color: var(--cds-charts-3-5-2-hovered, #6929c4);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-3-5-2 {
  stroke: var(--cds-charts-3-5-2, #6929c4);
}
.cds--cc--chart-wrapper .stop-color-3-5-2 {
  stop-color: var(--cds-charts-3-5-2, #6929c4);
}
.cds--cc--chart-wrapper .fill-3-5-3 {
  fill: var(--cds-charts-3-5-3, #009d9a);
}
.cds--cc--chart-wrapper .fill-3-5-3.hovered {
  fill: var(--cds-charts-3-5-3-hovered, #009d9a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-3-5-3 {
  background-color: var(--cds-charts-3-5-3, #009d9a);
}
.cds--cc--chart-wrapper .background-3-5-3.hovered {
  background-color: var(--cds-charts-3-5-3-hovered, #009d9a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-3-5-3 {
  stroke: var(--cds-charts-3-5-3, #009d9a);
}
.cds--cc--chart-wrapper .stop-color-3-5-3 {
  stop-color: var(--cds-charts-3-5-3, #009d9a);
}
.cds--cc--chart-wrapper .fill-4-1-1 {
  fill: var(--cds-charts-4-1-1, #6929c4);
}
.cds--cc--chart-wrapper .fill-4-1-1.hovered {
  fill: var(--cds-charts-4-1-1-hovered, #6929c4);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-4-1-1 {
  background-color: var(--cds-charts-4-1-1, #6929c4);
}
.cds--cc--chart-wrapper .background-4-1-1.hovered {
  background-color: var(--cds-charts-4-1-1-hovered, #6929c4);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-4-1-1 {
  stroke: var(--cds-charts-4-1-1, #6929c4);
}
.cds--cc--chart-wrapper .stop-color-4-1-1 {
  stop-color: var(--cds-charts-4-1-1, #6929c4);
}
.cds--cc--chart-wrapper .fill-4-1-2 {
  fill: var(--cds-charts-4-1-2, #012749);
}
.cds--cc--chart-wrapper .fill-4-1-2.hovered {
  fill: var(--cds-charts-4-1-2-hovered, #012749);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-4-1-2 {
  background-color: var(--cds-charts-4-1-2, #012749);
}
.cds--cc--chart-wrapper .background-4-1-2.hovered {
  background-color: var(--cds-charts-4-1-2-hovered, #012749);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-4-1-2 {
  stroke: var(--cds-charts-4-1-2, #012749);
}
.cds--cc--chart-wrapper .stop-color-4-1-2 {
  stop-color: var(--cds-charts-4-1-2, #012749);
}
.cds--cc--chart-wrapper .fill-4-1-3 {
  fill: var(--cds-charts-4-1-3, #009d9a);
}
.cds--cc--chart-wrapper .fill-4-1-3.hovered {
  fill: var(--cds-charts-4-1-3-hovered, #009d9a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-4-1-3 {
  background-color: var(--cds-charts-4-1-3, #009d9a);
}
.cds--cc--chart-wrapper .background-4-1-3.hovered {
  background-color: var(--cds-charts-4-1-3-hovered, #009d9a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-4-1-3 {
  stroke: var(--cds-charts-4-1-3, #009d9a);
}
.cds--cc--chart-wrapper .stop-color-4-1-3 {
  stop-color: var(--cds-charts-4-1-3, #009d9a);
}
.cds--cc--chart-wrapper .fill-4-1-4 {
  fill: var(--cds-charts-4-1-4, #ee5396);
}
.cds--cc--chart-wrapper .fill-4-1-4.hovered {
  fill: var(--cds-charts-4-1-4-hovered, #ee5396);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-4-1-4 {
  background-color: var(--cds-charts-4-1-4, #ee5396);
}
.cds--cc--chart-wrapper .background-4-1-4.hovered {
  background-color: var(--cds-charts-4-1-4-hovered, #ee5396);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-4-1-4 {
  stroke: var(--cds-charts-4-1-4, #ee5396);
}
.cds--cc--chart-wrapper .stop-color-4-1-4 {
  stop-color: var(--cds-charts-4-1-4, #ee5396);
}
.cds--cc--chart-wrapper .fill-4-2-1 {
  fill: var(--cds-charts-4-2-1, #9f1853);
}
.cds--cc--chart-wrapper .fill-4-2-1.hovered {
  fill: var(--cds-charts-4-2-1-hovered, #9f1853);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-4-2-1 {
  background-color: var(--cds-charts-4-2-1, #9f1853);
}
.cds--cc--chart-wrapper .background-4-2-1.hovered {
  background-color: var(--cds-charts-4-2-1-hovered, #9f1853);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-4-2-1 {
  stroke: var(--cds-charts-4-2-1, #9f1853);
}
.cds--cc--chart-wrapper .stop-color-4-2-1 {
  stop-color: var(--cds-charts-4-2-1, #9f1853);
}
.cds--cc--chart-wrapper .fill-4-2-2 {
  fill: var(--cds-charts-4-2-2, #fa4d56);
}
.cds--cc--chart-wrapper .fill-4-2-2.hovered {
  fill: var(--cds-charts-4-2-2-hovered, #fa4d56);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-4-2-2 {
  background-color: var(--cds-charts-4-2-2, #fa4d56);
}
.cds--cc--chart-wrapper .background-4-2-2.hovered {
  background-color: var(--cds-charts-4-2-2-hovered, #fa4d56);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-4-2-2 {
  stroke: var(--cds-charts-4-2-2, #fa4d56);
}
.cds--cc--chart-wrapper .stop-color-4-2-2 {
  stop-color: var(--cds-charts-4-2-2, #fa4d56);
}
.cds--cc--chart-wrapper .fill-4-2-3 {
  fill: var(--cds-charts-4-2-3, #520408);
}
.cds--cc--chart-wrapper .fill-4-2-3.hovered {
  fill: var(--cds-charts-4-2-3-hovered, #520408);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-4-2-3 {
  background-color: var(--cds-charts-4-2-3, #520408);
}
.cds--cc--chart-wrapper .background-4-2-3.hovered {
  background-color: var(--cds-charts-4-2-3-hovered, #520408);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-4-2-3 {
  stroke: var(--cds-charts-4-2-3, #520408);
}
.cds--cc--chart-wrapper .stop-color-4-2-3 {
  stop-color: var(--cds-charts-4-2-3, #520408);
}
.cds--cc--chart-wrapper .fill-4-2-4 {
  fill: var(--cds-charts-4-2-4, #a56eff);
}
.cds--cc--chart-wrapper .fill-4-2-4.hovered {
  fill: var(--cds-charts-4-2-4-hovered, #a56eff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-4-2-4 {
  background-color: var(--cds-charts-4-2-4, #a56eff);
}
.cds--cc--chart-wrapper .background-4-2-4.hovered {
  background-color: var(--cds-charts-4-2-4-hovered, #a56eff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-4-2-4 {
  stroke: var(--cds-charts-4-2-4, #a56eff);
}
.cds--cc--chart-wrapper .stop-color-4-2-4 {
  stop-color: var(--cds-charts-4-2-4, #a56eff);
}
.cds--cc--chart-wrapper .fill-4-3-1 {
  fill: var(--cds-charts-4-3-1, #009d9a);
}
.cds--cc--chart-wrapper .fill-4-3-1.hovered {
  fill: var(--cds-charts-4-3-1-hovered, #009d9a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-4-3-1 {
  background-color: var(--cds-charts-4-3-1, #009d9a);
}
.cds--cc--chart-wrapper .background-4-3-1.hovered {
  background-color: var(--cds-charts-4-3-1-hovered, #009d9a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-4-3-1 {
  stroke: var(--cds-charts-4-3-1, #009d9a);
}
.cds--cc--chart-wrapper .stop-color-4-3-1 {
  stop-color: var(--cds-charts-4-3-1, #009d9a);
}
.cds--cc--chart-wrapper .fill-4-3-2 {
  fill: var(--cds-charts-4-3-2, #002d9c);
}
.cds--cc--chart-wrapper .fill-4-3-2.hovered {
  fill: var(--cds-charts-4-3-2-hovered, #002d9c);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-4-3-2 {
  background-color: var(--cds-charts-4-3-2, #002d9c);
}
.cds--cc--chart-wrapper .background-4-3-2.hovered {
  background-color: var(--cds-charts-4-3-2-hovered, #002d9c);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-4-3-2 {
  stroke: var(--cds-charts-4-3-2, #002d9c);
}
.cds--cc--chart-wrapper .stop-color-4-3-2 {
  stop-color: var(--cds-charts-4-3-2, #002d9c);
}
.cds--cc--chart-wrapper .fill-4-3-3 {
  fill: var(--cds-charts-4-3-3, #a56eff);
}
.cds--cc--chart-wrapper .fill-4-3-3.hovered {
  fill: var(--cds-charts-4-3-3-hovered, #a56eff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-4-3-3 {
  background-color: var(--cds-charts-4-3-3, #a56eff);
}
.cds--cc--chart-wrapper .background-4-3-3.hovered {
  background-color: var(--cds-charts-4-3-3-hovered, #a56eff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-4-3-3 {
  stroke: var(--cds-charts-4-3-3, #a56eff);
}
.cds--cc--chart-wrapper .stop-color-4-3-3 {
  stop-color: var(--cds-charts-4-3-3, #a56eff);
}
.cds--cc--chart-wrapper .fill-4-3-4 {
  fill: var(--cds-charts-4-3-4, #9f1853);
}
.cds--cc--chart-wrapper .fill-4-3-4.hovered {
  fill: var(--cds-charts-4-3-4-hovered, #9f1853);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-4-3-4 {
  background-color: var(--cds-charts-4-3-4, #9f1853);
}
.cds--cc--chart-wrapper .background-4-3-4.hovered {
  background-color: var(--cds-charts-4-3-4-hovered, #9f1853);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-4-3-4 {
  stroke: var(--cds-charts-4-3-4, #9f1853);
}
.cds--cc--chart-wrapper .stop-color-4-3-4 {
  stop-color: var(--cds-charts-4-3-4, #9f1853);
}
.cds--cc--chart-wrapper .fill-5-1-1 {
  fill: var(--cds-charts-5-1-1, #6929c4);
}
.cds--cc--chart-wrapper .fill-5-1-1.hovered {
  fill: var(--cds-charts-5-1-1-hovered, #6929c4);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-5-1-1 {
  background-color: var(--cds-charts-5-1-1, #6929c4);
}
.cds--cc--chart-wrapper .background-5-1-1.hovered {
  background-color: var(--cds-charts-5-1-1-hovered, #6929c4);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-5-1-1 {
  stroke: var(--cds-charts-5-1-1, #6929c4);
}
.cds--cc--chart-wrapper .stop-color-5-1-1 {
  stop-color: var(--cds-charts-5-1-1, #6929c4);
}
.cds--cc--chart-wrapper .fill-5-1-2 {
  fill: var(--cds-charts-5-1-2, #1192e8);
}
.cds--cc--chart-wrapper .fill-5-1-2.hovered {
  fill: var(--cds-charts-5-1-2-hovered, #1192e8);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-5-1-2 {
  background-color: var(--cds-charts-5-1-2, #1192e8);
}
.cds--cc--chart-wrapper .background-5-1-2.hovered {
  background-color: var(--cds-charts-5-1-2-hovered, #1192e8);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-5-1-2 {
  stroke: var(--cds-charts-5-1-2, #1192e8);
}
.cds--cc--chart-wrapper .stop-color-5-1-2 {
  stop-color: var(--cds-charts-5-1-2, #1192e8);
}
.cds--cc--chart-wrapper .fill-5-1-3 {
  fill: var(--cds-charts-5-1-3, #005d5d);
}
.cds--cc--chart-wrapper .fill-5-1-3.hovered {
  fill: var(--cds-charts-5-1-3-hovered, #005d5d);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-5-1-3 {
  background-color: var(--cds-charts-5-1-3, #005d5d);
}
.cds--cc--chart-wrapper .background-5-1-3.hovered {
  background-color: var(--cds-charts-5-1-3-hovered, #005d5d);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-5-1-3 {
  stroke: var(--cds-charts-5-1-3, #005d5d);
}
.cds--cc--chart-wrapper .stop-color-5-1-3 {
  stop-color: var(--cds-charts-5-1-3, #005d5d);
}
.cds--cc--chart-wrapper .fill-5-1-4 {
  fill: var(--cds-charts-5-1-4, #9f1853);
}
.cds--cc--chart-wrapper .fill-5-1-4.hovered {
  fill: var(--cds-charts-5-1-4-hovered, #9f1853);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-5-1-4 {
  background-color: var(--cds-charts-5-1-4, #9f1853);
}
.cds--cc--chart-wrapper .background-5-1-4.hovered {
  background-color: var(--cds-charts-5-1-4-hovered, #9f1853);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-5-1-4 {
  stroke: var(--cds-charts-5-1-4, #9f1853);
}
.cds--cc--chart-wrapper .stop-color-5-1-4 {
  stop-color: var(--cds-charts-5-1-4, #9f1853);
}
.cds--cc--chart-wrapper .fill-5-1-5 {
  fill: var(--cds-charts-5-1-5, #520408);
}
.cds--cc--chart-wrapper .fill-5-1-5.hovered {
  fill: var(--cds-charts-5-1-5-hovered, #520408);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-5-1-5 {
  background-color: var(--cds-charts-5-1-5, #520408);
}
.cds--cc--chart-wrapper .background-5-1-5.hovered {
  background-color: var(--cds-charts-5-1-5-hovered, #520408);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-5-1-5 {
  stroke: var(--cds-charts-5-1-5, #520408);
}
.cds--cc--chart-wrapper .stop-color-5-1-5 {
  stop-color: var(--cds-charts-5-1-5, #520408);
}
.cds--cc--chart-wrapper .fill-5-2-1 {
  fill: var(--cds-charts-5-2-1, #002d9c);
}
.cds--cc--chart-wrapper .fill-5-2-1.hovered {
  fill: var(--cds-charts-5-2-1-hovered, #002d9c);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-5-2-1 {
  background-color: var(--cds-charts-5-2-1, #002d9c);
}
.cds--cc--chart-wrapper .background-5-2-1.hovered {
  background-color: var(--cds-charts-5-2-1-hovered, #002d9c);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-5-2-1 {
  stroke: var(--cds-charts-5-2-1, #002d9c);
}
.cds--cc--chart-wrapper .stop-color-5-2-1 {
  stop-color: var(--cds-charts-5-2-1, #002d9c);
}
.cds--cc--chart-wrapper .fill-5-2-2 {
  fill: var(--cds-charts-5-2-2, #009d9a);
}
.cds--cc--chart-wrapper .fill-5-2-2.hovered {
  fill: var(--cds-charts-5-2-2-hovered, #009d9a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-5-2-2 {
  background-color: var(--cds-charts-5-2-2, #009d9a);
}
.cds--cc--chart-wrapper .background-5-2-2.hovered {
  background-color: var(--cds-charts-5-2-2-hovered, #009d9a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-5-2-2 {
  stroke: var(--cds-charts-5-2-2, #009d9a);
}
.cds--cc--chart-wrapper .stop-color-5-2-2 {
  stop-color: var(--cds-charts-5-2-2, #009d9a);
}
.cds--cc--chart-wrapper .fill-5-2-3 {
  fill: var(--cds-charts-5-2-3, #9f1853);
}
.cds--cc--chart-wrapper .fill-5-2-3.hovered {
  fill: var(--cds-charts-5-2-3-hovered, #9f1853);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-5-2-3 {
  background-color: var(--cds-charts-5-2-3, #9f1853);
}
.cds--cc--chart-wrapper .background-5-2-3.hovered {
  background-color: var(--cds-charts-5-2-3-hovered, #9f1853);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-5-2-3 {
  stroke: var(--cds-charts-5-2-3, #9f1853);
}
.cds--cc--chart-wrapper .stop-color-5-2-3 {
  stop-color: var(--cds-charts-5-2-3, #9f1853);
}
.cds--cc--chart-wrapper .fill-5-2-4 {
  fill: var(--cds-charts-5-2-4, #520408);
}
.cds--cc--chart-wrapper .fill-5-2-4.hovered {
  fill: var(--cds-charts-5-2-4-hovered, #520408);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-5-2-4 {
  background-color: var(--cds-charts-5-2-4, #520408);
}
.cds--cc--chart-wrapper .background-5-2-4.hovered {
  background-color: var(--cds-charts-5-2-4-hovered, #520408);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-5-2-4 {
  stroke: var(--cds-charts-5-2-4, #520408);
}
.cds--cc--chart-wrapper .stop-color-5-2-4 {
  stop-color: var(--cds-charts-5-2-4, #520408);
}
.cds--cc--chart-wrapper .fill-5-2-5 {
  fill: var(--cds-charts-5-2-5, #a56eff);
}
.cds--cc--chart-wrapper .fill-5-2-5.hovered {
  fill: var(--cds-charts-5-2-5-hovered, #a56eff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-5-2-5 {
  background-color: var(--cds-charts-5-2-5, #a56eff);
}
.cds--cc--chart-wrapper .background-5-2-5.hovered {
  background-color: var(--cds-charts-5-2-5-hovered, #a56eff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-5-2-5 {
  stroke: var(--cds-charts-5-2-5, #a56eff);
}
.cds--cc--chart-wrapper .stop-color-5-2-5 {
  stop-color: var(--cds-charts-5-2-5, #a56eff);
}
.cds--cc--chart-wrapper .fill-14-1-1 {
  fill: var(--cds-charts-14-1-1, #6929c4);
}
.cds--cc--chart-wrapper .fill-14-1-1.hovered {
  fill: var(--cds-charts-14-1-1-hovered, #6929c4);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-14-1-1 {
  background-color: var(--cds-charts-14-1-1, #6929c4);
}
.cds--cc--chart-wrapper .background-14-1-1.hovered {
  background-color: var(--cds-charts-14-1-1-hovered, #6929c4);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-14-1-1 {
  stroke: var(--cds-charts-14-1-1, #6929c4);
}
.cds--cc--chart-wrapper .stop-color-14-1-1 {
  stop-color: var(--cds-charts-14-1-1, #6929c4);
}
.cds--cc--chart-wrapper .fill-14-1-2 {
  fill: var(--cds-charts-14-1-2, #1192e8);
}
.cds--cc--chart-wrapper .fill-14-1-2.hovered {
  fill: var(--cds-charts-14-1-2-hovered, #1192e8);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-14-1-2 {
  background-color: var(--cds-charts-14-1-2, #1192e8);
}
.cds--cc--chart-wrapper .background-14-1-2.hovered {
  background-color: var(--cds-charts-14-1-2-hovered, #1192e8);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-14-1-2 {
  stroke: var(--cds-charts-14-1-2, #1192e8);
}
.cds--cc--chart-wrapper .stop-color-14-1-2 {
  stop-color: var(--cds-charts-14-1-2, #1192e8);
}
.cds--cc--chart-wrapper .fill-14-1-3 {
  fill: var(--cds-charts-14-1-3, #005d5d);
}
.cds--cc--chart-wrapper .fill-14-1-3.hovered {
  fill: var(--cds-charts-14-1-3-hovered, #005d5d);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-14-1-3 {
  background-color: var(--cds-charts-14-1-3, #005d5d);
}
.cds--cc--chart-wrapper .background-14-1-3.hovered {
  background-color: var(--cds-charts-14-1-3-hovered, #005d5d);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-14-1-3 {
  stroke: var(--cds-charts-14-1-3, #005d5d);
}
.cds--cc--chart-wrapper .stop-color-14-1-3 {
  stop-color: var(--cds-charts-14-1-3, #005d5d);
}
.cds--cc--chart-wrapper .fill-14-1-4 {
  fill: var(--cds-charts-14-1-4, #9f1853);
}
.cds--cc--chart-wrapper .fill-14-1-4.hovered {
  fill: var(--cds-charts-14-1-4-hovered, #9f1853);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-14-1-4 {
  background-color: var(--cds-charts-14-1-4, #9f1853);
}
.cds--cc--chart-wrapper .background-14-1-4.hovered {
  background-color: var(--cds-charts-14-1-4-hovered, #9f1853);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-14-1-4 {
  stroke: var(--cds-charts-14-1-4, #9f1853);
}
.cds--cc--chart-wrapper .stop-color-14-1-4 {
  stop-color: var(--cds-charts-14-1-4, #9f1853);
}
.cds--cc--chart-wrapper .fill-14-1-5 {
  fill: var(--cds-charts-14-1-5, #fa4d56);
}
.cds--cc--chart-wrapper .fill-14-1-5.hovered {
  fill: var(--cds-charts-14-1-5-hovered, #fa4d56);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-14-1-5 {
  background-color: var(--cds-charts-14-1-5, #fa4d56);
}
.cds--cc--chart-wrapper .background-14-1-5.hovered {
  background-color: var(--cds-charts-14-1-5-hovered, #fa4d56);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-14-1-5 {
  stroke: var(--cds-charts-14-1-5, #fa4d56);
}
.cds--cc--chart-wrapper .stop-color-14-1-5 {
  stop-color: var(--cds-charts-14-1-5, #fa4d56);
}
.cds--cc--chart-wrapper .fill-14-1-6 {
  fill: var(--cds-charts-14-1-6, #520408);
}
.cds--cc--chart-wrapper .fill-14-1-6.hovered {
  fill: var(--cds-charts-14-1-6-hovered, #520408);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-14-1-6 {
  background-color: var(--cds-charts-14-1-6, #520408);
}
.cds--cc--chart-wrapper .background-14-1-6.hovered {
  background-color: var(--cds-charts-14-1-6-hovered, #520408);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-14-1-6 {
  stroke: var(--cds-charts-14-1-6, #520408);
}
.cds--cc--chart-wrapper .stop-color-14-1-6 {
  stop-color: var(--cds-charts-14-1-6, #520408);
}
.cds--cc--chart-wrapper .fill-14-1-7 {
  fill: var(--cds-charts-14-1-7, #198038);
}
.cds--cc--chart-wrapper .fill-14-1-7.hovered {
  fill: var(--cds-charts-14-1-7-hovered, #198038);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-14-1-7 {
  background-color: var(--cds-charts-14-1-7, #198038);
}
.cds--cc--chart-wrapper .background-14-1-7.hovered {
  background-color: var(--cds-charts-14-1-7-hovered, #198038);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-14-1-7 {
  stroke: var(--cds-charts-14-1-7, #198038);
}
.cds--cc--chart-wrapper .stop-color-14-1-7 {
  stop-color: var(--cds-charts-14-1-7, #198038);
}
.cds--cc--chart-wrapper .fill-14-1-8 {
  fill: var(--cds-charts-14-1-8, #002d9c);
}
.cds--cc--chart-wrapper .fill-14-1-8.hovered {
  fill: var(--cds-charts-14-1-8-hovered, #002d9c);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-14-1-8 {
  background-color: var(--cds-charts-14-1-8, #002d9c);
}
.cds--cc--chart-wrapper .background-14-1-8.hovered {
  background-color: var(--cds-charts-14-1-8-hovered, #002d9c);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-14-1-8 {
  stroke: var(--cds-charts-14-1-8, #002d9c);
}
.cds--cc--chart-wrapper .stop-color-14-1-8 {
  stop-color: var(--cds-charts-14-1-8, #002d9c);
}
.cds--cc--chart-wrapper .fill-14-1-9 {
  fill: var(--cds-charts-14-1-9, #ee5396);
}
.cds--cc--chart-wrapper .fill-14-1-9.hovered {
  fill: var(--cds-charts-14-1-9-hovered, #ee5396);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-14-1-9 {
  background-color: var(--cds-charts-14-1-9, #ee5396);
}
.cds--cc--chart-wrapper .background-14-1-9.hovered {
  background-color: var(--cds-charts-14-1-9-hovered, #ee5396);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-14-1-9 {
  stroke: var(--cds-charts-14-1-9, #ee5396);
}
.cds--cc--chart-wrapper .stop-color-14-1-9 {
  stop-color: var(--cds-charts-14-1-9, #ee5396);
}
.cds--cc--chart-wrapper .fill-14-1-10 {
  fill: var(--cds-charts-14-1-10, #b28600);
}
.cds--cc--chart-wrapper .fill-14-1-10.hovered {
  fill: var(--cds-charts-14-1-10-hovered, #b28600);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-14-1-10 {
  background-color: var(--cds-charts-14-1-10, #b28600);
}
.cds--cc--chart-wrapper .background-14-1-10.hovered {
  background-color: var(--cds-charts-14-1-10-hovered, #b28600);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-14-1-10 {
  stroke: var(--cds-charts-14-1-10, #b28600);
}
.cds--cc--chart-wrapper .stop-color-14-1-10 {
  stop-color: var(--cds-charts-14-1-10, #b28600);
}
.cds--cc--chart-wrapper .fill-14-1-11 {
  fill: var(--cds-charts-14-1-11, #009d9a);
}
.cds--cc--chart-wrapper .fill-14-1-11.hovered {
  fill: var(--cds-charts-14-1-11-hovered, #009d9a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-14-1-11 {
  background-color: var(--cds-charts-14-1-11, #009d9a);
}
.cds--cc--chart-wrapper .background-14-1-11.hovered {
  background-color: var(--cds-charts-14-1-11-hovered, #009d9a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-14-1-11 {
  stroke: var(--cds-charts-14-1-11, #009d9a);
}
.cds--cc--chart-wrapper .stop-color-14-1-11 {
  stop-color: var(--cds-charts-14-1-11, #009d9a);
}
.cds--cc--chart-wrapper .fill-14-1-12 {
  fill: var(--cds-charts-14-1-12, #012749);
}
.cds--cc--chart-wrapper .fill-14-1-12.hovered {
  fill: var(--cds-charts-14-1-12-hovered, #012749);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-14-1-12 {
  background-color: var(--cds-charts-14-1-12, #012749);
}
.cds--cc--chart-wrapper .background-14-1-12.hovered {
  background-color: var(--cds-charts-14-1-12-hovered, #012749);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-14-1-12 {
  stroke: var(--cds-charts-14-1-12, #012749);
}
.cds--cc--chart-wrapper .stop-color-14-1-12 {
  stop-color: var(--cds-charts-14-1-12, #012749);
}
.cds--cc--chart-wrapper .fill-14-1-13 {
  fill: var(--cds-charts-14-1-13, #8a3800);
}
.cds--cc--chart-wrapper .fill-14-1-13.hovered {
  fill: var(--cds-charts-14-1-13-hovered, #8a3800);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-14-1-13 {
  background-color: var(--cds-charts-14-1-13, #8a3800);
}
.cds--cc--chart-wrapper .background-14-1-13.hovered {
  background-color: var(--cds-charts-14-1-13-hovered, #8a3800);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-14-1-13 {
  stroke: var(--cds-charts-14-1-13, #8a3800);
}
.cds--cc--chart-wrapper .stop-color-14-1-13 {
  stop-color: var(--cds-charts-14-1-13, #8a3800);
}
.cds--cc--chart-wrapper .fill-14-1-14 {
  fill: var(--cds-charts-14-1-14, #a56eff);
}
.cds--cc--chart-wrapper .fill-14-1-14.hovered {
  fill: var(--cds-charts-14-1-14-hovered, #a56eff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-14-1-14 {
  background-color: var(--cds-charts-14-1-14, #a56eff);
}
.cds--cc--chart-wrapper .background-14-1-14.hovered {
  background-color: var(--cds-charts-14-1-14-hovered, #a56eff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-14-1-14 {
  stroke: var(--cds-charts-14-1-14, #a56eff);
}
.cds--cc--chart-wrapper .stop-color-14-1-14 {
  stop-color: var(--cds-charts-14-1-14, #a56eff);
}
.cds--cc--chart-wrapper .fill-mono-1-1 {
  fill: var(--cds-charts-mono-1-1, #ffffff);
}
.cds--cc--chart-wrapper .fill-mono-1-1.hovered {
  fill: var(--cds-charts-mono-1-1-hovered, #ffffff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-1-1 {
  background-color: var(--cds-charts-mono-1-1, #ffffff);
}
.cds--cc--chart-wrapper .background-mono-1-1.hovered {
  background-color: var(--cds-charts-mono-1-1-hovered, #ffffff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-1-1 {
  stroke: var(--cds-charts-mono-1-1, #ffffff);
}
.cds--cc--chart-wrapper .stop-color-mono-1-1 {
  stop-color: var(--cds-charts-mono-1-1, #ffffff);
}
.cds--cc--chart-wrapper .fill-mono-1-2 {
  fill: var(--cds-charts-mono-1-2, #f6f2ff);
}
.cds--cc--chart-wrapper .fill-mono-1-2.hovered {
  fill: var(--cds-charts-mono-1-2-hovered, #f6f2ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-1-2 {
  background-color: var(--cds-charts-mono-1-2, #f6f2ff);
}
.cds--cc--chart-wrapper .background-mono-1-2.hovered {
  background-color: var(--cds-charts-mono-1-2-hovered, #f6f2ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-1-2 {
  stroke: var(--cds-charts-mono-1-2, #f6f2ff);
}
.cds--cc--chart-wrapper .stop-color-mono-1-2 {
  stop-color: var(--cds-charts-mono-1-2, #f6f2ff);
}
.cds--cc--chart-wrapper .fill-mono-1-3 {
  fill: var(--cds-charts-mono-1-3, #e8daff);
}
.cds--cc--chart-wrapper .fill-mono-1-3.hovered {
  fill: var(--cds-charts-mono-1-3-hovered, #e8daff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-1-3 {
  background-color: var(--cds-charts-mono-1-3, #e8daff);
}
.cds--cc--chart-wrapper .background-mono-1-3.hovered {
  background-color: var(--cds-charts-mono-1-3-hovered, #e8daff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-1-3 {
  stroke: var(--cds-charts-mono-1-3, #e8daff);
}
.cds--cc--chart-wrapper .stop-color-mono-1-3 {
  stop-color: var(--cds-charts-mono-1-3, #e8daff);
}
.cds--cc--chart-wrapper .fill-mono-1-4 {
  fill: var(--cds-charts-mono-1-4, #d4bbff);
}
.cds--cc--chart-wrapper .fill-mono-1-4.hovered {
  fill: var(--cds-charts-mono-1-4-hovered, #d4bbff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-1-4 {
  background-color: var(--cds-charts-mono-1-4, #d4bbff);
}
.cds--cc--chart-wrapper .background-mono-1-4.hovered {
  background-color: var(--cds-charts-mono-1-4-hovered, #d4bbff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-1-4 {
  stroke: var(--cds-charts-mono-1-4, #d4bbff);
}
.cds--cc--chart-wrapper .stop-color-mono-1-4 {
  stop-color: var(--cds-charts-mono-1-4, #d4bbff);
}
.cds--cc--chart-wrapper .fill-mono-1-5 {
  fill: var(--cds-charts-mono-1-5, #be95ff);
}
.cds--cc--chart-wrapper .fill-mono-1-5.hovered {
  fill: var(--cds-charts-mono-1-5-hovered, #be95ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-1-5 {
  background-color: var(--cds-charts-mono-1-5, #be95ff);
}
.cds--cc--chart-wrapper .background-mono-1-5.hovered {
  background-color: var(--cds-charts-mono-1-5-hovered, #be95ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-1-5 {
  stroke: var(--cds-charts-mono-1-5, #be95ff);
}
.cds--cc--chart-wrapper .stop-color-mono-1-5 {
  stop-color: var(--cds-charts-mono-1-5, #be95ff);
}
.cds--cc--chart-wrapper .fill-mono-1-6 {
  fill: var(--cds-charts-mono-1-6, #a56eff);
}
.cds--cc--chart-wrapper .fill-mono-1-6.hovered {
  fill: var(--cds-charts-mono-1-6-hovered, #a56eff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-1-6 {
  background-color: var(--cds-charts-mono-1-6, #a56eff);
}
.cds--cc--chart-wrapper .background-mono-1-6.hovered {
  background-color: var(--cds-charts-mono-1-6-hovered, #a56eff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-1-6 {
  stroke: var(--cds-charts-mono-1-6, #a56eff);
}
.cds--cc--chart-wrapper .stop-color-mono-1-6 {
  stop-color: var(--cds-charts-mono-1-6, #a56eff);
}
.cds--cc--chart-wrapper .fill-mono-1-7 {
  fill: var(--cds-charts-mono-1-7, #8a3ffc);
}
.cds--cc--chart-wrapper .fill-mono-1-7.hovered {
  fill: var(--cds-charts-mono-1-7-hovered, #8a3ffc);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-1-7 {
  background-color: var(--cds-charts-mono-1-7, #8a3ffc);
}
.cds--cc--chart-wrapper .background-mono-1-7.hovered {
  background-color: var(--cds-charts-mono-1-7-hovered, #8a3ffc);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-1-7 {
  stroke: var(--cds-charts-mono-1-7, #8a3ffc);
}
.cds--cc--chart-wrapper .stop-color-mono-1-7 {
  stop-color: var(--cds-charts-mono-1-7, #8a3ffc);
}
.cds--cc--chart-wrapper .fill-mono-1-8 {
  fill: var(--cds-charts-mono-1-8, #6929c4);
}
.cds--cc--chart-wrapper .fill-mono-1-8.hovered {
  fill: var(--cds-charts-mono-1-8-hovered, #6929c4);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-1-8 {
  background-color: var(--cds-charts-mono-1-8, #6929c4);
}
.cds--cc--chart-wrapper .background-mono-1-8.hovered {
  background-color: var(--cds-charts-mono-1-8-hovered, #6929c4);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-1-8 {
  stroke: var(--cds-charts-mono-1-8, #6929c4);
}
.cds--cc--chart-wrapper .stop-color-mono-1-8 {
  stop-color: var(--cds-charts-mono-1-8, #6929c4);
}
.cds--cc--chart-wrapper .fill-mono-1-9 {
  fill: var(--cds-charts-mono-1-9, #491d8b);
}
.cds--cc--chart-wrapper .fill-mono-1-9.hovered {
  fill: var(--cds-charts-mono-1-9-hovered, #491d8b);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-1-9 {
  background-color: var(--cds-charts-mono-1-9, #491d8b);
}
.cds--cc--chart-wrapper .background-mono-1-9.hovered {
  background-color: var(--cds-charts-mono-1-9-hovered, #491d8b);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-1-9 {
  stroke: var(--cds-charts-mono-1-9, #491d8b);
}
.cds--cc--chart-wrapper .stop-color-mono-1-9 {
  stop-color: var(--cds-charts-mono-1-9, #491d8b);
}
.cds--cc--chart-wrapper .fill-mono-1-10 {
  fill: var(--cds-charts-mono-1-10, #31135e);
}
.cds--cc--chart-wrapper .fill-mono-1-10.hovered {
  fill: var(--cds-charts-mono-1-10-hovered, #31135e);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-1-10 {
  background-color: var(--cds-charts-mono-1-10, #31135e);
}
.cds--cc--chart-wrapper .background-mono-1-10.hovered {
  background-color: var(--cds-charts-mono-1-10-hovered, #31135e);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-1-10 {
  stroke: var(--cds-charts-mono-1-10, #31135e);
}
.cds--cc--chart-wrapper .stop-color-mono-1-10 {
  stop-color: var(--cds-charts-mono-1-10, #31135e);
}
.cds--cc--chart-wrapper .fill-mono-1-11 {
  fill: var(--cds-charts-mono-1-11, #1c0f30);
}
.cds--cc--chart-wrapper .fill-mono-1-11.hovered {
  fill: var(--cds-charts-mono-1-11-hovered, #1c0f30);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-1-11 {
  background-color: var(--cds-charts-mono-1-11, #1c0f30);
}
.cds--cc--chart-wrapper .background-mono-1-11.hovered {
  background-color: var(--cds-charts-mono-1-11-hovered, #1c0f30);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-1-11 {
  stroke: var(--cds-charts-mono-1-11, #1c0f30);
}
.cds--cc--chart-wrapper .stop-color-mono-1-11 {
  stop-color: var(--cds-charts-mono-1-11, #1c0f30);
}
.cds--cc--chart-wrapper .fill-mono-2-1 {
  fill: var(--cds-charts-mono-2-1, #ffffff);
}
.cds--cc--chart-wrapper .fill-mono-2-1.hovered {
  fill: var(--cds-charts-mono-2-1-hovered, #ffffff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-2-1 {
  background-color: var(--cds-charts-mono-2-1, #ffffff);
}
.cds--cc--chart-wrapper .background-mono-2-1.hovered {
  background-color: var(--cds-charts-mono-2-1-hovered, #ffffff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-2-1 {
  stroke: var(--cds-charts-mono-2-1, #ffffff);
}
.cds--cc--chart-wrapper .stop-color-mono-2-1 {
  stop-color: var(--cds-charts-mono-2-1, #ffffff);
}
.cds--cc--chart-wrapper .fill-mono-2-2 {
  fill: var(--cds-charts-mono-2-2, #edf5ff);
}
.cds--cc--chart-wrapper .fill-mono-2-2.hovered {
  fill: var(--cds-charts-mono-2-2-hovered, #edf5ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-2-2 {
  background-color: var(--cds-charts-mono-2-2, #edf5ff);
}
.cds--cc--chart-wrapper .background-mono-2-2.hovered {
  background-color: var(--cds-charts-mono-2-2-hovered, #edf5ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-2-2 {
  stroke: var(--cds-charts-mono-2-2, #edf5ff);
}
.cds--cc--chart-wrapper .stop-color-mono-2-2 {
  stop-color: var(--cds-charts-mono-2-2, #edf5ff);
}
.cds--cc--chart-wrapper .fill-mono-2-3 {
  fill: var(--cds-charts-mono-2-3, #d0e2ff);
}
.cds--cc--chart-wrapper .fill-mono-2-3.hovered {
  fill: var(--cds-charts-mono-2-3-hovered, #d0e2ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-2-3 {
  background-color: var(--cds-charts-mono-2-3, #d0e2ff);
}
.cds--cc--chart-wrapper .background-mono-2-3.hovered {
  background-color: var(--cds-charts-mono-2-3-hovered, #d0e2ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-2-3 {
  stroke: var(--cds-charts-mono-2-3, #d0e2ff);
}
.cds--cc--chart-wrapper .stop-color-mono-2-3 {
  stop-color: var(--cds-charts-mono-2-3, #d0e2ff);
}
.cds--cc--chart-wrapper .fill-mono-2-4 {
  fill: var(--cds-charts-mono-2-4, #a6c8ff);
}
.cds--cc--chart-wrapper .fill-mono-2-4.hovered {
  fill: var(--cds-charts-mono-2-4-hovered, #a6c8ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-2-4 {
  background-color: var(--cds-charts-mono-2-4, #a6c8ff);
}
.cds--cc--chart-wrapper .background-mono-2-4.hovered {
  background-color: var(--cds-charts-mono-2-4-hovered, #a6c8ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-2-4 {
  stroke: var(--cds-charts-mono-2-4, #a6c8ff);
}
.cds--cc--chart-wrapper .stop-color-mono-2-4 {
  stop-color: var(--cds-charts-mono-2-4, #a6c8ff);
}
.cds--cc--chart-wrapper .fill-mono-2-5 {
  fill: var(--cds-charts-mono-2-5, #78a9ff);
}
.cds--cc--chart-wrapper .fill-mono-2-5.hovered {
  fill: var(--cds-charts-mono-2-5-hovered, #78a9ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-2-5 {
  background-color: var(--cds-charts-mono-2-5, #78a9ff);
}
.cds--cc--chart-wrapper .background-mono-2-5.hovered {
  background-color: var(--cds-charts-mono-2-5-hovered, #78a9ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-2-5 {
  stroke: var(--cds-charts-mono-2-5, #78a9ff);
}
.cds--cc--chart-wrapper .stop-color-mono-2-5 {
  stop-color: var(--cds-charts-mono-2-5, #78a9ff);
}
.cds--cc--chart-wrapper .fill-mono-2-6 {
  fill: var(--cds-charts-mono-2-6, #4589ff);
}
.cds--cc--chart-wrapper .fill-mono-2-6.hovered {
  fill: var(--cds-charts-mono-2-6-hovered, #4589ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-2-6 {
  background-color: var(--cds-charts-mono-2-6, #4589ff);
}
.cds--cc--chart-wrapper .background-mono-2-6.hovered {
  background-color: var(--cds-charts-mono-2-6-hovered, #4589ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-2-6 {
  stroke: var(--cds-charts-mono-2-6, #4589ff);
}
.cds--cc--chart-wrapper .stop-color-mono-2-6 {
  stop-color: var(--cds-charts-mono-2-6, #4589ff);
}
.cds--cc--chart-wrapper .fill-mono-2-7 {
  fill: var(--cds-charts-mono-2-7, #0f62fe);
}
.cds--cc--chart-wrapper .fill-mono-2-7.hovered {
  fill: var(--cds-charts-mono-2-7-hovered, #0f62fe);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-2-7 {
  background-color: var(--cds-charts-mono-2-7, #0f62fe);
}
.cds--cc--chart-wrapper .background-mono-2-7.hovered {
  background-color: var(--cds-charts-mono-2-7-hovered, #0f62fe);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-2-7 {
  stroke: var(--cds-charts-mono-2-7, #0f62fe);
}
.cds--cc--chart-wrapper .stop-color-mono-2-7 {
  stop-color: var(--cds-charts-mono-2-7, #0f62fe);
}
.cds--cc--chart-wrapper .fill-mono-2-8 {
  fill: var(--cds-charts-mono-2-8, #0043ce);
}
.cds--cc--chart-wrapper .fill-mono-2-8.hovered {
  fill: var(--cds-charts-mono-2-8-hovered, #0043ce);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-2-8 {
  background-color: var(--cds-charts-mono-2-8, #0043ce);
}
.cds--cc--chart-wrapper .background-mono-2-8.hovered {
  background-color: var(--cds-charts-mono-2-8-hovered, #0043ce);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-2-8 {
  stroke: var(--cds-charts-mono-2-8, #0043ce);
}
.cds--cc--chart-wrapper .stop-color-mono-2-8 {
  stop-color: var(--cds-charts-mono-2-8, #0043ce);
}
.cds--cc--chart-wrapper .fill-mono-2-9 {
  fill: var(--cds-charts-mono-2-9, #002d9c);
}
.cds--cc--chart-wrapper .fill-mono-2-9.hovered {
  fill: var(--cds-charts-mono-2-9-hovered, #002d9c);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-2-9 {
  background-color: var(--cds-charts-mono-2-9, #002d9c);
}
.cds--cc--chart-wrapper .background-mono-2-9.hovered {
  background-color: var(--cds-charts-mono-2-9-hovered, #002d9c);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-2-9 {
  stroke: var(--cds-charts-mono-2-9, #002d9c);
}
.cds--cc--chart-wrapper .stop-color-mono-2-9 {
  stop-color: var(--cds-charts-mono-2-9, #002d9c);
}
.cds--cc--chart-wrapper .fill-mono-2-10 {
  fill: var(--cds-charts-mono-2-10, #001d6c);
}
.cds--cc--chart-wrapper .fill-mono-2-10.hovered {
  fill: var(--cds-charts-mono-2-10-hovered, #001d6c);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-2-10 {
  background-color: var(--cds-charts-mono-2-10, #001d6c);
}
.cds--cc--chart-wrapper .background-mono-2-10.hovered {
  background-color: var(--cds-charts-mono-2-10-hovered, #001d6c);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-2-10 {
  stroke: var(--cds-charts-mono-2-10, #001d6c);
}
.cds--cc--chart-wrapper .stop-color-mono-2-10 {
  stop-color: var(--cds-charts-mono-2-10, #001d6c);
}
.cds--cc--chart-wrapper .fill-mono-2-11 {
  fill: var(--cds-charts-mono-2-11, #001141);
}
.cds--cc--chart-wrapper .fill-mono-2-11.hovered {
  fill: var(--cds-charts-mono-2-11-hovered, #001141);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-2-11 {
  background-color: var(--cds-charts-mono-2-11, #001141);
}
.cds--cc--chart-wrapper .background-mono-2-11.hovered {
  background-color: var(--cds-charts-mono-2-11-hovered, #001141);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-2-11 {
  stroke: var(--cds-charts-mono-2-11, #001141);
}
.cds--cc--chart-wrapper .stop-color-mono-2-11 {
  stop-color: var(--cds-charts-mono-2-11, #001141);
}
.cds--cc--chart-wrapper .fill-mono-3-1 {
  fill: var(--cds-charts-mono-3-1, #ffffff);
}
.cds--cc--chart-wrapper .fill-mono-3-1.hovered {
  fill: var(--cds-charts-mono-3-1-hovered, #ffffff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-3-1 {
  background-color: var(--cds-charts-mono-3-1, #ffffff);
}
.cds--cc--chart-wrapper .background-mono-3-1.hovered {
  background-color: var(--cds-charts-mono-3-1-hovered, #ffffff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-3-1 {
  stroke: var(--cds-charts-mono-3-1, #ffffff);
}
.cds--cc--chart-wrapper .stop-color-mono-3-1 {
  stop-color: var(--cds-charts-mono-3-1, #ffffff);
}
.cds--cc--chart-wrapper .fill-mono-3-2 {
  fill: var(--cds-charts-mono-3-2, #e5f6ff);
}
.cds--cc--chart-wrapper .fill-mono-3-2.hovered {
  fill: var(--cds-charts-mono-3-2-hovered, #e5f6ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-3-2 {
  background-color: var(--cds-charts-mono-3-2, #e5f6ff);
}
.cds--cc--chart-wrapper .background-mono-3-2.hovered {
  background-color: var(--cds-charts-mono-3-2-hovered, #e5f6ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-3-2 {
  stroke: var(--cds-charts-mono-3-2, #e5f6ff);
}
.cds--cc--chart-wrapper .stop-color-mono-3-2 {
  stop-color: var(--cds-charts-mono-3-2, #e5f6ff);
}
.cds--cc--chart-wrapper .fill-mono-3-3 {
  fill: var(--cds-charts-mono-3-3, #bae6ff);
}
.cds--cc--chart-wrapper .fill-mono-3-3.hovered {
  fill: var(--cds-charts-mono-3-3-hovered, #bae6ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-3-3 {
  background-color: var(--cds-charts-mono-3-3, #bae6ff);
}
.cds--cc--chart-wrapper .background-mono-3-3.hovered {
  background-color: var(--cds-charts-mono-3-3-hovered, #bae6ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-3-3 {
  stroke: var(--cds-charts-mono-3-3, #bae6ff);
}
.cds--cc--chart-wrapper .stop-color-mono-3-3 {
  stop-color: var(--cds-charts-mono-3-3, #bae6ff);
}
.cds--cc--chart-wrapper .fill-mono-3-4 {
  fill: var(--cds-charts-mono-3-4, #82cfff);
}
.cds--cc--chart-wrapper .fill-mono-3-4.hovered {
  fill: var(--cds-charts-mono-3-4-hovered, #82cfff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-3-4 {
  background-color: var(--cds-charts-mono-3-4, #82cfff);
}
.cds--cc--chart-wrapper .background-mono-3-4.hovered {
  background-color: var(--cds-charts-mono-3-4-hovered, #82cfff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-3-4 {
  stroke: var(--cds-charts-mono-3-4, #82cfff);
}
.cds--cc--chart-wrapper .stop-color-mono-3-4 {
  stop-color: var(--cds-charts-mono-3-4, #82cfff);
}
.cds--cc--chart-wrapper .fill-mono-3-5 {
  fill: var(--cds-charts-mono-3-5, #33b1ff);
}
.cds--cc--chart-wrapper .fill-mono-3-5.hovered {
  fill: var(--cds-charts-mono-3-5-hovered, #33b1ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-3-5 {
  background-color: var(--cds-charts-mono-3-5, #33b1ff);
}
.cds--cc--chart-wrapper .background-mono-3-5.hovered {
  background-color: var(--cds-charts-mono-3-5-hovered, #33b1ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-3-5 {
  stroke: var(--cds-charts-mono-3-5, #33b1ff);
}
.cds--cc--chart-wrapper .stop-color-mono-3-5 {
  stop-color: var(--cds-charts-mono-3-5, #33b1ff);
}
.cds--cc--chart-wrapper .fill-mono-3-6 {
  fill: var(--cds-charts-mono-3-6, #1192e8);
}
.cds--cc--chart-wrapper .fill-mono-3-6.hovered {
  fill: var(--cds-charts-mono-3-6-hovered, #1192e8);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-3-6 {
  background-color: var(--cds-charts-mono-3-6, #1192e8);
}
.cds--cc--chart-wrapper .background-mono-3-6.hovered {
  background-color: var(--cds-charts-mono-3-6-hovered, #1192e8);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-3-6 {
  stroke: var(--cds-charts-mono-3-6, #1192e8);
}
.cds--cc--chart-wrapper .stop-color-mono-3-6 {
  stop-color: var(--cds-charts-mono-3-6, #1192e8);
}
.cds--cc--chart-wrapper .fill-mono-3-7 {
  fill: var(--cds-charts-mono-3-7, #0072c3);
}
.cds--cc--chart-wrapper .fill-mono-3-7.hovered {
  fill: var(--cds-charts-mono-3-7-hovered, #0072c3);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-3-7 {
  background-color: var(--cds-charts-mono-3-7, #0072c3);
}
.cds--cc--chart-wrapper .background-mono-3-7.hovered {
  background-color: var(--cds-charts-mono-3-7-hovered, #0072c3);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-3-7 {
  stroke: var(--cds-charts-mono-3-7, #0072c3);
}
.cds--cc--chart-wrapper .stop-color-mono-3-7 {
  stop-color: var(--cds-charts-mono-3-7, #0072c3);
}
.cds--cc--chart-wrapper .fill-mono-3-8 {
  fill: var(--cds-charts-mono-3-8, #00539a);
}
.cds--cc--chart-wrapper .fill-mono-3-8.hovered {
  fill: var(--cds-charts-mono-3-8-hovered, #00539a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-3-8 {
  background-color: var(--cds-charts-mono-3-8, #00539a);
}
.cds--cc--chart-wrapper .background-mono-3-8.hovered {
  background-color: var(--cds-charts-mono-3-8-hovered, #00539a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-3-8 {
  stroke: var(--cds-charts-mono-3-8, #00539a);
}
.cds--cc--chart-wrapper .stop-color-mono-3-8 {
  stop-color: var(--cds-charts-mono-3-8, #00539a);
}
.cds--cc--chart-wrapper .fill-mono-3-9 {
  fill: var(--cds-charts-mono-3-9, #003a6d);
}
.cds--cc--chart-wrapper .fill-mono-3-9.hovered {
  fill: var(--cds-charts-mono-3-9-hovered, #003a6d);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-3-9 {
  background-color: var(--cds-charts-mono-3-9, #003a6d);
}
.cds--cc--chart-wrapper .background-mono-3-9.hovered {
  background-color: var(--cds-charts-mono-3-9-hovered, #003a6d);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-3-9 {
  stroke: var(--cds-charts-mono-3-9, #003a6d);
}
.cds--cc--chart-wrapper .stop-color-mono-3-9 {
  stop-color: var(--cds-charts-mono-3-9, #003a6d);
}
.cds--cc--chart-wrapper .fill-mono-3-10 {
  fill: var(--cds-charts-mono-3-10, #012749);
}
.cds--cc--chart-wrapper .fill-mono-3-10.hovered {
  fill: var(--cds-charts-mono-3-10-hovered, #012749);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-3-10 {
  background-color: var(--cds-charts-mono-3-10, #012749);
}
.cds--cc--chart-wrapper .background-mono-3-10.hovered {
  background-color: var(--cds-charts-mono-3-10-hovered, #012749);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-3-10 {
  stroke: var(--cds-charts-mono-3-10, #012749);
}
.cds--cc--chart-wrapper .stop-color-mono-3-10 {
  stop-color: var(--cds-charts-mono-3-10, #012749);
}
.cds--cc--chart-wrapper .fill-mono-3-11 {
  fill: var(--cds-charts-mono-3-11, #061727);
}
.cds--cc--chart-wrapper .fill-mono-3-11.hovered {
  fill: var(--cds-charts-mono-3-11-hovered, #061727);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-3-11 {
  background-color: var(--cds-charts-mono-3-11, #061727);
}
.cds--cc--chart-wrapper .background-mono-3-11.hovered {
  background-color: var(--cds-charts-mono-3-11-hovered, #061727);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-3-11 {
  stroke: var(--cds-charts-mono-3-11, #061727);
}
.cds--cc--chart-wrapper .stop-color-mono-3-11 {
  stop-color: var(--cds-charts-mono-3-11, #061727);
}
.cds--cc--chart-wrapper .fill-mono-4-1 {
  fill: var(--cds-charts-mono-4-1, #ffffff);
}
.cds--cc--chart-wrapper .fill-mono-4-1.hovered {
  fill: var(--cds-charts-mono-4-1-hovered, #ffffff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-4-1 {
  background-color: var(--cds-charts-mono-4-1, #ffffff);
}
.cds--cc--chart-wrapper .background-mono-4-1.hovered {
  background-color: var(--cds-charts-mono-4-1-hovered, #ffffff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-4-1 {
  stroke: var(--cds-charts-mono-4-1, #ffffff);
}
.cds--cc--chart-wrapper .stop-color-mono-4-1 {
  stop-color: var(--cds-charts-mono-4-1, #ffffff);
}
.cds--cc--chart-wrapper .fill-mono-4-2 {
  fill: var(--cds-charts-mono-4-2, #d9fbfb);
}
.cds--cc--chart-wrapper .fill-mono-4-2.hovered {
  fill: var(--cds-charts-mono-4-2-hovered, #d9fbfb);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-4-2 {
  background-color: var(--cds-charts-mono-4-2, #d9fbfb);
}
.cds--cc--chart-wrapper .background-mono-4-2.hovered {
  background-color: var(--cds-charts-mono-4-2-hovered, #d9fbfb);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-4-2 {
  stroke: var(--cds-charts-mono-4-2, #d9fbfb);
}
.cds--cc--chart-wrapper .stop-color-mono-4-2 {
  stop-color: var(--cds-charts-mono-4-2, #d9fbfb);
}
.cds--cc--chart-wrapper .fill-mono-4-3 {
  fill: var(--cds-charts-mono-4-3, #9ef0f0);
}
.cds--cc--chart-wrapper .fill-mono-4-3.hovered {
  fill: var(--cds-charts-mono-4-3-hovered, #9ef0f0);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-4-3 {
  background-color: var(--cds-charts-mono-4-3, #9ef0f0);
}
.cds--cc--chart-wrapper .background-mono-4-3.hovered {
  background-color: var(--cds-charts-mono-4-3-hovered, #9ef0f0);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-4-3 {
  stroke: var(--cds-charts-mono-4-3, #9ef0f0);
}
.cds--cc--chart-wrapper .stop-color-mono-4-3 {
  stop-color: var(--cds-charts-mono-4-3, #9ef0f0);
}
.cds--cc--chart-wrapper .fill-mono-4-4 {
  fill: var(--cds-charts-mono-4-4, #3ddbd9);
}
.cds--cc--chart-wrapper .fill-mono-4-4.hovered {
  fill: var(--cds-charts-mono-4-4-hovered, #3ddbd9);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-4-4 {
  background-color: var(--cds-charts-mono-4-4, #3ddbd9);
}
.cds--cc--chart-wrapper .background-mono-4-4.hovered {
  background-color: var(--cds-charts-mono-4-4-hovered, #3ddbd9);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-4-4 {
  stroke: var(--cds-charts-mono-4-4, #3ddbd9);
}
.cds--cc--chart-wrapper .stop-color-mono-4-4 {
  stop-color: var(--cds-charts-mono-4-4, #3ddbd9);
}
.cds--cc--chart-wrapper .fill-mono-4-5 {
  fill: var(--cds-charts-mono-4-5, #08bdba);
}
.cds--cc--chart-wrapper .fill-mono-4-5.hovered {
  fill: var(--cds-charts-mono-4-5-hovered, #08bdba);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-4-5 {
  background-color: var(--cds-charts-mono-4-5, #08bdba);
}
.cds--cc--chart-wrapper .background-mono-4-5.hovered {
  background-color: var(--cds-charts-mono-4-5-hovered, #08bdba);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-4-5 {
  stroke: var(--cds-charts-mono-4-5, #08bdba);
}
.cds--cc--chart-wrapper .stop-color-mono-4-5 {
  stop-color: var(--cds-charts-mono-4-5, #08bdba);
}
.cds--cc--chart-wrapper .fill-mono-4-6 {
  fill: var(--cds-charts-mono-4-6, #009d9a);
}
.cds--cc--chart-wrapper .fill-mono-4-6.hovered {
  fill: var(--cds-charts-mono-4-6-hovered, #009d9a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-4-6 {
  background-color: var(--cds-charts-mono-4-6, #009d9a);
}
.cds--cc--chart-wrapper .background-mono-4-6.hovered {
  background-color: var(--cds-charts-mono-4-6-hovered, #009d9a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-4-6 {
  stroke: var(--cds-charts-mono-4-6, #009d9a);
}
.cds--cc--chart-wrapper .stop-color-mono-4-6 {
  stop-color: var(--cds-charts-mono-4-6, #009d9a);
}
.cds--cc--chart-wrapper .fill-mono-4-7 {
  fill: var(--cds-charts-mono-4-7, #007d79);
}
.cds--cc--chart-wrapper .fill-mono-4-7.hovered {
  fill: var(--cds-charts-mono-4-7-hovered, #007d79);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-4-7 {
  background-color: var(--cds-charts-mono-4-7, #007d79);
}
.cds--cc--chart-wrapper .background-mono-4-7.hovered {
  background-color: var(--cds-charts-mono-4-7-hovered, #007d79);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-4-7 {
  stroke: var(--cds-charts-mono-4-7, #007d79);
}
.cds--cc--chart-wrapper .stop-color-mono-4-7 {
  stop-color: var(--cds-charts-mono-4-7, #007d79);
}
.cds--cc--chart-wrapper .fill-mono-4-8 {
  fill: var(--cds-charts-mono-4-8, #005d5d);
}
.cds--cc--chart-wrapper .fill-mono-4-8.hovered {
  fill: var(--cds-charts-mono-4-8-hovered, #005d5d);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-4-8 {
  background-color: var(--cds-charts-mono-4-8, #005d5d);
}
.cds--cc--chart-wrapper .background-mono-4-8.hovered {
  background-color: var(--cds-charts-mono-4-8-hovered, #005d5d);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-4-8 {
  stroke: var(--cds-charts-mono-4-8, #005d5d);
}
.cds--cc--chart-wrapper .stop-color-mono-4-8 {
  stop-color: var(--cds-charts-mono-4-8, #005d5d);
}
.cds--cc--chart-wrapper .fill-mono-4-9 {
  fill: var(--cds-charts-mono-4-9, #004144);
}
.cds--cc--chart-wrapper .fill-mono-4-9.hovered {
  fill: var(--cds-charts-mono-4-9-hovered, #004144);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-4-9 {
  background-color: var(--cds-charts-mono-4-9, #004144);
}
.cds--cc--chart-wrapper .background-mono-4-9.hovered {
  background-color: var(--cds-charts-mono-4-9-hovered, #004144);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-4-9 {
  stroke: var(--cds-charts-mono-4-9, #004144);
}
.cds--cc--chart-wrapper .stop-color-mono-4-9 {
  stop-color: var(--cds-charts-mono-4-9, #004144);
}
.cds--cc--chart-wrapper .fill-mono-4-10 {
  fill: var(--cds-charts-mono-4-10, #022b30);
}
.cds--cc--chart-wrapper .fill-mono-4-10.hovered {
  fill: var(--cds-charts-mono-4-10-hovered, #022b30);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-4-10 {
  background-color: var(--cds-charts-mono-4-10, #022b30);
}
.cds--cc--chart-wrapper .background-mono-4-10.hovered {
  background-color: var(--cds-charts-mono-4-10-hovered, #022b30);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-4-10 {
  stroke: var(--cds-charts-mono-4-10, #022b30);
}
.cds--cc--chart-wrapper .stop-color-mono-4-10 {
  stop-color: var(--cds-charts-mono-4-10, #022b30);
}
.cds--cc--chart-wrapper .fill-mono-4-11 {
  fill: var(--cds-charts-mono-4-11, #081a1c);
}
.cds--cc--chart-wrapper .fill-mono-4-11.hovered {
  fill: var(--cds-charts-mono-4-11-hovered, #081a1c);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-mono-4-11 {
  background-color: var(--cds-charts-mono-4-11, #081a1c);
}
.cds--cc--chart-wrapper .background-mono-4-11.hovered {
  background-color: var(--cds-charts-mono-4-11-hovered, #081a1c);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-mono-4-11 {
  stroke: var(--cds-charts-mono-4-11, #081a1c);
}
.cds--cc--chart-wrapper .stop-color-mono-4-11 {
  stop-color: var(--cds-charts-mono-4-11, #081a1c);
}
.cds--cc--chart-wrapper .fill-diverge-1-1 {
  fill: var(--cds-charts-diverge-1-1, #750e13);
}
.cds--cc--chart-wrapper .fill-diverge-1-1.hovered {
  fill: var(--cds-charts-diverge-1-1-hovered, #750e13);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-1-1 {
  background-color: var(--cds-charts-diverge-1-1, #750e13);
}
.cds--cc--chart-wrapper .background-diverge-1-1.hovered {
  background-color: var(--cds-charts-diverge-1-1-hovered, #750e13);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-1-1 {
  stroke: var(--cds-charts-diverge-1-1, #750e13);
}
.cds--cc--chart-wrapper .stop-color-diverge-1-1 {
  stop-color: var(--cds-charts-diverge-1-1, #750e13);
}
.cds--cc--chart-wrapper .fill-diverge-1-2 {
  fill: var(--cds-charts-diverge-1-2, #a2191f);
}
.cds--cc--chart-wrapper .fill-diverge-1-2.hovered {
  fill: var(--cds-charts-diverge-1-2-hovered, #a2191f);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-1-2 {
  background-color: var(--cds-charts-diverge-1-2, #a2191f);
}
.cds--cc--chart-wrapper .background-diverge-1-2.hovered {
  background-color: var(--cds-charts-diverge-1-2-hovered, #a2191f);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-1-2 {
  stroke: var(--cds-charts-diverge-1-2, #a2191f);
}
.cds--cc--chart-wrapper .stop-color-diverge-1-2 {
  stop-color: var(--cds-charts-diverge-1-2, #a2191f);
}
.cds--cc--chart-wrapper .fill-diverge-1-3 {
  fill: var(--cds-charts-diverge-1-3, #da1e28);
}
.cds--cc--chart-wrapper .fill-diverge-1-3.hovered {
  fill: var(--cds-charts-diverge-1-3-hovered, #da1e28);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-1-3 {
  background-color: var(--cds-charts-diverge-1-3, #da1e28);
}
.cds--cc--chart-wrapper .background-diverge-1-3.hovered {
  background-color: var(--cds-charts-diverge-1-3-hovered, #da1e28);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-1-3 {
  stroke: var(--cds-charts-diverge-1-3, #da1e28);
}
.cds--cc--chart-wrapper .stop-color-diverge-1-3 {
  stop-color: var(--cds-charts-diverge-1-3, #da1e28);
}
.cds--cc--chart-wrapper .fill-diverge-1-4 {
  fill: var(--cds-charts-diverge-1-4, #fa4d56);
}
.cds--cc--chart-wrapper .fill-diverge-1-4.hovered {
  fill: var(--cds-charts-diverge-1-4-hovered, #fa4d56);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-1-4 {
  background-color: var(--cds-charts-diverge-1-4, #fa4d56);
}
.cds--cc--chart-wrapper .background-diverge-1-4.hovered {
  background-color: var(--cds-charts-diverge-1-4-hovered, #fa4d56);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-1-4 {
  stroke: var(--cds-charts-diverge-1-4, #fa4d56);
}
.cds--cc--chart-wrapper .stop-color-diverge-1-4 {
  stop-color: var(--cds-charts-diverge-1-4, #fa4d56);
}
.cds--cc--chart-wrapper .fill-diverge-1-5 {
  fill: var(--cds-charts-diverge-1-5, #ff8389);
}
.cds--cc--chart-wrapper .fill-diverge-1-5.hovered {
  fill: var(--cds-charts-diverge-1-5-hovered, #ff8389);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-1-5 {
  background-color: var(--cds-charts-diverge-1-5, #ff8389);
}
.cds--cc--chart-wrapper .background-diverge-1-5.hovered {
  background-color: var(--cds-charts-diverge-1-5-hovered, #ff8389);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-1-5 {
  stroke: var(--cds-charts-diverge-1-5, #ff8389);
}
.cds--cc--chart-wrapper .stop-color-diverge-1-5 {
  stop-color: var(--cds-charts-diverge-1-5, #ff8389);
}
.cds--cc--chart-wrapper .fill-diverge-1-6 {
  fill: var(--cds-charts-diverge-1-6, #ffb3b8);
}
.cds--cc--chart-wrapper .fill-diverge-1-6.hovered {
  fill: var(--cds-charts-diverge-1-6-hovered, #ffb3b8);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-1-6 {
  background-color: var(--cds-charts-diverge-1-6, #ffb3b8);
}
.cds--cc--chart-wrapper .background-diverge-1-6.hovered {
  background-color: var(--cds-charts-diverge-1-6-hovered, #ffb3b8);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-1-6 {
  stroke: var(--cds-charts-diverge-1-6, #ffb3b8);
}
.cds--cc--chart-wrapper .stop-color-diverge-1-6 {
  stop-color: var(--cds-charts-diverge-1-6, #ffb3b8);
}
.cds--cc--chart-wrapper .fill-diverge-1-7 {
  fill: var(--cds-charts-diverge-1-7, #ffd7d9);
}
.cds--cc--chart-wrapper .fill-diverge-1-7.hovered {
  fill: var(--cds-charts-diverge-1-7-hovered, #ffd7d9);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-1-7 {
  background-color: var(--cds-charts-diverge-1-7, #ffd7d9);
}
.cds--cc--chart-wrapper .background-diverge-1-7.hovered {
  background-color: var(--cds-charts-diverge-1-7-hovered, #ffd7d9);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-1-7 {
  stroke: var(--cds-charts-diverge-1-7, #ffd7d9);
}
.cds--cc--chart-wrapper .stop-color-diverge-1-7 {
  stop-color: var(--cds-charts-diverge-1-7, #ffd7d9);
}
.cds--cc--chart-wrapper .fill-diverge-1-8 {
  fill: var(--cds-charts-diverge-1-8, #fff1f1);
}
.cds--cc--chart-wrapper .fill-diverge-1-8.hovered {
  fill: var(--cds-charts-diverge-1-8-hovered, #fff1f1);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-1-8 {
  background-color: var(--cds-charts-diverge-1-8, #fff1f1);
}
.cds--cc--chart-wrapper .background-diverge-1-8.hovered {
  background-color: var(--cds-charts-diverge-1-8-hovered, #fff1f1);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-1-8 {
  stroke: var(--cds-charts-diverge-1-8, #fff1f1);
}
.cds--cc--chart-wrapper .stop-color-diverge-1-8 {
  stop-color: var(--cds-charts-diverge-1-8, #fff1f1);
}
.cds--cc--chart-wrapper .fill-diverge-1-9 {
  fill: var(--cds-charts-diverge-1-9, #ffffff);
}
.cds--cc--chart-wrapper .fill-diverge-1-9.hovered {
  fill: var(--cds-charts-diverge-1-9-hovered, #ffffff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-1-9 {
  background-color: var(--cds-charts-diverge-1-9, #ffffff);
}
.cds--cc--chart-wrapper .background-diverge-1-9.hovered {
  background-color: var(--cds-charts-diverge-1-9-hovered, #ffffff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-1-9 {
  stroke: var(--cds-charts-diverge-1-9, #ffffff);
}
.cds--cc--chart-wrapper .stop-color-diverge-1-9 {
  stop-color: var(--cds-charts-diverge-1-9, #ffffff);
}
.cds--cc--chart-wrapper .fill-diverge-1-10 {
  fill: var(--cds-charts-diverge-1-10, #e5f6ff);
}
.cds--cc--chart-wrapper .fill-diverge-1-10.hovered {
  fill: var(--cds-charts-diverge-1-10-hovered, #e5f6ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-1-10 {
  background-color: var(--cds-charts-diverge-1-10, #e5f6ff);
}
.cds--cc--chart-wrapper .background-diverge-1-10.hovered {
  background-color: var(--cds-charts-diverge-1-10-hovered, #e5f6ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-1-10 {
  stroke: var(--cds-charts-diverge-1-10, #e5f6ff);
}
.cds--cc--chart-wrapper .stop-color-diverge-1-10 {
  stop-color: var(--cds-charts-diverge-1-10, #e5f6ff);
}
.cds--cc--chart-wrapper .fill-diverge-1-11 {
  fill: var(--cds-charts-diverge-1-11, #bae6ff);
}
.cds--cc--chart-wrapper .fill-diverge-1-11.hovered {
  fill: var(--cds-charts-diverge-1-11-hovered, #bae6ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-1-11 {
  background-color: var(--cds-charts-diverge-1-11, #bae6ff);
}
.cds--cc--chart-wrapper .background-diverge-1-11.hovered {
  background-color: var(--cds-charts-diverge-1-11-hovered, #bae6ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-1-11 {
  stroke: var(--cds-charts-diverge-1-11, #bae6ff);
}
.cds--cc--chart-wrapper .stop-color-diverge-1-11 {
  stop-color: var(--cds-charts-diverge-1-11, #bae6ff);
}
.cds--cc--chart-wrapper .fill-diverge-1-12 {
  fill: var(--cds-charts-diverge-1-12, #82cfff);
}
.cds--cc--chart-wrapper .fill-diverge-1-12.hovered {
  fill: var(--cds-charts-diverge-1-12-hovered, #82cfff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-1-12 {
  background-color: var(--cds-charts-diverge-1-12, #82cfff);
}
.cds--cc--chart-wrapper .background-diverge-1-12.hovered {
  background-color: var(--cds-charts-diverge-1-12-hovered, #82cfff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-1-12 {
  stroke: var(--cds-charts-diverge-1-12, #82cfff);
}
.cds--cc--chart-wrapper .stop-color-diverge-1-12 {
  stop-color: var(--cds-charts-diverge-1-12, #82cfff);
}
.cds--cc--chart-wrapper .fill-diverge-1-13 {
  fill: var(--cds-charts-diverge-1-13, #33b1ff);
}
.cds--cc--chart-wrapper .fill-diverge-1-13.hovered {
  fill: var(--cds-charts-diverge-1-13-hovered, #33b1ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-1-13 {
  background-color: var(--cds-charts-diverge-1-13, #33b1ff);
}
.cds--cc--chart-wrapper .background-diverge-1-13.hovered {
  background-color: var(--cds-charts-diverge-1-13-hovered, #33b1ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-1-13 {
  stroke: var(--cds-charts-diverge-1-13, #33b1ff);
}
.cds--cc--chart-wrapper .stop-color-diverge-1-13 {
  stop-color: var(--cds-charts-diverge-1-13, #33b1ff);
}
.cds--cc--chart-wrapper .fill-diverge-1-14 {
  fill: var(--cds-charts-diverge-1-14, #1192e8);
}
.cds--cc--chart-wrapper .fill-diverge-1-14.hovered {
  fill: var(--cds-charts-diverge-1-14-hovered, #1192e8);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-1-14 {
  background-color: var(--cds-charts-diverge-1-14, #1192e8);
}
.cds--cc--chart-wrapper .background-diverge-1-14.hovered {
  background-color: var(--cds-charts-diverge-1-14-hovered, #1192e8);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-1-14 {
  stroke: var(--cds-charts-diverge-1-14, #1192e8);
}
.cds--cc--chart-wrapper .stop-color-diverge-1-14 {
  stop-color: var(--cds-charts-diverge-1-14, #1192e8);
}
.cds--cc--chart-wrapper .fill-diverge-1-15 {
  fill: var(--cds-charts-diverge-1-15, #0072c3);
}
.cds--cc--chart-wrapper .fill-diverge-1-15.hovered {
  fill: var(--cds-charts-diverge-1-15-hovered, #0072c3);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-1-15 {
  background-color: var(--cds-charts-diverge-1-15, #0072c3);
}
.cds--cc--chart-wrapper .background-diverge-1-15.hovered {
  background-color: var(--cds-charts-diverge-1-15-hovered, #0072c3);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-1-15 {
  stroke: var(--cds-charts-diverge-1-15, #0072c3);
}
.cds--cc--chart-wrapper .stop-color-diverge-1-15 {
  stop-color: var(--cds-charts-diverge-1-15, #0072c3);
}
.cds--cc--chart-wrapper .fill-diverge-1-16 {
  fill: var(--cds-charts-diverge-1-16, #00539a);
}
.cds--cc--chart-wrapper .fill-diverge-1-16.hovered {
  fill: var(--cds-charts-diverge-1-16-hovered, #00539a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-1-16 {
  background-color: var(--cds-charts-diverge-1-16, #00539a);
}
.cds--cc--chart-wrapper .background-diverge-1-16.hovered {
  background-color: var(--cds-charts-diverge-1-16-hovered, #00539a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-1-16 {
  stroke: var(--cds-charts-diverge-1-16, #00539a);
}
.cds--cc--chart-wrapper .stop-color-diverge-1-16 {
  stop-color: var(--cds-charts-diverge-1-16, #00539a);
}
.cds--cc--chart-wrapper .fill-diverge-1-17 {
  fill: var(--cds-charts-diverge-1-17, #003a6d);
}
.cds--cc--chart-wrapper .fill-diverge-1-17.hovered {
  fill: var(--cds-charts-diverge-1-17-hovered, #003a6d);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-1-17 {
  background-color: var(--cds-charts-diverge-1-17, #003a6d);
}
.cds--cc--chart-wrapper .background-diverge-1-17.hovered {
  background-color: var(--cds-charts-diverge-1-17-hovered, #003a6d);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-1-17 {
  stroke: var(--cds-charts-diverge-1-17, #003a6d);
}
.cds--cc--chart-wrapper .stop-color-diverge-1-17 {
  stop-color: var(--cds-charts-diverge-1-17, #003a6d);
}
.cds--cc--chart-wrapper .fill-diverge-2-1 {
  fill: var(--cds-charts-diverge-2-1, #491d8b);
}
.cds--cc--chart-wrapper .fill-diverge-2-1.hovered {
  fill: var(--cds-charts-diverge-2-1-hovered, #491d8b);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-2-1 {
  background-color: var(--cds-charts-diverge-2-1, #491d8b);
}
.cds--cc--chart-wrapper .background-diverge-2-1.hovered {
  background-color: var(--cds-charts-diverge-2-1-hovered, #491d8b);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-2-1 {
  stroke: var(--cds-charts-diverge-2-1, #491d8b);
}
.cds--cc--chart-wrapper .stop-color-diverge-2-1 {
  stop-color: var(--cds-charts-diverge-2-1, #491d8b);
}
.cds--cc--chart-wrapper .fill-diverge-2-2 {
  fill: var(--cds-charts-diverge-2-2, #6929c4);
}
.cds--cc--chart-wrapper .fill-diverge-2-2.hovered {
  fill: var(--cds-charts-diverge-2-2-hovered, #6929c4);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-2-2 {
  background-color: var(--cds-charts-diverge-2-2, #6929c4);
}
.cds--cc--chart-wrapper .background-diverge-2-2.hovered {
  background-color: var(--cds-charts-diverge-2-2-hovered, #6929c4);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-2-2 {
  stroke: var(--cds-charts-diverge-2-2, #6929c4);
}
.cds--cc--chart-wrapper .stop-color-diverge-2-2 {
  stop-color: var(--cds-charts-diverge-2-2, #6929c4);
}
.cds--cc--chart-wrapper .fill-diverge-2-3 {
  fill: var(--cds-charts-diverge-2-3, #8a3ffc);
}
.cds--cc--chart-wrapper .fill-diverge-2-3.hovered {
  fill: var(--cds-charts-diverge-2-3-hovered, #8a3ffc);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-2-3 {
  background-color: var(--cds-charts-diverge-2-3, #8a3ffc);
}
.cds--cc--chart-wrapper .background-diverge-2-3.hovered {
  background-color: var(--cds-charts-diverge-2-3-hovered, #8a3ffc);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-2-3 {
  stroke: var(--cds-charts-diverge-2-3, #8a3ffc);
}
.cds--cc--chart-wrapper .stop-color-diverge-2-3 {
  stop-color: var(--cds-charts-diverge-2-3, #8a3ffc);
}
.cds--cc--chart-wrapper .fill-diverge-2-4 {
  fill: var(--cds-charts-diverge-2-4, #a56eff);
}
.cds--cc--chart-wrapper .fill-diverge-2-4.hovered {
  fill: var(--cds-charts-diverge-2-4-hovered, #a56eff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-2-4 {
  background-color: var(--cds-charts-diverge-2-4, #a56eff);
}
.cds--cc--chart-wrapper .background-diverge-2-4.hovered {
  background-color: var(--cds-charts-diverge-2-4-hovered, #a56eff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-2-4 {
  stroke: var(--cds-charts-diverge-2-4, #a56eff);
}
.cds--cc--chart-wrapper .stop-color-diverge-2-4 {
  stop-color: var(--cds-charts-diverge-2-4, #a56eff);
}
.cds--cc--chart-wrapper .fill-diverge-2-5 {
  fill: var(--cds-charts-diverge-2-5, #be95ff);
}
.cds--cc--chart-wrapper .fill-diverge-2-5.hovered {
  fill: var(--cds-charts-diverge-2-5-hovered, #be95ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-2-5 {
  background-color: var(--cds-charts-diverge-2-5, #be95ff);
}
.cds--cc--chart-wrapper .background-diverge-2-5.hovered {
  background-color: var(--cds-charts-diverge-2-5-hovered, #be95ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-2-5 {
  stroke: var(--cds-charts-diverge-2-5, #be95ff);
}
.cds--cc--chart-wrapper .stop-color-diverge-2-5 {
  stop-color: var(--cds-charts-diverge-2-5, #be95ff);
}
.cds--cc--chart-wrapper .fill-diverge-2-6 {
  fill: var(--cds-charts-diverge-2-6, #d4bbff);
}
.cds--cc--chart-wrapper .fill-diverge-2-6.hovered {
  fill: var(--cds-charts-diverge-2-6-hovered, #d4bbff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-2-6 {
  background-color: var(--cds-charts-diverge-2-6, #d4bbff);
}
.cds--cc--chart-wrapper .background-diverge-2-6.hovered {
  background-color: var(--cds-charts-diverge-2-6-hovered, #d4bbff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-2-6 {
  stroke: var(--cds-charts-diverge-2-6, #d4bbff);
}
.cds--cc--chart-wrapper .stop-color-diverge-2-6 {
  stop-color: var(--cds-charts-diverge-2-6, #d4bbff);
}
.cds--cc--chart-wrapper .fill-diverge-2-7 {
  fill: var(--cds-charts-diverge-2-7, #e8daff);
}
.cds--cc--chart-wrapper .fill-diverge-2-7.hovered {
  fill: var(--cds-charts-diverge-2-7-hovered, #e8daff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-2-7 {
  background-color: var(--cds-charts-diverge-2-7, #e8daff);
}
.cds--cc--chart-wrapper .background-diverge-2-7.hovered {
  background-color: var(--cds-charts-diverge-2-7-hovered, #e8daff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-2-7 {
  stroke: var(--cds-charts-diverge-2-7, #e8daff);
}
.cds--cc--chart-wrapper .stop-color-diverge-2-7 {
  stop-color: var(--cds-charts-diverge-2-7, #e8daff);
}
.cds--cc--chart-wrapper .fill-diverge-2-8 {
  fill: var(--cds-charts-diverge-2-8, #f6f2ff);
}
.cds--cc--chart-wrapper .fill-diverge-2-8.hovered {
  fill: var(--cds-charts-diverge-2-8-hovered, #f6f2ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-2-8 {
  background-color: var(--cds-charts-diverge-2-8, #f6f2ff);
}
.cds--cc--chart-wrapper .background-diverge-2-8.hovered {
  background-color: var(--cds-charts-diverge-2-8-hovered, #f6f2ff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-2-8 {
  stroke: var(--cds-charts-diverge-2-8, #f6f2ff);
}
.cds--cc--chart-wrapper .stop-color-diverge-2-8 {
  stop-color: var(--cds-charts-diverge-2-8, #f6f2ff);
}
.cds--cc--chart-wrapper .fill-diverge-2-9 {
  fill: var(--cds-charts-diverge-2-9, #ffffff);
}
.cds--cc--chart-wrapper .fill-diverge-2-9.hovered {
  fill: var(--cds-charts-diverge-2-9-hovered, #ffffff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-2-9 {
  background-color: var(--cds-charts-diverge-2-9, #ffffff);
}
.cds--cc--chart-wrapper .background-diverge-2-9.hovered {
  background-color: var(--cds-charts-diverge-2-9-hovered, #ffffff);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-2-9 {
  stroke: var(--cds-charts-diverge-2-9, #ffffff);
}
.cds--cc--chart-wrapper .stop-color-diverge-2-9 {
  stop-color: var(--cds-charts-diverge-2-9, #ffffff);
}
.cds--cc--chart-wrapper .fill-diverge-2-10 {
  fill: var(--cds-charts-diverge-2-10, #d9fbfb);
}
.cds--cc--chart-wrapper .fill-diverge-2-10.hovered {
  fill: var(--cds-charts-diverge-2-10-hovered, #d9fbfb);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-2-10 {
  background-color: var(--cds-charts-diverge-2-10, #d9fbfb);
}
.cds--cc--chart-wrapper .background-diverge-2-10.hovered {
  background-color: var(--cds-charts-diverge-2-10-hovered, #d9fbfb);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-2-10 {
  stroke: var(--cds-charts-diverge-2-10, #d9fbfb);
}
.cds--cc--chart-wrapper .stop-color-diverge-2-10 {
  stop-color: var(--cds-charts-diverge-2-10, #d9fbfb);
}
.cds--cc--chart-wrapper .fill-diverge-2-11 {
  fill: var(--cds-charts-diverge-2-11, #9ef0f0);
}
.cds--cc--chart-wrapper .fill-diverge-2-11.hovered {
  fill: var(--cds-charts-diverge-2-11-hovered, #9ef0f0);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-2-11 {
  background-color: var(--cds-charts-diverge-2-11, #9ef0f0);
}
.cds--cc--chart-wrapper .background-diverge-2-11.hovered {
  background-color: var(--cds-charts-diverge-2-11-hovered, #9ef0f0);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-2-11 {
  stroke: var(--cds-charts-diverge-2-11, #9ef0f0);
}
.cds--cc--chart-wrapper .stop-color-diverge-2-11 {
  stop-color: var(--cds-charts-diverge-2-11, #9ef0f0);
}
.cds--cc--chart-wrapper .fill-diverge-2-12 {
  fill: var(--cds-charts-diverge-2-12, #3ddbd9);
}
.cds--cc--chart-wrapper .fill-diverge-2-12.hovered {
  fill: var(--cds-charts-diverge-2-12-hovered, #3ddbd9);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-2-12 {
  background-color: var(--cds-charts-diverge-2-12, #3ddbd9);
}
.cds--cc--chart-wrapper .background-diverge-2-12.hovered {
  background-color: var(--cds-charts-diverge-2-12-hovered, #3ddbd9);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-2-12 {
  stroke: var(--cds-charts-diverge-2-12, #3ddbd9);
}
.cds--cc--chart-wrapper .stop-color-diverge-2-12 {
  stop-color: var(--cds-charts-diverge-2-12, #3ddbd9);
}
.cds--cc--chart-wrapper .fill-diverge-2-13 {
  fill: var(--cds-charts-diverge-2-13, #08bdba);
}
.cds--cc--chart-wrapper .fill-diverge-2-13.hovered {
  fill: var(--cds-charts-diverge-2-13-hovered, #08bdba);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-2-13 {
  background-color: var(--cds-charts-diverge-2-13, #08bdba);
}
.cds--cc--chart-wrapper .background-diverge-2-13.hovered {
  background-color: var(--cds-charts-diverge-2-13-hovered, #08bdba);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-2-13 {
  stroke: var(--cds-charts-diverge-2-13, #08bdba);
}
.cds--cc--chart-wrapper .stop-color-diverge-2-13 {
  stop-color: var(--cds-charts-diverge-2-13, #08bdba);
}
.cds--cc--chart-wrapper .fill-diverge-2-14 {
  fill: var(--cds-charts-diverge-2-14, #009d9a);
}
.cds--cc--chart-wrapper .fill-diverge-2-14.hovered {
  fill: var(--cds-charts-diverge-2-14-hovered, #009d9a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-2-14 {
  background-color: var(--cds-charts-diverge-2-14, #009d9a);
}
.cds--cc--chart-wrapper .background-diverge-2-14.hovered {
  background-color: var(--cds-charts-diverge-2-14-hovered, #009d9a);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-2-14 {
  stroke: var(--cds-charts-diverge-2-14, #009d9a);
}
.cds--cc--chart-wrapper .stop-color-diverge-2-14 {
  stop-color: var(--cds-charts-diverge-2-14, #009d9a);
}
.cds--cc--chart-wrapper .fill-diverge-2-15 {
  fill: var(--cds-charts-diverge-2-15, #007d79);
}
.cds--cc--chart-wrapper .fill-diverge-2-15.hovered {
  fill: var(--cds-charts-diverge-2-15-hovered, #007d79);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-2-15 {
  background-color: var(--cds-charts-diverge-2-15, #007d79);
}
.cds--cc--chart-wrapper .background-diverge-2-15.hovered {
  background-color: var(--cds-charts-diverge-2-15-hovered, #007d79);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-2-15 {
  stroke: var(--cds-charts-diverge-2-15, #007d79);
}
.cds--cc--chart-wrapper .stop-color-diverge-2-15 {
  stop-color: var(--cds-charts-diverge-2-15, #007d79);
}
.cds--cc--chart-wrapper .fill-diverge-2-16 {
  fill: var(--cds-charts-diverge-2-16, #005d5d);
}
.cds--cc--chart-wrapper .fill-diverge-2-16.hovered {
  fill: var(--cds-charts-diverge-2-16-hovered, #005d5d);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-2-16 {
  background-color: var(--cds-charts-diverge-2-16, #005d5d);
}
.cds--cc--chart-wrapper .background-diverge-2-16.hovered {
  background-color: var(--cds-charts-diverge-2-16-hovered, #005d5d);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-2-16 {
  stroke: var(--cds-charts-diverge-2-16, #005d5d);
}
.cds--cc--chart-wrapper .stop-color-diverge-2-16 {
  stop-color: var(--cds-charts-diverge-2-16, #005d5d);
}
.cds--cc--chart-wrapper .fill-diverge-2-17 {
  fill: var(--cds-charts-diverge-2-17, #004144);
}
.cds--cc--chart-wrapper .fill-diverge-2-17.hovered {
  fill: var(--cds-charts-diverge-2-17-hovered, #004144);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .background-diverge-2-17 {
  background-color: var(--cds-charts-diverge-2-17, #004144);
}
.cds--cc--chart-wrapper .background-diverge-2-17.hovered {
  background-color: var(--cds-charts-diverge-2-17-hovered, #004144);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .stroke-diverge-2-17 {
  stroke: var(--cds-charts-diverge-2-17, #004144);
}
.cds--cc--chart-wrapper .stop-color-diverge-2-17 {
  stop-color: var(--cds-charts-diverge-2-17, #004144);
}

.cds--cc--tooltip .tooltip-1-1-1 {
  background-color: var(--cds-charts-1-1-1, #6929c4);
}
.cds--cc--tooltip .tooltip-1-2-1 {
  background-color: var(--cds-charts-1-2-1, #002d9c);
}
.cds--cc--tooltip .tooltip-1-3-1 {
  background-color: var(--cds-charts-1-3-1, #1192e8);
}
.cds--cc--tooltip .tooltip-1-4-1 {
  background-color: var(--cds-charts-1-4-1, #007d79);
}
.cds--cc--tooltip .tooltip-2-1-1 {
  background-color: var(--cds-charts-2-1-1, #6929c4);
}
.cds--cc--tooltip .tooltip-2-1-2 {
  background-color: var(--cds-charts-2-1-2, #009d9a);
}
.cds--cc--tooltip .tooltip-2-2-1 {
  background-color: var(--cds-charts-2-2-1, #8a3ffc);
}
.cds--cc--tooltip .tooltip-2-2-2 {
  background-color: var(--cds-charts-2-2-2, #520408);
}
.cds--cc--tooltip .tooltip-2-3-1 {
  background-color: var(--cds-charts-2-3-1, #9f1853);
}
.cds--cc--tooltip .tooltip-2-3-2 {
  background-color: var(--cds-charts-2-3-2, #520408);
}
.cds--cc--tooltip .tooltip-2-4-1 {
  background-color: var(--cds-charts-2-4-1, #1192e8);
}
.cds--cc--tooltip .tooltip-2-4-2 {
  background-color: var(--cds-charts-2-4-2, #005d5d);
}
.cds--cc--tooltip .tooltip-2-5-1 {
  background-color: var(--cds-charts-2-5-1, #009d9a);
}
.cds--cc--tooltip .tooltip-2-5-2 {
  background-color: var(--cds-charts-2-5-2, #002d9c);
}
.cds--cc--tooltip .tooltip-3-1-1 {
  background-color: var(--cds-charts-3-1-1, #ee5396);
}
.cds--cc--tooltip .tooltip-3-1-2 {
  background-color: var(--cds-charts-3-1-2, #1192e8);
}
.cds--cc--tooltip .tooltip-3-1-3 {
  background-color: var(--cds-charts-3-1-3, #6929c4);
}
.cds--cc--tooltip .tooltip-3-2-1 {
  background-color: var(--cds-charts-3-2-1, #9f1853);
}
.cds--cc--tooltip .tooltip-3-2-2 {
  background-color: var(--cds-charts-3-2-2, #fa4d56);
}
.cds--cc--tooltip .tooltip-3-2-3 {
  background-color: var(--cds-charts-3-2-3, #520408);
}
.cds--cc--tooltip .tooltip-3-3-1 {
  background-color: var(--cds-charts-3-3-1, #a56eff);
}
.cds--cc--tooltip .tooltip-3-3-2 {
  background-color: var(--cds-charts-3-3-2, #005d5d);
}
.cds--cc--tooltip .tooltip-3-3-3 {
  background-color: var(--cds-charts-3-3-3, #002d9c);
}
.cds--cc--tooltip .tooltip-3-4-1 {
  background-color: var(--cds-charts-3-4-1, #a56eff);
}
.cds--cc--tooltip .tooltip-3-4-2 {
  background-color: var(--cds-charts-3-4-2, #005d5d);
}
.cds--cc--tooltip .tooltip-3-4-3 {
  background-color: var(--cds-charts-3-4-3, #9f1853);
}
.cds--cc--tooltip .tooltip-3-5-1 {
  background-color: var(--cds-charts-3-5-1, #012749);
}
.cds--cc--tooltip .tooltip-3-5-2 {
  background-color: var(--cds-charts-3-5-2, #6929c4);
}
.cds--cc--tooltip .tooltip-3-5-3 {
  background-color: var(--cds-charts-3-5-3, #009d9a);
}
.cds--cc--tooltip .tooltip-4-1-1 {
  background-color: var(--cds-charts-4-1-1, #6929c4);
}
.cds--cc--tooltip .tooltip-4-1-2 {
  background-color: var(--cds-charts-4-1-2, #012749);
}
.cds--cc--tooltip .tooltip-4-1-3 {
  background-color: var(--cds-charts-4-1-3, #009d9a);
}
.cds--cc--tooltip .tooltip-4-1-4 {
  background-color: var(--cds-charts-4-1-4, #ee5396);
}
.cds--cc--tooltip .tooltip-4-2-1 {
  background-color: var(--cds-charts-4-2-1, #9f1853);
}
.cds--cc--tooltip .tooltip-4-2-2 {
  background-color: var(--cds-charts-4-2-2, #fa4d56);
}
.cds--cc--tooltip .tooltip-4-2-3 {
  background-color: var(--cds-charts-4-2-3, #520408);
}
.cds--cc--tooltip .tooltip-4-2-4 {
  background-color: var(--cds-charts-4-2-4, #a56eff);
}
.cds--cc--tooltip .tooltip-4-3-1 {
  background-color: var(--cds-charts-4-3-1, #009d9a);
}
.cds--cc--tooltip .tooltip-4-3-2 {
  background-color: var(--cds-charts-4-3-2, #002d9c);
}
.cds--cc--tooltip .tooltip-4-3-3 {
  background-color: var(--cds-charts-4-3-3, #a56eff);
}
.cds--cc--tooltip .tooltip-4-3-4 {
  background-color: var(--cds-charts-4-3-4, #9f1853);
}
.cds--cc--tooltip .tooltip-5-1-1 {
  background-color: var(--cds-charts-5-1-1, #6929c4);
}
.cds--cc--tooltip .tooltip-5-1-2 {
  background-color: var(--cds-charts-5-1-2, #1192e8);
}
.cds--cc--tooltip .tooltip-5-1-3 {
  background-color: var(--cds-charts-5-1-3, #005d5d);
}
.cds--cc--tooltip .tooltip-5-1-4 {
  background-color: var(--cds-charts-5-1-4, #9f1853);
}
.cds--cc--tooltip .tooltip-5-1-5 {
  background-color: var(--cds-charts-5-1-5, #520408);
}
.cds--cc--tooltip .tooltip-5-2-1 {
  background-color: var(--cds-charts-5-2-1, #002d9c);
}
.cds--cc--tooltip .tooltip-5-2-2 {
  background-color: var(--cds-charts-5-2-2, #009d9a);
}
.cds--cc--tooltip .tooltip-5-2-3 {
  background-color: var(--cds-charts-5-2-3, #9f1853);
}
.cds--cc--tooltip .tooltip-5-2-4 {
  background-color: var(--cds-charts-5-2-4, #520408);
}
.cds--cc--tooltip .tooltip-5-2-5 {
  background-color: var(--cds-charts-5-2-5, #a56eff);
}
.cds--cc--tooltip .tooltip-14-1-1 {
  background-color: var(--cds-charts-14-1-1, #6929c4);
}
.cds--cc--tooltip .tooltip-14-1-2 {
  background-color: var(--cds-charts-14-1-2, #1192e8);
}
.cds--cc--tooltip .tooltip-14-1-3 {
  background-color: var(--cds-charts-14-1-3, #005d5d);
}
.cds--cc--tooltip .tooltip-14-1-4 {
  background-color: var(--cds-charts-14-1-4, #9f1853);
}
.cds--cc--tooltip .tooltip-14-1-5 {
  background-color: var(--cds-charts-14-1-5, #fa4d56);
}
.cds--cc--tooltip .tooltip-14-1-6 {
  background-color: var(--cds-charts-14-1-6, #520408);
}
.cds--cc--tooltip .tooltip-14-1-7 {
  background-color: var(--cds-charts-14-1-7, #198038);
}
.cds--cc--tooltip .tooltip-14-1-8 {
  background-color: var(--cds-charts-14-1-8, #002d9c);
}
.cds--cc--tooltip .tooltip-14-1-9 {
  background-color: var(--cds-charts-14-1-9, #ee5396);
}
.cds--cc--tooltip .tooltip-14-1-10 {
  background-color: var(--cds-charts-14-1-10, #b28600);
}
.cds--cc--tooltip .tooltip-14-1-11 {
  background-color: var(--cds-charts-14-1-11, #009d9a);
}
.cds--cc--tooltip .tooltip-14-1-12 {
  background-color: var(--cds-charts-14-1-12, #012749);
}
.cds--cc--tooltip .tooltip-14-1-13 {
  background-color: var(--cds-charts-14-1-13, #8a3800);
}
.cds--cc--tooltip .tooltip-14-1-14 {
  background-color: var(--cds-charts-14-1-14, #a56eff);
}

.cds--cc--legend .additional > .icon .area-1 {
  fill: var(--cds-zone-fill-01, #E8EDEE);
  stroke: var(--cds-zone-stroke-01, #8d8d8d);
}
.cds--cc--legend .additional > .icon .area-2 {
  fill: var(--cds-zone-fill-02, #e0e0e0);
  stroke: var(--cds-zone-stroke-02, #8d8d8d);
}
.cds--cc--legend .additional > .icon .area-3 {
  fill: var(--cds-zone-fill-03, #c6c6c6);
  stroke: var(--cds-zone-stroke-03, #8d8d8d);
}
.cds--cc--legend .additional > .icon .quartile-wrapper {
  fill: var(--cds-zone-fill-02, #e0e0e0);
  stroke: var(--cds-zone-stroke-01, #8d8d8d);
}
.cds--cc--legend .additional > .icon .quartile-line {
  fill: var(--cds-layer-inverse-absolute, #000000);
}

.cds--cc--axes {
  overflow: visible;
}
.cds--cc--axes g.axis g.ticks.invisible {
  visibility: hidden;
}
.cds--cc--axes g.axis g.tick-hover rect.axis-holder {
  fill: transparent;
  stroke: transparent;
  stroke-width: 2px;
}
.cds--cc--axes g.axis g.tick-hover:hover rect.axis-holder,
.cds--cc--axes g.axis g.tick-hover:focus rect.axis-holder {
  fill: var(--cds-layer-selected-inverse, #161616);
  stroke: var(--cds-layer-selected-inverse, #161616);
  stroke-width: 2px;
}
.cds--cc--axes g.axis g.tick-hover:hover text,
.cds--cc--axes g.axis g.tick-hover:focus text {
  fill: var(--cds-layer-selected);
}
.cds--cc--axes g.axis g.tick text {
  fill: var(--cds-text-secondary, #525252);
  font-family: 'IBM Plex Sans Condensed', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
}
.cds--cc--axes g.axis g.tick line {
  display: none;
}
.cds--cc--axes g.axis path.domain {
  stroke: var(--cds-border-strong-01, #8d8d8d);
}
.cds--cc--axes g.axis .axis-title {
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  font-weight: 600;
  fill: var(--cds-text-primary, #161616);
}

.cds--cc--chart-wrapper g.callouts {
  stroke: var(--cds-text-secondary, #525252);
}

.cds--cc--grid-brush g.grid-brush rect.selection {
  fill: none;
  fill-opacity: 0;
  stroke: none;
}

.cds--cc--grid-brush rect.frontSelection {
  fill: var(--cds-layer-accent-01, #e0e0e0);
  fill-opacity: 0.3;
  stroke: var(--cds-button-tertiary, #0f62fe);
}

.cds--cc--grid rect.chart-grid-backdrop {
  fill: var(--cds-grid-bg, #ffffff);
}
.cds--cc--grid rect.chart-grid-backdrop.stroked {
  stroke: var(--cds-layer-accent-01, #e0e0e0);
}
.cds--cc--grid rect.stroke {
  stroke: var(--cds-layer-accent-01, #e0e0e0);
}
.cds--cc--grid g.x.grid g.tick line,
.cds--cc--grid g.y.grid g.tick line {
  pointer-events: none;
  stroke-width: 1px;
  stroke: var(--cds-layer-accent-01, #e0e0e0);
}
.cds--cc--grid g.x.grid g.tick.active line,
.cds--cc--grid g.y.grid g.tick.active line {
  stroke-dasharray: 2px;
  stroke: var(--cds-focus, #0f62fe);
}

.cds--cc--ruler line.ruler-line,
.cds--cc--ruler-binned line.ruler-line {
  stroke: var(--cds-layer-inverse-absolute, #000000);
  stroke-width: 1px;
  stroke-dasharray: 2;
  pointer-events: none;
}

.cds--cc--skeleton rect.chart-skeleton-backdrop {
  fill: var(--cds-grid-bg, #ffffff);
}
.cds--cc--skeleton .shimmer-effect-lines {
  stroke-width: 1px;
}
.cds--cc--skeleton .shimmer-effect-sparkline {
  stroke-width: 0px;
}
.cds--cc--skeleton .empty-state-lines {
  stroke-width: 1px;
  stroke: var(--cds-layer-accent-01, #e0e0e0);
}
.cds--cc--skeleton .shimmer-lines .stop-bg-shimmer {
  stop-color: var(--cds-layer-accent-01, #e0e0e0);
}
.cds--cc--skeleton .shimmer-lines .stop-shimmer {
  stop-color: #ffffff;
}
.cds--cc--skeleton .empty-state-areas {
  fill: rgba(127, 127, 127, 0.1);
}
.cds--cc--skeleton .shimmer-areas .stop-bg-shimmer {
  stop-color: rgba(127, 127, 127, 0.1);
}
.cds--cc--skeleton .shimmer-areas .stop-shimmer {
  stop-color: rgba(255, 255, 255, 0.15);
}

.cds--cc--skeleton-lines rect.chart-skeleton-backdrop {
  fill: var(--cds-grid-bg, #ffffff);
}
.cds--cc--skeleton-lines .shimmer-effect-lines {
  stroke-width: 1px;
}
.cds--cc--skeleton-lines .shimmer-effect-sparkline {
  stroke-width: 0px;
}
.cds--cc--skeleton-lines .empty-state-lines {
  stroke-width: 1px;
  stroke: var(--cds-layer-accent-01, #e0e0e0);
}
.cds--cc--skeleton-lines .shimmer-lines .stop-bg-shimmer {
  stop-color: var(--cds-layer-accent-01, #e0e0e0);
}
.cds--cc--skeleton-lines .shimmer-lines .stop-shimmer {
  stop-color: #ffffff;
}

.cds--cc--zero-line line.domain {
  stroke: var(--cds-border-strong-01, #8d8d8d);
}

.cds--cc--layout-row {
  display: flex;
  flex-direction: row;
}
.cds--cc--layout-column {
  display: flex;
  flex-direction: column;
}
.cds--cc--layout-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.cds--cc--layout-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}
.cds--cc--layout-alignitems-center {
  align-items: center;
}

.cds--cc--chart-wrapper .layout-child {
  overflow: visible;
}

.cds--cc--chart-wrapper svg.layout-svg-wrapper {
  height: inherit;
  width: inherit;
  overflow: visible;
}

div.cds--cc--legend {
  display: flex;
  user-select: none;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
div.cds--cc--legend[data-name=legend-items] {
  width: 100%;
  margin: -5px;
}
div.cds--cc--legend div.legend-item {
  display: flex;
  align-items: center;
  margin: 5px;
}
div.cds--cc--legend div.legend-item div.checkbox {
  width: 13px;
  height: 13px;
  margin-right: 4px;
  border-radius: 2px;
  border: solid 1px var(--cds-background, #ffffff);
  box-shadow: 0 0 0 2px transparent;
}
@media (forced-colors: active) {
  div.cds--cc--legend div.legend-item div.checkbox {
    forced-color-adjust: none;
  }
}
div.cds--cc--legend div.legend-item div.checkbox:not(.active) {
  border-color: var(--cds-text-secondary, #525252);
  background: var(--cds-background, #ffffff);
}
div.cds--cc--legend div.legend-item div.checkbox svg {
  display: none;
  vertical-align: text-top;
  fill: var(--cds-background, #ffffff);
  stroke: var(--cds-background, #ffffff);
}
div.cds--cc--legend div.legend-item.additional svg.icon {
  margin-right: 4px;
}
div.cds--cc--legend div.legend-item p {
  font-size: 12px;
  fill: var(--cds-text-secondary, #525252);
  line-height: 1rem;
}
div.cds--cc--legend.center-aligned {
  justify-content: center;
}
div.cds--cc--legend.right-aligned {
  justify-content: flex-end;
}
div.cds--cc--legend.has-deactivated-items div.legend-item div.checkbox svg {
  display: block;
}
div.cds--cc--legend.vertical {
  margin: -5px;
  flex-direction: column;
}
div.cds--cc--legend.vertical div.legend-item {
  margin-right: 0;
  margin-bottom: 10px;
}
div.cds--cc--legend.clickable div.legend-item:not(.additional):hover {
  cursor: pointer;
}
div.cds--cc--legend.clickable div.legend-item:not(.additional):hover div.checkbox {
  border: solid 1px var(--cds-background, #ffffff);
  box-shadow: 0 0 0 2px #0f62fe;
}
div.cds--cc--legend.clickable div.legend-item:not(.additional):hover div.checkbox:not(.active) {
  border-color: var(--cds-text-secondary, #525252);
}

.cds--chart-holder .cds--modal.is-visible {
  z-index: 99999;
}
.cds--chart-holder .cds--modal .cds--modal-container .cds--modal-header__label {
  margin-top: 0;
  margin-bottom: 0;
}
.cds--chart-holder .cds--modal .cds--modal-container .cds--modal-header__heading {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.cds--chart-holder .cds--modal .cds--modal-container .cds--modal-content {
  padding: 0;
  margin-bottom: 0;
  color-scheme: var(--cds-color-scheme, light);
}
.cds--chart-holder .cds--modal .cds--modal-container .cds--modal-content table {
  position: relative;
  border-collapse: collapse;
}
.cds--chart-holder .cds--modal .cds--modal-container .cds--modal-content table th {
  position: sticky;
  top: 0;
}
.cds--chart-holder .cds--modal .cds--modal-container .cds--modal-footer {
  background-color: transparent;
}
.cds--chart-holder .cds--modal .cds--modal-container .cds--modal-footer .cds--cc-modal-footer-spacer {
  width: 50%;
}

.cds--cc--title p.title {
  color: var(--cds-text-primary, #161616);
  font-size: 16px;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
}

.layout-child.title {
  height: unset !important;
  overflow: hidden;
}

.cds--cc--meter-title {
  overflow: visible;
}
.cds--cc--meter-title text.meter-title,
.cds--cc--meter-title text.proportional-meter-title,
.cds--cc--meter-title text.proportional-meter-total,
.cds--cc--meter-title text.percent-value {
  fill: var(--cds-text-primary, #161616);
}
.cds--cc--meter-title g.status-indicator.status--danger circle.status {
  fill: var(--cds-support-error, #da1e28);
}
.cds--cc--meter-title g.status-indicator.status--warning circle.status {
  fill: var(--cds-support-warning, #f1c21b);
}
.cds--cc--meter-title g.status-indicator.status--warning path.innerFill {
  fill: #000000;
}
.cds--cc--meter-title g.status-indicator.status--success circle.status {
  fill: var(--cds-support-success, #198038);
}
.cds--cc--meter-title g.status-indicator path.innerFill {
  fill: var(--cds-layer-01-absolute, #ffffff);
}

.cds--chart-holder .cds--overflow-menu,
.cds--chart-holder .cds--overflow-menu__trigger {
  width: 2rem;
  height: 2rem;
}
.cds--chart-holder .cds--cc--toolbar {
  display: flex;
}
.cds--chart-holder .cds--cc--toolbar div.toolbar-control.disabled,
.cds--chart-holder .cds--cc--toolbar div.toolbar-control.disabled button {
  cursor: not-allowed;
}
.cds--chart-holder .cds--cc--toolbar div.toolbar-control.disabled:hover,
.cds--chart-holder .cds--cc--toolbar div.toolbar-control.disabled button:hover {
  background-color: transparent;
}
.cds--chart-holder .cds--cc--toolbar div.toolbar-control.disabled button:focus {
  outline: none;
}
.cds--chart-holder .cds--cc--toolbar div.toolbar-control.disabled svg {
  fill: var(--cds-icon-on-color-disabled, #8d8d8d);
}
.cds--chart-holder .cds--cc--toolbar .cds--overflow-menu--flip {
  right: 0;
  left: unset;
}
.cds--chart-holder .cds--cc--toolbar .cds--overflow-menu--flip.is-open {
  display: table;
}
.cds--chart-holder .cds--cc--toolbar .cds--overflow-menu--flip ul {
  margin: 0;
  padding: 0;
}
.cds--chart-holder .cds--cc--toolbar .cds--loading__background {
  fill: transparent;
}
.cds--chart-holder .cds--cc--toolbar .cds--loading__stroke {
  stroke-dashoffset: 99;
  fill: transparent;
}

.cds--cc--tooltip {
  background-color: var(--cds-layer-02, #ffffff);
  pointer-events: none;
  transition-timing-function: cubic-bezier(0.4, 0.14, 0.3, 1);
  display: inline;
  visibility: visible;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  word-wrap: break-word;
  z-index: 1059;
  font-family: 'IBM Plex Sans Condensed', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  transition: visibility 0s linear 0.1s, opacity 0.1s;
}
.cds--cc--tooltip.hidden {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0s, opacity 0.1s;
  transition-timing-function: cubic-bezier(0.4, 0.14, 0.3, 1);
}
.cds--cc--tooltip .content-box {
  color: var(--cds-text-primary, #161616);
}
.cds--cc--tooltip .content-box .title-tooltip {
  width: auto;
  padding: 4px;
  min-width: 20px;
  max-width: 270px;
}
.cds--cc--tooltip .content-box .title-tooltip p {
  margin: 2px;
  font-size: 12px;
  line-height: 1rem;
}
.cds--cc--tooltip .content-box .datapoint-tooltip {
  display: flex;
  padding: 4px;
  flex-flow: row nowrap;
  width: auto;
  min-width: 20px;
  justify-content: flex-start;
  align-items: center;
}
.cds--cc--tooltip .content-box .datapoint-tooltip div.label {
  display: flex;
  flex: 1;
}
.cds--cc--tooltip .content-box .datapoint-tooltip div.label p {
  flex: 1;
  padding-right: 8px;
}
.cds--cc--tooltip .content-box .datapoint-tooltip div.label span.label-icon svg {
  height: 12px;
  padding-top: 3px;
  vertical-align: top;
  padding-left: 4px;
  width: auto;
  fill: var(--cds-layer-inverse-absolute, #000000);
}
.cds--cc--tooltip .content-box .datapoint-tooltip.bold {
  font-weight: 600;
}
.cds--cc--tooltip .content-box .datapoint-tooltip p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
  font-size: 12px;
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0;
}
.cds--cc--tooltip .content-box .datapoint-tooltip p.value {
  width: auto;
  margin-left: 6px;
}
.cds--cc--tooltip .content-box ul.multi-tooltip {
  margin: 0;
  padding: 0;
}
.cds--cc--tooltip .content-box ul.multi-tooltip li {
  list-style: none;
  position: relative;
}
.cds--cc--tooltip .content-box ul.multi-tooltip li:not(:last-child) {
  border-bottom: 1px solid var(--cds-tooltip-line-border, #e0e0e0);
}
.cds--cc--tooltip .content-box svg.arrow-right rect {
  fill: none;
}
.cds--cc--tooltip .tooltip-color {
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
}
@media (forced-colors: active) {
  .cds--cc--tooltip .tooltip-color {
    forced-color-adjust: none;
  }
}
.cds--cc--tooltip .tooltip-color + div.label p {
  margin-left: 4px;
}

.cds--cc--threshold line.threshold-line {
  stroke: #fa4d56;
  stroke-width: 1;
  stroke-dasharray: 4;
  cursor: pointer;
  pointer-events: none;
}
.cds--cc--threshold line.threshold-line.active {
  stroke-width: 2;
}
.cds--cc--threshold rect.threshold-hoverable-area {
  height: 20px;
  transform: translate(0, -10px);
  cursor: pointer;
  fill: transparent;
}
.cds--cc--threshold rect.threshold-hoverable-area.rotate {
  transform: rotate(90deg) translate(0, -10px);
}

.cds--cc--threshold--label {
  background-color: #fa4d56;
  pointer-events: none;
  transition: opacity 0.1s;
  transition-timing-function: cubic-bezier(0.4, 0.14, 0.3, 1);
  display: inline;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  word-wrap: break-word;
  z-index: 1059;
  font-family: 'IBM Plex Sans Condensed', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  color: var(--cds-text-primary, #161616);
  line-height: 16px;
  font-size: 12px;
  padding: 4px;
  min-width: 20px;
}
.cds--cc--threshold--label.hidden {
  opacity: 0;
  transition: opacity 0.1s;
  transition-timing-function: cubic-bezier(0.4, 0.14, 0.3, 1);
}

.cds--cc--zoom-bar rect.zoom-bg {
  fill: var(--cds-background, #ffffff);
  stroke: var(--cds-layer-01, #E8EDEE);
}
.cds--cc--zoom-bar rect.zoom-slider-bg {
  fill: var(--cds-layer-01, #E8EDEE);
}
.cds--cc--zoom-bar rect.zoom-slider-selected-area {
  fill: var(--cds-icon-secondary, #525252);
}
.cds--cc--zoom-bar path.zoom-bg-baseline {
  stroke: var(--cds-border-strong-01, #8d8d8d);
  stroke-width: 2;
}
.cds--cc--zoom-bar path.zoom-graph-area {
  fill: var(--cds-layer-accent-01, #e0e0e0);
  stroke: var(--cds-border-strong-01, #8d8d8d);
  stroke-width: 1;
}
.cds--cc--zoom-bar path.zoom-graph-area-unselected {
  fill: var(--cds-layer-01, #E8EDEE);
  stroke: none;
}
.cds--cc--zoom-bar g.zoom-bar-brush rect.handle {
  fill: var(--cds-icon-secondary, #525252);
}
.cds--cc--zoom-bar g.zoom-bar-brush rect.handle-bar {
  fill: var(--cds-layer-02, #ffffff);
}
.cds--cc--zoom-bar g.zoom-bar-brush rect.selection {
  fill: none;
  stroke: none;
}
.cds--cc--zoom-bar rect[class^=highlight-] {
  fill: #ee5396;
  stroke: #ee5396;
}

.cds--cc--highlight rect.highlight-bar {
  pointer-events: none;
  fill: #ee5396;
  stroke: #ee5396;
}

.cds--cc--card-node {
  display: flex;
  position: relative;
  background-color: var(--cds-layer-01, #E8EDEE);
  z-index: 1;
  box-sizing: border-box;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  width: 100%;
  height: 100%;
  padding: 1rem 0.5rem;
  border-left: 0.25rem solid var(--cds-border-inverse, #161616);
}

.cds--cc--card-node--a,
.cds--cc--card-node--button {
  border-top: none;
  border-right: none;
  border-bottom: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.cds--cc--card-node--button {
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  text-align: left;
  width: 100%;
}

.cds--cc--card-node--a:focus,
.cds--cc--card-node--a:hover,
.cds--cc--card-node--button:focus,
.cds--cc--card-node--button:hover {
  background-color: var(--cds-network-diagrams-background-hover, #f1f1f1);
}

.cds--cc--card-node--a:focus,
.cds--cc--card-node--button:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px;
}
@media screen and (prefers-contrast) {
  .cds--cc--card-node--a:focus,
  .cds--cc--card-node--button:focus {
    outline-style: dotted;
  }
}

.cds--cc--card-node--stacked::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  border-top: 0.125rem solid var(--cds-layer-accent-01, #e0e0e0);
  border-right: 0.125rem solid var(--cds-layer-accent-01, #e0e0e0);
  pointer-events: none;
  left: 0.3125rem;
  bottom: 0.3125rem;
}
.cds--cc--card-node--stacked::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  border-top: 0.125rem solid var(--cds-layer-accent-01, #e0e0e0);
  border-right: 0.125rem solid var(--cds-layer-accent-01, #e0e0e0);
  pointer-events: none;
  left: 0.6875rem;
  bottom: 0.6875rem;
}

.cds--cc--card-node__column {
  padding: 0 0.5rem;
}

.cds--cc--card-node__column--farside {
  margin-left: auto;
}

.cds--cc--card-node__title {
  font-size: var(--cds-productive-heading-01-font-size, 0.875rem);
  font-weight: var(--cds-productive-heading-01-font-weight, 600);
  line-height: var(--cds-productive-heading-01-line-height, 1.28572);
  letter-spacing: var(--cds-productive-heading-01-letter-spacing, 0.16px);
  margin: 0;
}

.cds--cc--card-node__subtitle {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  margin: 0;
}

.cds--cc--card-node__label {
  display: block;
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  color: var(--cds-text-secondary, #525252);
  padding-top: 1.5rem;
}

.cds--cc--shape-node {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--cds-layer-01, #E8EDEE);
  box-sizing: border-box;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  width: 100%;
  height: 100%;
  position: relative;
}

.cds--cc--shape-node--circle {
  border-radius: 100%;
}

.cds--cc--shape-node--square {
  border-radius: 0;
}

.cds--cc--shape-node--rounded-square {
  border-radius: 0.5rem;
}

.cds--cc--shape-node--a,
.cds--cc--shape-node--button {
  border: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.cds--cc--shape-node--button {
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  text-align: left;
  width: 100%;
}

.cds--cc--shape-node--a:focus,
.cds--cc--shape-node--a:hover,
.cds--cc--shape-node--button:focus,
.cds--cc--shape-node--button:hover {
  background-color: var(--cds-network-diagrams-background-hover, #f1f1f1);
}
.cds--cc--shape-node--a:focus .cds--cc--shape-node__title,
.cds--cc--shape-node--a:focus .cds--cc--shape-node__subtitle,
.cds--cc--shape-node--a:hover .cds--cc--shape-node__title,
.cds--cc--shape-node--a:hover .cds--cc--shape-node__subtitle,
.cds--cc--shape-node--button:focus .cds--cc--shape-node__title,
.cds--cc--shape-node--button:focus .cds--cc--shape-node__subtitle,
.cds--cc--shape-node--button:hover .cds--cc--shape-node__title,
.cds--cc--shape-node--button:hover .cds--cc--shape-node__subtitle {
  font-weight: 600;
}

.cds--cc--shape-node--a:focus:focus,
.cds--cc--shape-node--button:focus:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--cds-focus, #0f62fe);
}

.cds--cc--shape-node__body {
  position: absolute;
  top: calc(100% + 0.125rem);
  text-align: center;
}

.cds--cc--shape-node__subtitle {
  padding-bottom: 0.125rem;
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  color: var(--cds-text-primary, #161616);
}

.cds--cc--shape-node__icon {
  display: flex;
}

.cds--cc--shape-node__title {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  color: var(--cds-text-primary, #161616);
  padding-top: 0.125rem;
  margin-bottom: 1px;
}

.cds--cc--edge {
  fill: transparent;
}

.cds--cc--edge__container {
  stroke-width: 1.5rem;
  stroke: transparent;
  stroke-dasharray: none;
}

.cds--cc--edge__inner {
  stroke-width: 0.0625rem;
  stroke: var(--cds-border-strong-01, #8d8d8d);
}

.cds--cc--edge__outer {
  stroke-width: 0.15625rem;
  stroke: transparent;
}

.cds--cc--edge:hover .cds--cc--edge__inner {
  stroke-width: 0.15625rem;
}

.cds--cc--edge--dash-sm {
  stroke-dasharray: 2 4;
}

.cds--cc--edge--dash-md {
  stroke-dasharray: 4 4;
}

.cds--cc--edge--dash-lg {
  stroke-dasharray: 8 4;
}

.cds--cc--edge--dash-xl {
  stroke-dasharray: 16 4;
}

.cds--cc--edge--tunnel .cds--cc--edge__outer {
  stroke: var(--cds-layer-accent-01, #e0e0e0);
  stroke-width: 0.375rem;
}

.cds--cc--edge--double .cds--cc--edge__inner {
  stroke: var(--cds-background, #ffffff);
}
.cds--cc--edge--double .cds--cc--edge__outer {
  stroke: var(--cds-border-inverse, #161616);
  stroke-width: 0.28125rem;
}

.cds--cc--marker {
  fill: var(--cds-border-inverse, #161616);
}

svg.cds--cc--color-legend {
  display: flex;
  user-select: none;
}
svg.cds--cc--color-legend g.legend-title text {
  fill: var(--cds-layer-inverse-absolute, #000000);
}

.cds--cc--area path.area,
.cds--cc--area-stacked path.area {
  pointer-events: none;
}

.cds--cc--bubble circle.dot.hovered {
  fill-opacity: 1;
  transition: all 0.1s;
  transition-timing-function: cubic-bezier(0.4, 0.14, 0.3, 1);
}
.cds--cc--bubble circle.dot.unfilled {
  fill: var(--cds-layer-01, #E8EDEE);
}
.cds--cc--bubble g.lines path.line {
  mix-blend-mode: multiply;
}

.cds--cc--bullet path.range-box {
  pointer-events: none;
}
.cds--cc--bullet path.range-box.order-1 {
  fill: var(--cds-zone-fill-01, #E8EDEE);
  stroke: var(--cds-zone-stroke-01, #8d8d8d);
}
.cds--cc--bullet path.range-box.order-2 {
  fill: var(--cds-zone-fill-02, #e0e0e0);
  stroke: var(--cds-zone-stroke-02, #8d8d8d);
}
.cds--cc--bullet path.range-box.order-3 {
  fill: var(--cds-zone-fill-03, #c6c6c6);
  stroke: var(--cds-zone-stroke-03, #8d8d8d);
}
.cds--cc--bullet path.marker,
.cds--cc--bullet path.quartile {
  pointer-events: none;
  stroke-width: 1.5px;
  stroke: var(--cds-layer-inverse-absolute, #000000);
}
.cds--cc--bullet path.quartile.over-bar {
  stroke: var(--cds-layer-01-absolute, #ffffff);
}

.cds--cc--donut {
  overflow: visible;
}

.cds--cc--line path.line {
  pointer-events: none;
  fill: none;
  stroke-width: 1.5;
}
.cds--cc--line path.line.sparkline-loading {
  animation: shimmer 2.5s infinite linear;
}
@keyframes shimmer {
  0% {
    stroke: var(--cds-layer-accent-01, #e0e0e0);
  }
  20% {
    stroke: #ffffff;
    opacity: 0.5;
  }
  100% {
    stroke: var(--cds-layer-accent-01, #e0e0e0);
  }
}

.cds--cc--scatter circle.dot.hovered {
  fill-opacity: 1;
  transition: all 0.1s;
  transition-timing-function: cubic-bezier(0.4, 0.14, 0.3, 1);
}
.cds--cc--scatter circle.dot.unfilled {
  fill: var(--cds-layer-01, #E8EDEE);
  stroke-width: 1.5;
}
.cds--cc--scatter circle.dot.threshold-anomaly {
  stroke-width: 3;
}
.cds--cc--scatter g.lines path.line {
  mix-blend-mode: multiply;
}

.cds--cc--meter rect.container {
  fill: var(--cds-layer-01, #E8EDEE);
}
.cds--cc--meter line.rangeIndicator {
  stroke: var(--cds-meter-range-indicator, #a8a8a8);
  stroke-width: 1px;
}
.cds--cc--meter rect.value.status--danger {
  fill: var(--cds-support-error, #da1e28);
}
.cds--cc--meter rect.value.status--warning {
  fill: var(--cds-support-warning, #f1c21b);
  stroke-width: 1px;
  stroke: var(--cds-alert-stroke, #b28600);
}
.cds--cc--meter rect.value.status--success {
  fill: var(--cds-support-success, #198038);
}
.cds--cc--meter line.peak {
  stroke: var(--cds-border-inverse, #161616);
  stroke-width: 2px;
}

.cds--cc--scatter-stacked circle.dot.unfilled {
  fill: var(--cds-layer-01, #E8EDEE);
  stroke-width: 1.5;
}
.cds--cc--scatter-stacked circle.dot.threshold-anomaly {
  stroke-width: 3;
}

.cds--cc--radar .blobs path {
  stroke-width: 1.5px;
}
.cds--cc--radar .y-axes path,
.cds--cc--radar .x-axes line {
  stroke-width: 1px;
  stroke: var(--cds-layer-accent-01, #e0e0e0);
}
.cds--cc--radar .x-axes line.hovered {
  stroke: var(--cds-layer-inverse-absolute, #000000);
}

.cds--cc--chart-wrapper .cds--cc--tree g.links {
  fill: none;
  stroke: var(--cds-border-strong-01, #8d8d8d);
  stroke-opacity: 0.4;
  stroke-width: 1.5;
}
.cds--cc--chart-wrapper .cds--cc--tree g.clickable {
  cursor: pointer;
}
.cds--cc--chart-wrapper .cds--cc--tree g.clickable:hover text {
  font-weight: 600;
}
.cds--cc--chart-wrapper .cds--cc--tree g.clickable:hover circle {
  fill: var(--cds-text-primary, #161616);
  transition: all 0.1s ease-out;
}
.cds--cc--chart-wrapper .cds--cc--tree circle.parent {
  fill: var(--cds-text-secondary, #525252);
}
.cds--cc--chart-wrapper .cds--cc--tree circle.child {
  fill: var(--cds-border-strong-01, #8d8d8d);
}
.cds--cc--chart-wrapper .cds--cc--tree text {
  fill: var(--cds-text-primary, #161616);
}
.cds--cc--chart-wrapper .cds--cc--tree text.text-stroke {
  stroke: var(--cds-text-inverse, #ffffff);
  stroke-width: 2px;
}

.cds--cc--treemap text {
  pointer-events: none;
}

.cds--cc--gauge {
  overflow: visible;
}
.cds--cc--gauge path.arc-background {
  fill: var(--cds-layer-01, #E8EDEE);
}
.cds--cc--gauge .gauge-delta-arrow.status--danger {
  fill: var(--cds-support-error, #da1e28);
}
.cds--cc--gauge .gauge-delta-arrow.status--warning {
  fill: var(--cds-support-warning, #f1c21b);
}
.cds--cc--gauge .gauge-delta-arrow.status--success {
  fill: var(--cds-support-success, #198038);
}

.cds--cc--pie {
  overflow: visible;
}

.cds--cc--lollipop line.line {
  pointer-events: none;
}
.cds--cc--lollipop circle.dot {
  stroke-width: 1.5;
}

.cds--cc--circle-pack circle.node {
  stroke-width: 1.5px;
}
.cds--cc--circle-pack circle.node.hovered {
  fill-opacity: 1;
}
.cds--cc--circle-pack circle.node.non-focal {
  fill: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
  fill-opacity: 30%;
  stroke: var(--cds-icon-disabled, rgba(22, 22, 22, 0.25));
}
.cds--cc--circle-pack circle.node.clickable {
  cursor: zoom-in;
}

.cds--cc--chart-wrapper.zoomed-in {
  cursor: zoom-out;
}
.cds--cc--chart-wrapper.zoomed-in .cds--cc--circle-pack circle.node.hovered-child {
  stroke: 1.5px solid initial;
}
.cds--cc--chart-wrapper.zoomed-in .cds--cc--circle-pack circle.node.clickable {
  cursor: zoom-out;
}

.cds--cc--wordcloud text.word.light {
  font-weight: 300;
}

.cds--cc--alluvial rect.node,
.cds--cc--alluvial rect.node-text-bg {
  fill: var(--cds-layer-inverse-absolute, #000000);
}
.cds--cc--alluvial text.node-text {
  fill: var(--cds-layer-01-absolute, #ffffff);
}
.cds--cc--alluvial polygon.arrow-down {
  fill: var(--cds-layer-01, #E8EDEE);
}

.cds--cc--heatmap g.highlighter-hidden {
  visibility: hidden;
}
.cds--cc--heatmap g.cell-highlight line {
  stroke: white;
  stroke-width: 1px;
}
.cds--cc--heatmap g.cell-2 line {
  stroke: white;
  stroke-width: 2px !important;
}
.cds--cc--heatmap g.multi-cell line {
  stroke: white;
  stroke-width: 2px;
}
.cds--cc--heatmap rect.pattern-fill {
  fill: var(--cds-border-strong-01, #8d8d8d);
}
.cds--cc--heatmap g.shadows line.top {
  filter: drop-shadow(0px -3px 2px black);
}
.cds--cc--heatmap g.shadows line.down {
  filter: drop-shadow(0px 3px 2px black);
}
.cds--cc--heatmap g.shadows line.left {
  filter: drop-shadow(-3px 0px 2px black);
}
.cds--cc--heatmap g.shadows line.right {
  filter: drop-shadow(3px 0px 2px black);
}
.cds--cc--heatmap rect.heat {
  stroke-width: 0px;
  stroke: var(--cds-background, #ffffff);
}
.cds--cc--heatmap rect.null-state {
  fill: var(--cds-icon-inverse, #ffffff);
}

.cds--cc--chart-wrapper {
  font-family: 'IBM Plex Sans Condensed', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
}
.cds--cc--chart-wrapper p {
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: var(--cds-text-secondary, #525252);
}
.cds--cc--chart-wrapper text {
  font-size: 12px;
  font-weight: 400;
  fill: var(--cds-text-secondary, #525252);
}
.cds--cc--chart-wrapper g.gauge-numbers text.gauge-value-number {
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  font-weight: 300;
}
.cds--cc--chart-wrapper text.meter-title,
.cds--cc--chart-wrapper text.percent-value {
  font-size: 16px;
  font-family: 'IBM Plex Sans', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
}
.cds--cc--chart-wrapper text.meter-title {
  font-weight: 600;
}

.cds--chart-holder {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.cds--chart-holder.filled, .cds--chart-holder.fullscreen {
  background-color: var(--cds-background, #ffffff);
}
.cds--chart-holder.filled .cds--cc--chart-wrapper, .cds--chart-holder.fullscreen .cds--cc--chart-wrapper {
  background-color: var(--cds-background, #ffffff);
}
.cds--chart-holder .DONT_STYLE_ME_css_styles_verifier {
  overflow: hidden;
  opacity: 0;
}

.cds--chart-holder {
  --cds-background: #ffffff;
  --cds-background-active: rgba(141, 141, 141, 0.5);
  --cds-background-brand: #0f62fe;
  --cds-background-hover: rgba(141, 141, 141, 0.12);
  --cds-background-inverse: #393939;
  --cds-background-inverse-hover: #474747;
  --cds-background-selected: rgba(141, 141, 141, 0.2);
  --cds-background-selected-hover: rgba(141, 141, 141, 0.32);
  --cds-border-disabled: #c6c6c6;
  --cds-border-interactive: #0f62fe;
  --cds-border-inverse: #161616;
  --cds-border-strong-01: #8d8d8d;
  --cds-border-strong-02: #8d8d8d;
  --cds-border-strong-03: #8d8d8d;
  --cds-border-subtle-00: #e0e0e0;
  --cds-border-subtle-01: #e0e0e0;
  --cds-border-subtle-02: #e0e0e0;
  --cds-border-subtle-03: #e0e0e0;
  --cds-border-subtle-selected-01: #c6c6c6;
  --cds-border-subtle-selected-02: #c6c6c6;
  --cds-border-subtle-selected-03: #c6c6c6;
  --cds-field-01: #E8EDEE;
  --cds-field-02: #ffffff;
  --cds-field-03: #E8EDEE;
  --cds-field-hover-01: #e8e8e8;
  --cds-field-hover-02: #e8e8e8;
  --cds-field-hover-03: #e8e8e8;
  --cds-focus: #0f62fe;
  --cds-focus-inset: #ffffff;
  --cds-focus-inverse: #ffffff;
  --cds-highlight: #d0e2ff;
  --cds-icon-disabled: rgba(22, 22, 22, 0.25);
  --cds-icon-inverse: #ffffff;
  --cds-icon-on-color: #ffffff;
  --cds-icon-on-color-disabled: #8d8d8d;
  --cds-icon-primary: #161616;
  --cds-icon-secondary: #525252;
  --cds-interactive: #0f62fe;
  --cds-layer-01: #E8EDEE;
  --cds-layer-02: #ffffff;
  --cds-layer-03: #E8EDEE;
  --cds-layer-accent-01: #e0e0e0;
  --cds-layer-accent-02: #e0e0e0;
  --cds-layer-accent-03: #e0e0e0;
  --cds-layer-accent-active-01: #a8a8a8;
  --cds-layer-accent-active-02: #a8a8a8;
  --cds-layer-accent-active-03: #a8a8a8;
  --cds-layer-accent-hover-01: #d1d1d1;
  --cds-layer-accent-hover-02: #d1d1d1;
  --cds-layer-accent-hover-03: #d1d1d1;
  --cds-layer-active-01: #c6c6c6;
  --cds-layer-active-02: #c6c6c6;
  --cds-layer-active-03: #c6c6c6;
  --cds-layer-hover-01: #e8e8e8;
  --cds-layer-hover-02: #e8e8e8;
  --cds-layer-hover-03: #e8e8e8;
  --cds-layer-selected-01: #e0e0e0;
  --cds-layer-selected-02: #e0e0e0;
  --cds-layer-selected-03: #e0e0e0;
  --cds-layer-selected-disabled: #8d8d8d;
  --cds-layer-selected-hover-01: #d1d1d1;
  --cds-layer-selected-hover-02: #d1d1d1;
  --cds-layer-selected-hover-03: #d1d1d1;
  --cds-layer-selected-inverse: #161616;
  --cds-link-inverse: #78a9ff;
  --cds-link-inverse-active: #E8EDEE;
  --cds-link-inverse-hover: #a6c8ff;
  --cds-link-primary: #0f62fe;
  --cds-link-primary-hover: #0043ce;
  --cds-link-secondary: #0043ce;
  --cds-link-visited: #8a3ffc;
  --cds-overlay: rgba(22, 22, 22, 0.5);
  --cds-shadow: rgba(0, 0, 0, 0.3);
  --cds-skeleton-background: #e8e8e8;
  --cds-skeleton-element: #c6c6c6;
  --cds-support-caution-major: #ff832b;
  --cds-support-caution-minor: #f1c21b;
  --cds-support-caution-undefined: #8a3ffc;
  --cds-support-error: #da1e28;
  --cds-support-error-inverse: #fa4d56;
  --cds-support-info: #0043ce;
  --cds-support-info-inverse: #4589ff;
  --cds-support-success: #198038;
  --cds-support-success-inverse: #42be65;
  --cds-support-warning: #f1c21b;
  --cds-support-warning-inverse: #f1c21b;
  --cds-text-disabled: rgba(22, 22, 22, 0.25);
  --cds-text-error: #da1e28;
  --cds-text-helper: #6f6f6f;
  --cds-text-inverse: #ffffff;
  --cds-text-on-color: #ffffff;
  --cds-text-on-color-disabled: #8d8d8d;
  --cds-text-placeholder: rgba(22, 22, 22, 0.4);
  --cds-text-primary: #161616;
  --cds-text-secondary: #525252;
  --cds-toggle-off: #8d8d8d;
  --cds-spacing-01: 0.125rem;
  --cds-spacing-02: 0.25rem;
  --cds-spacing-03: 0.5rem;
  --cds-spacing-04: 0.75rem;
  --cds-spacing-05: 1rem;
  --cds-spacing-06: 1.5rem;
  --cds-spacing-07: 2rem;
  --cds-spacing-08: 2.5rem;
  --cds-spacing-09: 3rem;
  --cds-spacing-10: 4rem;
  --cds-spacing-11: 5rem;
  --cds-spacing-12: 6rem;
  --cds-spacing-13: 10rem;
  --cds-fluid-spacing-01: 0;
  --cds-fluid-spacing-02: 2vw;
  --cds-fluid-spacing-03: 5vw;
  --cds-fluid-spacing-04: 10vw;
  --cds-label-01-font-size: 0.75rem;
  --cds-label-01-font-weight: 400;
  --cds-label-01-line-height: 1.33333;
  --cds-label-01-letter-spacing: 0.32px;
  --cds-helper-text-01-font-size: 0.75rem;
  --cds-helper-text-01-line-height: 1.33333;
  --cds-helper-text-01-letter-spacing: 0.32px;
  --cds-body-short-01-font-size: 0.875rem;
  --cds-body-short-01-font-weight: 400;
  --cds-body-short-01-line-height: 1.28572;
  --cds-body-short-01-letter-spacing: 0.16px;
  --cds-body-short-02-font-size: 1rem;
  --cds-body-short-02-font-weight: 400;
  --cds-body-short-02-line-height: 1.375;
  --cds-body-short-02-letter-spacing: 0;
  --cds-body-long-01-font-size: 0.875rem;
  --cds-body-long-01-font-weight: 400;
  --cds-body-long-01-line-height: 1.42857;
  --cds-body-long-01-letter-spacing: 0.16px;
  --cds-body-long-02-font-size: 1rem;
  --cds-body-long-02-font-weight: 400;
  --cds-body-long-02-line-height: 1.5;
  --cds-body-long-02-letter-spacing: 0;
  --cds-code-01-font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
  --cds-code-01-font-size: 0.75rem;
  --cds-code-01-font-weight: 400;
  --cds-code-01-line-height: 1.33333;
  --cds-code-01-letter-spacing: 0.32px;
  --cds-code-02-font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
  --cds-code-02-font-size: 0.875rem;
  --cds-code-02-font-weight: 400;
  --cds-code-02-line-height: 1.42857;
  --cds-code-02-letter-spacing: 0.32px;
  --cds-heading-01-font-size: 0.875rem;
  --cds-heading-01-font-weight: 600;
  --cds-heading-01-line-height: 1.42857;
  --cds-heading-01-letter-spacing: 0.16px;
  --cds-heading-02-font-size: 1rem;
  --cds-heading-02-font-weight: 600;
  --cds-heading-02-line-height: 1.5;
  --cds-heading-02-letter-spacing: 0;
  --cds-productive-heading-01-font-size: 0.875rem;
  --cds-productive-heading-01-font-weight: 600;
  --cds-productive-heading-01-line-height: 1.28572;
  --cds-productive-heading-01-letter-spacing: 0.16px;
  --cds-productive-heading-02-font-size: 1rem;
  --cds-productive-heading-02-font-weight: 600;
  --cds-productive-heading-02-line-height: 1.375;
  --cds-productive-heading-02-letter-spacing: 0;
  --cds-productive-heading-03-font-size: 1.25rem;
  --cds-productive-heading-03-font-weight: 400;
  --cds-productive-heading-03-line-height: 1.4;
  --cds-productive-heading-03-letter-spacing: 0;
  --cds-productive-heading-04-font-size: 1.75rem;
  --cds-productive-heading-04-font-weight: 400;
  --cds-productive-heading-04-line-height: 1.28572;
  --cds-productive-heading-04-letter-spacing: 0;
  --cds-productive-heading-05-font-size: 2rem;
  --cds-productive-heading-05-font-weight: 400;
  --cds-productive-heading-05-line-height: 1.25;
  --cds-productive-heading-05-letter-spacing: 0;
  --cds-productive-heading-06-font-size: 2rem;
  --cds-productive-heading-06-font-weight: 300;
  --cds-productive-heading-06-line-height: 1.199;
  --cds-productive-heading-06-letter-spacing: 0;
  --cds-productive-heading-07-font-size: 2.625rem;
  --cds-productive-heading-07-font-weight: 300;
  --cds-productive-heading-07-line-height: 1.19;
  --cds-productive-heading-07-letter-spacing: 0;
  --cds-expressive-paragraph-01-font-size: 1.5rem;
  --cds-expressive-paragraph-01-font-weight: 300;
  --cds-expressive-paragraph-01-line-height: 1.334;
  --cds-expressive-paragraph-01-letter-spacing: 0;
  --cds-expressive-heading-01-font-size: 0.875rem;
  --cds-expressive-heading-01-font-weight: 600;
  --cds-expressive-heading-01-line-height: 1.42857;
  --cds-expressive-heading-01-letter-spacing: 0.16px;
  --cds-expressive-heading-02-font-size: 1rem;
  --cds-expressive-heading-02-font-weight: 600;
  --cds-expressive-heading-02-line-height: 1.5;
  --cds-expressive-heading-02-letter-spacing: 0;
  --cds-expressive-heading-03-font-size: 1.25rem;
  --cds-expressive-heading-03-font-weight: 400;
  --cds-expressive-heading-03-line-height: 1.4;
  --cds-expressive-heading-03-letter-spacing: 0;
  --cds-expressive-heading-04-font-size: 1.75rem;
  --cds-expressive-heading-04-font-weight: 400;
  --cds-expressive-heading-04-line-height: 1.28572;
  --cds-expressive-heading-04-letter-spacing: 0;
  --cds-expressive-heading-05-font-size: 2rem;
  --cds-expressive-heading-05-font-weight: 400;
  --cds-expressive-heading-05-line-height: 1.25;
  --cds-expressive-heading-05-letter-spacing: 0;
  --cds-expressive-heading-06-font-size: 2rem;
  --cds-expressive-heading-06-font-weight: 600;
  --cds-expressive-heading-06-line-height: 1.25;
  --cds-expressive-heading-06-letter-spacing: 0;
  --cds-quotation-01-font-family: 'IBM Plex Serif', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', serif;
  --cds-quotation-01-font-size: 1.25rem;
  --cds-quotation-01-font-weight: 400;
  --cds-quotation-01-line-height: 1.3;
  --cds-quotation-01-letter-spacing: 0;
  --cds-quotation-02-font-family: 'IBM Plex Serif', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', serif;
  --cds-quotation-02-font-size: 2rem;
  --cds-quotation-02-font-weight: 300;
  --cds-quotation-02-line-height: 1.25;
  --cds-quotation-02-letter-spacing: 0;
  --cds-display-01-font-size: 2.625rem;
  --cds-display-01-font-weight: 300;
  --cds-display-01-line-height: 1.19;
  --cds-display-01-letter-spacing: 0;
  --cds-display-02-font-size: 2.625rem;
  --cds-display-02-font-weight: 600;
  --cds-display-02-line-height: 1.19;
  --cds-display-02-letter-spacing: 0;
  --cds-display-03-font-size: 2.625rem;
  --cds-display-03-font-weight: 300;
  --cds-display-03-line-height: 1.19;
  --cds-display-03-letter-spacing: 0;
  --cds-display-04-font-size: 2.625rem;
  --cds-display-04-font-weight: 600;
  --cds-display-04-line-height: 1.19;
  --cds-display-04-letter-spacing: 0;
  --cds-legal-01-font-size: 0.75rem;
  --cds-legal-01-font-weight: 400;
  --cds-legal-01-line-height: 1.33333;
  --cds-legal-01-letter-spacing: 0.32px;
  --cds-legal-02-font-size: 0.875rem;
  --cds-legal-02-font-weight: 400;
  --cds-legal-02-line-height: 1.28572;
  --cds-legal-02-letter-spacing: 0.16px;
  --cds-body-compact-01-font-size: 0.875rem;
  --cds-body-compact-01-font-weight: 400;
  --cds-body-compact-01-line-height: 1.28572;
  --cds-body-compact-01-letter-spacing: 0.16px;
  --cds-body-compact-02-font-size: 1rem;
  --cds-body-compact-02-font-weight: 400;
  --cds-body-compact-02-line-height: 1.375;
  --cds-body-compact-02-letter-spacing: 0;
  --cds-heading-compact-01-font-size: 0.875rem;
  --cds-heading-compact-01-font-weight: 600;
  --cds-heading-compact-01-line-height: 1.28572;
  --cds-heading-compact-01-letter-spacing: 0.16px;
  --cds-heading-compact-02-font-size: 1rem;
  --cds-heading-compact-02-font-weight: 600;
  --cds-heading-compact-02-line-height: 1.375;
  --cds-heading-compact-02-letter-spacing: 0;
  --cds-body-01-font-size: 0.875rem;
  --cds-body-01-font-weight: 400;
  --cds-body-01-line-height: 1.42857;
  --cds-body-01-letter-spacing: 0.16px;
  --cds-body-02-font-size: 1rem;
  --cds-body-02-font-weight: 400;
  --cds-body-02-line-height: 1.5;
  --cds-body-02-letter-spacing: 0;
  --cds-heading-03-font-size: 1.25rem;
  --cds-heading-03-font-weight: 400;
  --cds-heading-03-line-height: 1.4;
  --cds-heading-03-letter-spacing: 0;
  --cds-heading-04-font-size: 1.75rem;
  --cds-heading-04-font-weight: 400;
  --cds-heading-04-line-height: 1.28572;
  --cds-heading-04-letter-spacing: 0;
  --cds-heading-05-font-size: 2rem;
  --cds-heading-05-font-weight: 400;
  --cds-heading-05-line-height: 1.25;
  --cds-heading-05-letter-spacing: 0;
  --cds-heading-06-font-size: 2rem;
  --cds-heading-06-font-weight: 300;
  --cds-heading-06-line-height: 1.199;
  --cds-heading-06-letter-spacing: 0;
  --cds-heading-07-font-size: 2.625rem;
  --cds-heading-07-font-weight: 300;
  --cds-heading-07-line-height: 1.19;
  --cds-heading-07-letter-spacing: 0;
  --cds-fluid-heading-03-font-size: 1.25rem;
  --cds-fluid-heading-03-font-weight: 400;
  --cds-fluid-heading-03-line-height: 1.4;
  --cds-fluid-heading-03-letter-spacing: 0;
  --cds-fluid-heading-04-font-size: 1.75rem;
  --cds-fluid-heading-04-font-weight: 400;
  --cds-fluid-heading-04-line-height: 1.28572;
  --cds-fluid-heading-04-letter-spacing: 0;
  --cds-fluid-heading-05-font-size: 2rem;
  --cds-fluid-heading-05-font-weight: 400;
  --cds-fluid-heading-05-line-height: 1.25;
  --cds-fluid-heading-05-letter-spacing: 0;
  --cds-fluid-heading-06-font-size: 2rem;
  --cds-fluid-heading-06-font-weight: 600;
  --cds-fluid-heading-06-line-height: 1.25;
  --cds-fluid-heading-06-letter-spacing: 0;
  --cds-fluid-paragraph-01-font-size: 1.5rem;
  --cds-fluid-paragraph-01-font-weight: 300;
  --cds-fluid-paragraph-01-line-height: 1.334;
  --cds-fluid-paragraph-01-letter-spacing: 0;
  --cds-fluid-quotation-01-font-family: 'IBM Plex Serif', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', serif;
  --cds-fluid-quotation-01-font-size: 1.25rem;
  --cds-fluid-quotation-01-font-weight: 400;
  --cds-fluid-quotation-01-line-height: 1.3;
  --cds-fluid-quotation-01-letter-spacing: 0;
  --cds-fluid-quotation-02-font-family: 'IBM Plex Serif', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', serif;
  --cds-fluid-quotation-02-font-size: 2rem;
  --cds-fluid-quotation-02-font-weight: 300;
  --cds-fluid-quotation-02-line-height: 1.25;
  --cds-fluid-quotation-02-letter-spacing: 0;
  --cds-fluid-display-01-font-size: 2.625rem;
  --cds-fluid-display-01-font-weight: 300;
  --cds-fluid-display-01-line-height: 1.19;
  --cds-fluid-display-01-letter-spacing: 0;
  --cds-fluid-display-02-font-size: 2.625rem;
  --cds-fluid-display-02-font-weight: 600;
  --cds-fluid-display-02-line-height: 1.19;
  --cds-fluid-display-02-letter-spacing: 0;
  --cds-fluid-display-03-font-size: 2.625rem;
  --cds-fluid-display-03-font-weight: 300;
  --cds-fluid-display-03-line-height: 1.19;
  --cds-fluid-display-03-letter-spacing: 0;
  --cds-fluid-display-04-font-size: 2.625rem;
  --cds-fluid-display-04-font-weight: 600;
  --cds-fluid-display-04-line-height: 1.19;
  --cds-fluid-display-04-letter-spacing: 0;
  --cds-color-scheme: light;
  --cds-alert-stroke: #b28600;
  --cds-layer-01-absolute: #ffffff;
  --cds-layer-inverse-absolute: #000000;
  --cds-null-state: none;
  --cds-grid-bg: #ffffff;
  --cds-meter-range-indicator: #a8a8a8;
  --cds-network-diagrams-background-hover: #f1f1f1;
  --cds-tooltip-line-border: #e0e0e0;
  --cds-zone-fill-01: #E8EDEE;
  --cds-zone-stroke-01: #8d8d8d;
  --cds-zone-fill-02: #e0e0e0;
  --cds-zone-stroke-02: #8d8d8d;
  --cds-zone-fill-03: #c6c6c6;
  --cds-zone-stroke-03: #8d8d8d;
  --cds-layer: var(--cds-layer-01, #E8EDEE);
  --cds-layer-active: var(--cds-layer-active-01, #c6c6c6);
  --cds-layer-hover: var(--cds-layer-hover-01, #e8e8e8);
  --cds-layer-selected: var(--cds-layer-selected-01, #e0e0e0);
  --cds-layer-selected-hover: var(--cds-layer-selected-hover-01, #d1d1d1);
  --cds-layer-accent: var(--cds-layer-accent-01, #e0e0e0);
  --cds-layer-accent-hover: var(--cds-layer-accent-hover-01, #d1d1d1);
  --cds-layer-accent-active: var(--cds-layer-accent-active-01, #a8a8a8);
  --cds-field: var(--cds-field-01, #E8EDEE);
  --cds-field-hover: var(--cds-field-hover-01, #e8e8e8);
  --cds-border-subtle: var(--cds-border-subtle-01, #e0e0e0);
  --cds-border-subtle-selected: var(--cds-border-subtle-selected-01, #c6c6c6);
  --cds-border-strong: var(--cds-border-strong-01, #8d8d8d);
}

.cds--chart-holder[data-carbon-theme=g10] {
  --cds-background: #E8EDEE;
  --cds-background-active: rgba(141, 141, 141, 0.5);
  --cds-background-brand: #0f62fe;
  --cds-background-hover: rgba(141, 141, 141, 0.12);
  --cds-background-inverse: #393939;
  --cds-background-inverse-hover: #474747;
  --cds-background-selected: rgba(141, 141, 141, 0.2);
  --cds-background-selected-hover: rgba(141, 141, 141, 0.32);
  --cds-border-disabled: #c6c6c6;
  --cds-border-interactive: #0f62fe;
  --cds-border-inverse: #161616;
  --cds-border-strong-01: #8d8d8d;
  --cds-border-strong-02: #8d8d8d;
  --cds-border-strong-03: #8d8d8d;
  --cds-border-subtle-00: #e0e0e0;
  --cds-border-subtle-01: #e0e0e0;
  --cds-border-subtle-02: #e0e0e0;
  --cds-border-subtle-03: #e0e0e0;
  --cds-border-subtle-selected-01: #c6c6c6;
  --cds-border-subtle-selected-02: #c6c6c6;
  --cds-border-subtle-selected-03: #c6c6c6;
  --cds-field-01: #ffffff;
  --cds-field-02: #E8EDEE;
  --cds-field-03: #ffffff;
  --cds-field-hover-01: #e8e8e8;
  --cds-field-hover-02: #e8e8e8;
  --cds-field-hover-03: #e8e8e8;
  --cds-focus: #0f62fe;
  --cds-focus-inset: #ffffff;
  --cds-focus-inverse: #ffffff;
  --cds-highlight: #d0e2ff;
  --cds-icon-disabled: rgba(22, 22, 22, 0.25);
  --cds-icon-inverse: #ffffff;
  --cds-icon-on-color: #ffffff;
  --cds-icon-on-color-disabled: #8d8d8d;
  --cds-icon-primary: #161616;
  --cds-icon-secondary: #525252;
  --cds-interactive: #0f62fe;
  --cds-layer-01: #ffffff;
  --cds-layer-02: #E8EDEE;
  --cds-layer-03: #ffffff;
  --cds-layer-accent-01: #e0e0e0;
  --cds-layer-accent-02: #e0e0e0;
  --cds-layer-accent-03: #e0e0e0;
  --cds-layer-accent-active-01: #a8a8a8;
  --cds-layer-accent-active-02: #a8a8a8;
  --cds-layer-accent-active-03: #a8a8a8;
  --cds-layer-accent-hover-01: #d1d1d1;
  --cds-layer-accent-hover-02: #d1d1d1;
  --cds-layer-accent-hover-03: #d1d1d1;
  --cds-layer-active-01: #c6c6c6;
  --cds-layer-active-02: #c6c6c6;
  --cds-layer-active-03: #c6c6c6;
  --cds-layer-hover-01: #e8e8e8;
  --cds-layer-hover-02: #e8e8e8;
  --cds-layer-hover-03: #e8e8e8;
  --cds-layer-selected-01: #e0e0e0;
  --cds-layer-selected-02: #e0e0e0;
  --cds-layer-selected-03: #e0e0e0;
  --cds-layer-selected-disabled: #8d8d8d;
  --cds-layer-selected-hover-01: #d1d1d1;
  --cds-layer-selected-hover-02: #d1d1d1;
  --cds-layer-selected-hover-03: #d1d1d1;
  --cds-layer-selected-inverse: #161616;
  --cds-link-inverse: #78a9ff;
  --cds-link-inverse-active: #E8EDEE;
  --cds-link-inverse-hover: #a6c8ff;
  --cds-link-primary: #0f62fe;
  --cds-link-primary-hover: #0043ce;
  --cds-link-secondary: #0043ce;
  --cds-link-visited: #8a3ffc;
  --cds-overlay: rgba(22, 22, 22, 0.5);
  --cds-shadow: rgba(0, 0, 0, 0.3);
  --cds-skeleton-background: #e8e8e8;
  --cds-skeleton-element: #c6c6c6;
  --cds-support-caution-major: #ff832b;
  --cds-support-caution-minor: #f1c21b;
  --cds-support-caution-undefined: #8a3ffc;
  --cds-support-error: #da1e28;
  --cds-support-error-inverse: #fa4d56;
  --cds-support-info: #0043ce;
  --cds-support-info-inverse: #4589ff;
  --cds-support-success: #198038;
  --cds-support-success-inverse: #42be65;
  --cds-support-warning: #f1c21b;
  --cds-support-warning-inverse: #f1c21b;
  --cds-text-disabled: rgba(22, 22, 22, 0.25);
  --cds-text-error: #da1e28;
  --cds-text-helper: #6f6f6f;
  --cds-text-inverse: #ffffff;
  --cds-text-on-color: #ffffff;
  --cds-text-on-color-disabled: #8d8d8d;
  --cds-text-placeholder: rgba(22, 22, 22, 0.4);
  --cds-text-primary: #161616;
  --cds-text-secondary: #525252;
  --cds-toggle-off: #8d8d8d;
  --cds-spacing-01: 0.125rem;
  --cds-spacing-02: 0.25rem;
  --cds-spacing-03: 0.5rem;
  --cds-spacing-04: 0.75rem;
  --cds-spacing-05: 1rem;
  --cds-spacing-06: 1.5rem;
  --cds-spacing-07: 2rem;
  --cds-spacing-08: 2.5rem;
  --cds-spacing-09: 3rem;
  --cds-spacing-10: 4rem;
  --cds-spacing-11: 5rem;
  --cds-spacing-12: 6rem;
  --cds-spacing-13: 10rem;
  --cds-fluid-spacing-01: 0;
  --cds-fluid-spacing-02: 2vw;
  --cds-fluid-spacing-03: 5vw;
  --cds-fluid-spacing-04: 10vw;
  --cds-label-01-font-size: 0.75rem;
  --cds-label-01-font-weight: 400;
  --cds-label-01-line-height: 1.33333;
  --cds-label-01-letter-spacing: 0.32px;
  --cds-helper-text-01-font-size: 0.75rem;
  --cds-helper-text-01-line-height: 1.33333;
  --cds-helper-text-01-letter-spacing: 0.32px;
  --cds-body-short-01-font-size: 0.875rem;
  --cds-body-short-01-font-weight: 400;
  --cds-body-short-01-line-height: 1.28572;
  --cds-body-short-01-letter-spacing: 0.16px;
  --cds-body-short-02-font-size: 1rem;
  --cds-body-short-02-font-weight: 400;
  --cds-body-short-02-line-height: 1.375;
  --cds-body-short-02-letter-spacing: 0;
  --cds-body-long-01-font-size: 0.875rem;
  --cds-body-long-01-font-weight: 400;
  --cds-body-long-01-line-height: 1.42857;
  --cds-body-long-01-letter-spacing: 0.16px;
  --cds-body-long-02-font-size: 1rem;
  --cds-body-long-02-font-weight: 400;
  --cds-body-long-02-line-height: 1.5;
  --cds-body-long-02-letter-spacing: 0;
  --cds-code-01-font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
  --cds-code-01-font-size: 0.75rem;
  --cds-code-01-font-weight: 400;
  --cds-code-01-line-height: 1.33333;
  --cds-code-01-letter-spacing: 0.32px;
  --cds-code-02-font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
  --cds-code-02-font-size: 0.875rem;
  --cds-code-02-font-weight: 400;
  --cds-code-02-line-height: 1.42857;
  --cds-code-02-letter-spacing: 0.32px;
  --cds-heading-01-font-size: 0.875rem;
  --cds-heading-01-font-weight: 600;
  --cds-heading-01-line-height: 1.42857;
  --cds-heading-01-letter-spacing: 0.16px;
  --cds-heading-02-font-size: 1rem;
  --cds-heading-02-font-weight: 600;
  --cds-heading-02-line-height: 1.5;
  --cds-heading-02-letter-spacing: 0;
  --cds-productive-heading-01-font-size: 0.875rem;
  --cds-productive-heading-01-font-weight: 600;
  --cds-productive-heading-01-line-height: 1.28572;
  --cds-productive-heading-01-letter-spacing: 0.16px;
  --cds-productive-heading-02-font-size: 1rem;
  --cds-productive-heading-02-font-weight: 600;
  --cds-productive-heading-02-line-height: 1.375;
  --cds-productive-heading-02-letter-spacing: 0;
  --cds-productive-heading-03-font-size: 1.25rem;
  --cds-productive-heading-03-font-weight: 400;
  --cds-productive-heading-03-line-height: 1.4;
  --cds-productive-heading-03-letter-spacing: 0;
  --cds-productive-heading-04-font-size: 1.75rem;
  --cds-productive-heading-04-font-weight: 400;
  --cds-productive-heading-04-line-height: 1.28572;
  --cds-productive-heading-04-letter-spacing: 0;
  --cds-productive-heading-05-font-size: 2rem;
  --cds-productive-heading-05-font-weight: 400;
  --cds-productive-heading-05-line-height: 1.25;
  --cds-productive-heading-05-letter-spacing: 0;
  --cds-productive-heading-06-font-size: 2rem;
  --cds-productive-heading-06-font-weight: 300;
  --cds-productive-heading-06-line-height: 1.199;
  --cds-productive-heading-06-letter-spacing: 0;
  --cds-productive-heading-07-font-size: 2.625rem;
  --cds-productive-heading-07-font-weight: 300;
  --cds-productive-heading-07-line-height: 1.19;
  --cds-productive-heading-07-letter-spacing: 0;
  --cds-expressive-paragraph-01-font-size: 1.5rem;
  --cds-expressive-paragraph-01-font-weight: 300;
  --cds-expressive-paragraph-01-line-height: 1.334;
  --cds-expressive-paragraph-01-letter-spacing: 0;
  --cds-expressive-heading-01-font-size: 0.875rem;
  --cds-expressive-heading-01-font-weight: 600;
  --cds-expressive-heading-01-line-height: 1.42857;
  --cds-expressive-heading-01-letter-spacing: 0.16px;
  --cds-expressive-heading-02-font-size: 1rem;
  --cds-expressive-heading-02-font-weight: 600;
  --cds-expressive-heading-02-line-height: 1.5;
  --cds-expressive-heading-02-letter-spacing: 0;
  --cds-expressive-heading-03-font-size: 1.25rem;
  --cds-expressive-heading-03-font-weight: 400;
  --cds-expressive-heading-03-line-height: 1.4;
  --cds-expressive-heading-03-letter-spacing: 0;
  --cds-expressive-heading-04-font-size: 1.75rem;
  --cds-expressive-heading-04-font-weight: 400;
  --cds-expressive-heading-04-line-height: 1.28572;
  --cds-expressive-heading-04-letter-spacing: 0;
  --cds-expressive-heading-05-font-size: 2rem;
  --cds-expressive-heading-05-font-weight: 400;
  --cds-expressive-heading-05-line-height: 1.25;
  --cds-expressive-heading-05-letter-spacing: 0;
  --cds-expressive-heading-06-font-size: 2rem;
  --cds-expressive-heading-06-font-weight: 600;
  --cds-expressive-heading-06-line-height: 1.25;
  --cds-expressive-heading-06-letter-spacing: 0;
  --cds-quotation-01-font-family: 'IBM Plex Serif', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', serif;
  --cds-quotation-01-font-size: 1.25rem;
  --cds-quotation-01-font-weight: 400;
  --cds-quotation-01-line-height: 1.3;
  --cds-quotation-01-letter-spacing: 0;
  --cds-quotation-02-font-family: 'IBM Plex Serif', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', serif;
  --cds-quotation-02-font-size: 2rem;
  --cds-quotation-02-font-weight: 300;
  --cds-quotation-02-line-height: 1.25;
  --cds-quotation-02-letter-spacing: 0;
  --cds-display-01-font-size: 2.625rem;
  --cds-display-01-font-weight: 300;
  --cds-display-01-line-height: 1.19;
  --cds-display-01-letter-spacing: 0;
  --cds-display-02-font-size: 2.625rem;
  --cds-display-02-font-weight: 600;
  --cds-display-02-line-height: 1.19;
  --cds-display-02-letter-spacing: 0;
  --cds-display-03-font-size: 2.625rem;
  --cds-display-03-font-weight: 300;
  --cds-display-03-line-height: 1.19;
  --cds-display-03-letter-spacing: 0;
  --cds-display-04-font-size: 2.625rem;
  --cds-display-04-font-weight: 600;
  --cds-display-04-line-height: 1.19;
  --cds-display-04-letter-spacing: 0;
  --cds-legal-01-font-size: 0.75rem;
  --cds-legal-01-font-weight: 400;
  --cds-legal-01-line-height: 1.33333;
  --cds-legal-01-letter-spacing: 0.32px;
  --cds-legal-02-font-size: 0.875rem;
  --cds-legal-02-font-weight: 400;
  --cds-legal-02-line-height: 1.28572;
  --cds-legal-02-letter-spacing: 0.16px;
  --cds-body-compact-01-font-size: 0.875rem;
  --cds-body-compact-01-font-weight: 400;
  --cds-body-compact-01-line-height: 1.28572;
  --cds-body-compact-01-letter-spacing: 0.16px;
  --cds-body-compact-02-font-size: 1rem;
  --cds-body-compact-02-font-weight: 400;
  --cds-body-compact-02-line-height: 1.375;
  --cds-body-compact-02-letter-spacing: 0;
  --cds-heading-compact-01-font-size: 0.875rem;
  --cds-heading-compact-01-font-weight: 600;
  --cds-heading-compact-01-line-height: 1.28572;
  --cds-heading-compact-01-letter-spacing: 0.16px;
  --cds-heading-compact-02-font-size: 1rem;
  --cds-heading-compact-02-font-weight: 600;
  --cds-heading-compact-02-line-height: 1.375;
  --cds-heading-compact-02-letter-spacing: 0;
  --cds-body-01-font-size: 0.875rem;
  --cds-body-01-font-weight: 400;
  --cds-body-01-line-height: 1.42857;
  --cds-body-01-letter-spacing: 0.16px;
  --cds-body-02-font-size: 1rem;
  --cds-body-02-font-weight: 400;
  --cds-body-02-line-height: 1.5;
  --cds-body-02-letter-spacing: 0;
  --cds-heading-03-font-size: 1.25rem;
  --cds-heading-03-font-weight: 400;
  --cds-heading-03-line-height: 1.4;
  --cds-heading-03-letter-spacing: 0;
  --cds-heading-04-font-size: 1.75rem;
  --cds-heading-04-font-weight: 400;
  --cds-heading-04-line-height: 1.28572;
  --cds-heading-04-letter-spacing: 0;
  --cds-heading-05-font-size: 2rem;
  --cds-heading-05-font-weight: 400;
  --cds-heading-05-line-height: 1.25;
  --cds-heading-05-letter-spacing: 0;
  --cds-heading-06-font-size: 2rem;
  --cds-heading-06-font-weight: 300;
  --cds-heading-06-line-height: 1.199;
  --cds-heading-06-letter-spacing: 0;
  --cds-heading-07-font-size: 2.625rem;
  --cds-heading-07-font-weight: 300;
  --cds-heading-07-line-height: 1.19;
  --cds-heading-07-letter-spacing: 0;
  --cds-fluid-heading-03-font-size: 1.25rem;
  --cds-fluid-heading-03-font-weight: 400;
  --cds-fluid-heading-03-line-height: 1.4;
  --cds-fluid-heading-03-letter-spacing: 0;
  --cds-fluid-heading-04-font-size: 1.75rem;
  --cds-fluid-heading-04-font-weight: 400;
  --cds-fluid-heading-04-line-height: 1.28572;
  --cds-fluid-heading-04-letter-spacing: 0;
  --cds-fluid-heading-05-font-size: 2rem;
  --cds-fluid-heading-05-font-weight: 400;
  --cds-fluid-heading-05-line-height: 1.25;
  --cds-fluid-heading-05-letter-spacing: 0;
  --cds-fluid-heading-06-font-size: 2rem;
  --cds-fluid-heading-06-font-weight: 600;
  --cds-fluid-heading-06-line-height: 1.25;
  --cds-fluid-heading-06-letter-spacing: 0;
  --cds-fluid-paragraph-01-font-size: 1.5rem;
  --cds-fluid-paragraph-01-font-weight: 300;
  --cds-fluid-paragraph-01-line-height: 1.334;
  --cds-fluid-paragraph-01-letter-spacing: 0;
  --cds-fluid-quotation-01-font-family: 'IBM Plex Serif', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', serif;
  --cds-fluid-quotation-01-font-size: 1.25rem;
  --cds-fluid-quotation-01-font-weight: 400;
  --cds-fluid-quotation-01-line-height: 1.3;
  --cds-fluid-quotation-01-letter-spacing: 0;
  --cds-fluid-quotation-02-font-family: 'IBM Plex Serif', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', serif;
  --cds-fluid-quotation-02-font-size: 2rem;
  --cds-fluid-quotation-02-font-weight: 300;
  --cds-fluid-quotation-02-line-height: 1.25;
  --cds-fluid-quotation-02-letter-spacing: 0;
  --cds-fluid-display-01-font-size: 2.625rem;
  --cds-fluid-display-01-font-weight: 300;
  --cds-fluid-display-01-line-height: 1.19;
  --cds-fluid-display-01-letter-spacing: 0;
  --cds-fluid-display-02-font-size: 2.625rem;
  --cds-fluid-display-02-font-weight: 600;
  --cds-fluid-display-02-line-height: 1.19;
  --cds-fluid-display-02-letter-spacing: 0;
  --cds-fluid-display-03-font-size: 2.625rem;
  --cds-fluid-display-03-font-weight: 300;
  --cds-fluid-display-03-line-height: 1.19;
  --cds-fluid-display-03-letter-spacing: 0;
  --cds-fluid-display-04-font-size: 2.625rem;
  --cds-fluid-display-04-font-weight: 600;
  --cds-fluid-display-04-line-height: 1.19;
  --cds-fluid-display-04-letter-spacing: 0;
  --cds-color-scheme: light;
  --cds-alert-stroke: #b28600;
  --cds-layer-01-absolute: #ffffff;
  --cds-layer-inverse-absolute: #000000;
  --cds-null-state: none;
  --cds-grid-bg: #ffffff;
  --cds-meter-range-indicator: #a8a8a8;
  --cds-network-diagrams-background-hover: #f1f1f1;
  --cds-tooltip-line-border: #e0e0e0;
  --cds-zone-fill-01: #E8EDEE;
  --cds-zone-stroke-01: #8d8d8d;
  --cds-zone-fill-02: #e0e0e0;
  --cds-zone-stroke-02: #8d8d8d;
  --cds-zone-fill-03: #c6c6c6;
  --cds-zone-stroke-03: #8d8d8d;
  --cds-layer: var(--cds-layer-01, #E8EDEE);
  --cds-layer-active: var(--cds-layer-active-01, #c6c6c6);
  --cds-layer-hover: var(--cds-layer-hover-01, #e8e8e8);
  --cds-layer-selected: var(--cds-layer-selected-01, #e0e0e0);
  --cds-layer-selected-hover: var(--cds-layer-selected-hover-01, #d1d1d1);
  --cds-layer-accent: var(--cds-layer-accent-01, #e0e0e0);
  --cds-layer-accent-hover: var(--cds-layer-accent-hover-01, #d1d1d1);
  --cds-layer-accent-active: var(--cds-layer-accent-active-01, #a8a8a8);
  --cds-field: var(--cds-field-01, #E8EDEE);
  --cds-field-hover: var(--cds-field-hover-01, #e8e8e8);
  --cds-border-subtle: var(--cds-border-subtle-01, #e0e0e0);
  --cds-border-subtle-selected: var(--cds-border-subtle-selected-01, #c6c6c6);
  --cds-border-strong: var(--cds-border-strong-01, #8d8d8d);
}

.cds--chart-holder[data-carbon-theme=g90] {
  --cds-background: #262626;
  --cds-background-active: rgba(141, 141, 141, 0.4);
  --cds-background-brand: #0f62fe;
  --cds-background-hover: rgba(141, 141, 141, 0.16);
  --cds-background-inverse: #E8EDEE;
  --cds-background-inverse-hover: #e8e8e8;
  --cds-background-selected: rgba(141, 141, 141, 0.24);
  --cds-background-selected-hover: rgba(141, 141, 141, 0.32);
  --cds-border-disabled: rgba(141, 141, 141, 0.5);
  --cds-border-interactive: #4589ff;
  --cds-border-inverse: #E8EDEE;
  --cds-border-strong-01: #8d8d8d;
  --cds-border-strong-02: #a8a8a8;
  --cds-border-strong-03: #c6c6c6;
  --cds-border-subtle-00: #525252;
  --cds-border-subtle-01: #525252;
  --cds-border-subtle-02: #6f6f6f;
  --cds-border-subtle-03: #8d8d8d;
  --cds-border-subtle-selected-01: #6f6f6f;
  --cds-border-subtle-selected-02: #8d8d8d;
  --cds-border-subtle-selected-03: #a8a8a8;
  --cds-field-01: #393939;
  --cds-field-02: #525252;
  --cds-field-03: #6f6f6f;
  --cds-field-hover-01: #474747;
  --cds-field-hover-02: #636363;
  --cds-field-hover-03: #5e5e5e;
  --cds-focus: #ffffff;
  --cds-focus-inset: #161616;
  --cds-focus-inverse: #0f62fe;
  --cds-highlight: #0043ce;
  --cds-icon-disabled: rgba(244, 244, 244, 0.25);
  --cds-icon-inverse: #161616;
  --cds-icon-on-color: #ffffff;
  --cds-icon-on-color-disabled: rgba(255, 255, 255, 0.25);
  --cds-icon-primary: #E8EDEE;
  --cds-icon-secondary: #c6c6c6;
  --cds-interactive: #4589ff;
  --cds-layer-01: #393939;
  --cds-layer-02: #525252;
  --cds-layer-03: #6f6f6f;
  --cds-layer-accent-01: #525252;
  --cds-layer-accent-02: #6f6f6f;
  --cds-layer-accent-03: #8d8d8d;
  --cds-layer-accent-active-01: #8d8d8d;
  --cds-layer-accent-active-02: #393939;
  --cds-layer-accent-active-03: #525252;
  --cds-layer-accent-hover-01: #636363;
  --cds-layer-accent-hover-02: #5e5e5e;
  --cds-layer-accent-hover-03: #7a7a7a;
  --cds-layer-active-01: #6f6f6f;
  --cds-layer-active-02: #8d8d8d;
  --cds-layer-active-03: #393939;
  --cds-layer-hover-01: #474747;
  --cds-layer-hover-02: #636363;
  --cds-layer-hover-03: #5e5e5e;
  --cds-layer-selected-01: #525252;
  --cds-layer-selected-02: #6f6f6f;
  --cds-layer-selected-03: #525252;
  --cds-layer-selected-disabled: #a8a8a8;
  --cds-layer-selected-hover-01: #636363;
  --cds-layer-selected-hover-02: #5e5e5e;
  --cds-layer-selected-hover-03: #636363;
  --cds-layer-selected-inverse: #E8EDEE;
  --cds-link-inverse: #0f62fe;
  --cds-link-inverse-active: #161616;
  --cds-link-inverse-hover: #0043ce;
  --cds-link-primary: #78a9ff;
  --cds-link-primary-hover: #a6c8ff;
  --cds-link-secondary: #a6c8ff;
  --cds-link-visited: #be95ff;
  --cds-overlay: rgba(0, 0, 0, 0.65);
  --cds-shadow: rgba(0, 0, 0, 0.8);
  --cds-skeleton-background: #333333;
  --cds-skeleton-element: #525252;
  --cds-support-caution-major: #ff832b;
  --cds-support-caution-minor: #f1c21b;
  --cds-support-caution-undefined: #a56eff;
  --cds-support-error: #ff8389;
  --cds-support-error-inverse: #da1e28;
  --cds-support-info: #4589ff;
  --cds-support-info-inverse: #0043ce;
  --cds-support-success: #42be65;
  --cds-support-success-inverse: #24a148;
  --cds-support-warning: #f1c21b;
  --cds-support-warning-inverse: #f1c21b;
  --cds-text-disabled: rgba(244, 244, 244, 0.25);
  --cds-text-error: #ffb3b8;
  --cds-text-helper: #c6c6c6;
  --cds-text-inverse: #161616;
  --cds-text-on-color: #ffffff;
  --cds-text-on-color-disabled: rgba(255, 255, 255, 0.25);
  --cds-text-placeholder: rgba(244, 244, 244, 0.4);
  --cds-text-primary: #E8EDEE;
  --cds-text-secondary: #c6c6c6;
  --cds-toggle-off: #8d8d8d;
  --cds-spacing-01: 0.125rem;
  --cds-spacing-02: 0.25rem;
  --cds-spacing-03: 0.5rem;
  --cds-spacing-04: 0.75rem;
  --cds-spacing-05: 1rem;
  --cds-spacing-06: 1.5rem;
  --cds-spacing-07: 2rem;
  --cds-spacing-08: 2.5rem;
  --cds-spacing-09: 3rem;
  --cds-spacing-10: 4rem;
  --cds-spacing-11: 5rem;
  --cds-spacing-12: 6rem;
  --cds-spacing-13: 10rem;
  --cds-fluid-spacing-01: 0;
  --cds-fluid-spacing-02: 2vw;
  --cds-fluid-spacing-03: 5vw;
  --cds-fluid-spacing-04: 10vw;
  --cds-label-01-font-size: 0.75rem;
  --cds-label-01-font-weight: 400;
  --cds-label-01-line-height: 1.33333;
  --cds-label-01-letter-spacing: 0.32px;
  --cds-helper-text-01-font-size: 0.75rem;
  --cds-helper-text-01-line-height: 1.33333;
  --cds-helper-text-01-letter-spacing: 0.32px;
  --cds-body-short-01-font-size: 0.875rem;
  --cds-body-short-01-font-weight: 400;
  --cds-body-short-01-line-height: 1.28572;
  --cds-body-short-01-letter-spacing: 0.16px;
  --cds-body-short-02-font-size: 1rem;
  --cds-body-short-02-font-weight: 400;
  --cds-body-short-02-line-height: 1.375;
  --cds-body-short-02-letter-spacing: 0;
  --cds-body-long-01-font-size: 0.875rem;
  --cds-body-long-01-font-weight: 400;
  --cds-body-long-01-line-height: 1.42857;
  --cds-body-long-01-letter-spacing: 0.16px;
  --cds-body-long-02-font-size: 1rem;
  --cds-body-long-02-font-weight: 400;
  --cds-body-long-02-line-height: 1.5;
  --cds-body-long-02-letter-spacing: 0;
  --cds-code-01-font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
  --cds-code-01-font-size: 0.75rem;
  --cds-code-01-font-weight: 400;
  --cds-code-01-line-height: 1.33333;
  --cds-code-01-letter-spacing: 0.32px;
  --cds-code-02-font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
  --cds-code-02-font-size: 0.875rem;
  --cds-code-02-font-weight: 400;
  --cds-code-02-line-height: 1.42857;
  --cds-code-02-letter-spacing: 0.32px;
  --cds-heading-01-font-size: 0.875rem;
  --cds-heading-01-font-weight: 600;
  --cds-heading-01-line-height: 1.42857;
  --cds-heading-01-letter-spacing: 0.16px;
  --cds-heading-02-font-size: 1rem;
  --cds-heading-02-font-weight: 600;
  --cds-heading-02-line-height: 1.5;
  --cds-heading-02-letter-spacing: 0;
  --cds-productive-heading-01-font-size: 0.875rem;
  --cds-productive-heading-01-font-weight: 600;
  --cds-productive-heading-01-line-height: 1.28572;
  --cds-productive-heading-01-letter-spacing: 0.16px;
  --cds-productive-heading-02-font-size: 1rem;
  --cds-productive-heading-02-font-weight: 600;
  --cds-productive-heading-02-line-height: 1.375;
  --cds-productive-heading-02-letter-spacing: 0;
  --cds-productive-heading-03-font-size: 1.25rem;
  --cds-productive-heading-03-font-weight: 400;
  --cds-productive-heading-03-line-height: 1.4;
  --cds-productive-heading-03-letter-spacing: 0;
  --cds-productive-heading-04-font-size: 1.75rem;
  --cds-productive-heading-04-font-weight: 400;
  --cds-productive-heading-04-line-height: 1.28572;
  --cds-productive-heading-04-letter-spacing: 0;
  --cds-productive-heading-05-font-size: 2rem;
  --cds-productive-heading-05-font-weight: 400;
  --cds-productive-heading-05-line-height: 1.25;
  --cds-productive-heading-05-letter-spacing: 0;
  --cds-productive-heading-06-font-size: 2rem;
  --cds-productive-heading-06-font-weight: 300;
  --cds-productive-heading-06-line-height: 1.199;
  --cds-productive-heading-06-letter-spacing: 0;
  --cds-productive-heading-07-font-size: 2.625rem;
  --cds-productive-heading-07-font-weight: 300;
  --cds-productive-heading-07-line-height: 1.19;
  --cds-productive-heading-07-letter-spacing: 0;
  --cds-expressive-paragraph-01-font-size: 1.5rem;
  --cds-expressive-paragraph-01-font-weight: 300;
  --cds-expressive-paragraph-01-line-height: 1.334;
  --cds-expressive-paragraph-01-letter-spacing: 0;
  --cds-expressive-heading-01-font-size: 0.875rem;
  --cds-expressive-heading-01-font-weight: 600;
  --cds-expressive-heading-01-line-height: 1.42857;
  --cds-expressive-heading-01-letter-spacing: 0.16px;
  --cds-expressive-heading-02-font-size: 1rem;
  --cds-expressive-heading-02-font-weight: 600;
  --cds-expressive-heading-02-line-height: 1.5;
  --cds-expressive-heading-02-letter-spacing: 0;
  --cds-expressive-heading-03-font-size: 1.25rem;
  --cds-expressive-heading-03-font-weight: 400;
  --cds-expressive-heading-03-line-height: 1.4;
  --cds-expressive-heading-03-letter-spacing: 0;
  --cds-expressive-heading-04-font-size: 1.75rem;
  --cds-expressive-heading-04-font-weight: 400;
  --cds-expressive-heading-04-line-height: 1.28572;
  --cds-expressive-heading-04-letter-spacing: 0;
  --cds-expressive-heading-05-font-size: 2rem;
  --cds-expressive-heading-05-font-weight: 400;
  --cds-expressive-heading-05-line-height: 1.25;
  --cds-expressive-heading-05-letter-spacing: 0;
  --cds-expressive-heading-06-font-size: 2rem;
  --cds-expressive-heading-06-font-weight: 600;
  --cds-expressive-heading-06-line-height: 1.25;
  --cds-expressive-heading-06-letter-spacing: 0;
  --cds-quotation-01-font-family: 'IBM Plex Serif', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', serif;
  --cds-quotation-01-font-size: 1.25rem;
  --cds-quotation-01-font-weight: 400;
  --cds-quotation-01-line-height: 1.3;
  --cds-quotation-01-letter-spacing: 0;
  --cds-quotation-02-font-family: 'IBM Plex Serif', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', serif;
  --cds-quotation-02-font-size: 2rem;
  --cds-quotation-02-font-weight: 300;
  --cds-quotation-02-line-height: 1.25;
  --cds-quotation-02-letter-spacing: 0;
  --cds-display-01-font-size: 2.625rem;
  --cds-display-01-font-weight: 300;
  --cds-display-01-line-height: 1.19;
  --cds-display-01-letter-spacing: 0;
  --cds-display-02-font-size: 2.625rem;
  --cds-display-02-font-weight: 600;
  --cds-display-02-line-height: 1.19;
  --cds-display-02-letter-spacing: 0;
  --cds-display-03-font-size: 2.625rem;
  --cds-display-03-font-weight: 300;
  --cds-display-03-line-height: 1.19;
  --cds-display-03-letter-spacing: 0;
  --cds-display-04-font-size: 2.625rem;
  --cds-display-04-font-weight: 600;
  --cds-display-04-line-height: 1.19;
  --cds-display-04-letter-spacing: 0;
  --cds-legal-01-font-size: 0.75rem;
  --cds-legal-01-font-weight: 400;
  --cds-legal-01-line-height: 1.33333;
  --cds-legal-01-letter-spacing: 0.32px;
  --cds-legal-02-font-size: 0.875rem;
  --cds-legal-02-font-weight: 400;
  --cds-legal-02-line-height: 1.28572;
  --cds-legal-02-letter-spacing: 0.16px;
  --cds-body-compact-01-font-size: 0.875rem;
  --cds-body-compact-01-font-weight: 400;
  --cds-body-compact-01-line-height: 1.28572;
  --cds-body-compact-01-letter-spacing: 0.16px;
  --cds-body-compact-02-font-size: 1rem;
  --cds-body-compact-02-font-weight: 400;
  --cds-body-compact-02-line-height: 1.375;
  --cds-body-compact-02-letter-spacing: 0;
  --cds-heading-compact-01-font-size: 0.875rem;
  --cds-heading-compact-01-font-weight: 600;
  --cds-heading-compact-01-line-height: 1.28572;
  --cds-heading-compact-01-letter-spacing: 0.16px;
  --cds-heading-compact-02-font-size: 1rem;
  --cds-heading-compact-02-font-weight: 600;
  --cds-heading-compact-02-line-height: 1.375;
  --cds-heading-compact-02-letter-spacing: 0;
  --cds-body-01-font-size: 0.875rem;
  --cds-body-01-font-weight: 400;
  --cds-body-01-line-height: 1.42857;
  --cds-body-01-letter-spacing: 0.16px;
  --cds-body-02-font-size: 1rem;
  --cds-body-02-font-weight: 400;
  --cds-body-02-line-height: 1.5;
  --cds-body-02-letter-spacing: 0;
  --cds-heading-03-font-size: 1.25rem;
  --cds-heading-03-font-weight: 400;
  --cds-heading-03-line-height: 1.4;
  --cds-heading-03-letter-spacing: 0;
  --cds-heading-04-font-size: 1.75rem;
  --cds-heading-04-font-weight: 400;
  --cds-heading-04-line-height: 1.28572;
  --cds-heading-04-letter-spacing: 0;
  --cds-heading-05-font-size: 2rem;
  --cds-heading-05-font-weight: 400;
  --cds-heading-05-line-height: 1.25;
  --cds-heading-05-letter-spacing: 0;
  --cds-heading-06-font-size: 2rem;
  --cds-heading-06-font-weight: 300;
  --cds-heading-06-line-height: 1.199;
  --cds-heading-06-letter-spacing: 0;
  --cds-heading-07-font-size: 2.625rem;
  --cds-heading-07-font-weight: 300;
  --cds-heading-07-line-height: 1.19;
  --cds-heading-07-letter-spacing: 0;
  --cds-fluid-heading-03-font-size: 1.25rem;
  --cds-fluid-heading-03-font-weight: 400;
  --cds-fluid-heading-03-line-height: 1.4;
  --cds-fluid-heading-03-letter-spacing: 0;
  --cds-fluid-heading-04-font-size: 1.75rem;
  --cds-fluid-heading-04-font-weight: 400;
  --cds-fluid-heading-04-line-height: 1.28572;
  --cds-fluid-heading-04-letter-spacing: 0;
  --cds-fluid-heading-05-font-size: 2rem;
  --cds-fluid-heading-05-font-weight: 400;
  --cds-fluid-heading-05-line-height: 1.25;
  --cds-fluid-heading-05-letter-spacing: 0;
  --cds-fluid-heading-06-font-size: 2rem;
  --cds-fluid-heading-06-font-weight: 600;
  --cds-fluid-heading-06-line-height: 1.25;
  --cds-fluid-heading-06-letter-spacing: 0;
  --cds-fluid-paragraph-01-font-size: 1.5rem;
  --cds-fluid-paragraph-01-font-weight: 300;
  --cds-fluid-paragraph-01-line-height: 1.334;
  --cds-fluid-paragraph-01-letter-spacing: 0;
  --cds-fluid-quotation-01-font-family: 'IBM Plex Serif', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', serif;
  --cds-fluid-quotation-01-font-size: 1.25rem;
  --cds-fluid-quotation-01-font-weight: 400;
  --cds-fluid-quotation-01-line-height: 1.3;
  --cds-fluid-quotation-01-letter-spacing: 0;
  --cds-fluid-quotation-02-font-family: 'IBM Plex Serif', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', serif;
  --cds-fluid-quotation-02-font-size: 2rem;
  --cds-fluid-quotation-02-font-weight: 300;
  --cds-fluid-quotation-02-line-height: 1.25;
  --cds-fluid-quotation-02-letter-spacing: 0;
  --cds-fluid-display-01-font-size: 2.625rem;
  --cds-fluid-display-01-font-weight: 300;
  --cds-fluid-display-01-line-height: 1.19;
  --cds-fluid-display-01-letter-spacing: 0;
  --cds-fluid-display-02-font-size: 2.625rem;
  --cds-fluid-display-02-font-weight: 600;
  --cds-fluid-display-02-line-height: 1.19;
  --cds-fluid-display-02-letter-spacing: 0;
  --cds-fluid-display-03-font-size: 2.625rem;
  --cds-fluid-display-03-font-weight: 300;
  --cds-fluid-display-03-line-height: 1.19;
  --cds-fluid-display-03-letter-spacing: 0;
  --cds-fluid-display-04-font-size: 2.625rem;
  --cds-fluid-display-04-font-weight: 600;
  --cds-fluid-display-04-line-height: 1.19;
  --cds-fluid-display-04-letter-spacing: 0;
  --cds-color-scheme: dark;
  --cds-alert-stroke: none;
  --cds-layer-01-absolute: #000000;
  --cds-layer-inverse-absolute: #ffffff;
  --cds-null-state: #161616;
  --cds-grid-bg: #161616;
  --cds-meter-range-indicator: #6f6f6f;
  --cds-network-diagrams-background-hover: #ededed;
  --cds-tooltip-line-border: #393939;
  --cds-zone-fill-01: #262626;
  --cds-zone-stroke-01: #6f6f6f;
  --cds-zone-fill-02: #393939;
  --cds-zone-stroke-02: #6f6f6f;
  --cds-zone-fill-03: #525252;
  --cds-zone-stroke-03: #6f6f6f;
  --cds-layer: var(--cds-layer-01, #E8EDEE);
  --cds-layer-active: var(--cds-layer-active-01, #c6c6c6);
  --cds-layer-hover: var(--cds-layer-hover-01, #e8e8e8);
  --cds-layer-selected: var(--cds-layer-selected-01, #e0e0e0);
  --cds-layer-selected-hover: var(--cds-layer-selected-hover-01, #d1d1d1);
  --cds-layer-accent: var(--cds-layer-accent-01, #e0e0e0);
  --cds-layer-accent-hover: var(--cds-layer-accent-hover-01, #d1d1d1);
  --cds-layer-accent-active: var(--cds-layer-accent-active-01, #a8a8a8);
  --cds-field: var(--cds-field-01, #E8EDEE);
  --cds-field-hover: var(--cds-field-hover-01, #e8e8e8);
  --cds-border-subtle: var(--cds-border-subtle-01, #e0e0e0);
  --cds-border-subtle-selected: var(--cds-border-subtle-selected-01, #c6c6c6);
  --cds-border-strong: var(--cds-border-strong-01, #8d8d8d);
}

.cds--chart-holder[data-carbon-theme=g100] {
  --cds-background: #161616;
  --cds-background-active: rgba(141, 141, 141, 0.4);
  --cds-background-brand: #0f62fe;
  --cds-background-hover: rgba(141, 141, 141, 0.16);
  --cds-background-inverse: #E8EDEE;
  --cds-background-inverse-hover: #e8e8e8;
  --cds-background-selected: rgba(141, 141, 141, 0.24);
  --cds-background-selected-hover: rgba(141, 141, 141, 0.32);
  --cds-border-disabled: rgba(141, 141, 141, 0.5);
  --cds-border-interactive: #4589ff;
  --cds-border-inverse: #E8EDEE;
  --cds-border-strong-01: #6f6f6f;
  --cds-border-strong-02: #8d8d8d;
  --cds-border-strong-03: #a8a8a8;
  --cds-border-subtle-00: #393939;
  --cds-border-subtle-01: #393939;
  --cds-border-subtle-02: #525252;
  --cds-border-subtle-03: #6f6f6f;
  --cds-border-subtle-selected-01: #525252;
  --cds-border-subtle-selected-02: #6f6f6f;
  --cds-border-subtle-selected-03: #8d8d8d;
  --cds-field-01: #262626;
  --cds-field-02: #393939;
  --cds-field-03: #525252;
  --cds-field-hover-01: #333333;
  --cds-field-hover-02: #474747;
  --cds-field-hover-03: #636363;
  --cds-focus: #ffffff;
  --cds-focus-inset: #161616;
  --cds-focus-inverse: #0f62fe;
  --cds-highlight: #002d9c;
  --cds-icon-disabled: rgba(244, 244, 244, 0.25);
  --cds-icon-inverse: #161616;
  --cds-icon-on-color: #ffffff;
  --cds-icon-on-color-disabled: rgba(255, 255, 255, 0.25);
  --cds-icon-primary: #E8EDEE;
  --cds-icon-secondary: #c6c6c6;
  --cds-interactive: #4589ff;
  --cds-layer-01: #262626;
  --cds-layer-02: #393939;
  --cds-layer-03: #525252;
  --cds-layer-accent-01: #393939;
  --cds-layer-accent-02: #525252;
  --cds-layer-accent-03: #6f6f6f;
  --cds-layer-accent-active-01: #6f6f6f;
  --cds-layer-accent-active-02: #8d8d8d;
  --cds-layer-accent-active-03: #393939;
  --cds-layer-accent-hover-01: #474747;
  --cds-layer-accent-hover-02: #636363;
  --cds-layer-accent-hover-03: #5e5e5e;
  --cds-layer-active-01: #525252;
  --cds-layer-active-02: #6f6f6f;
  --cds-layer-active-03: #8d8d8d;
  --cds-layer-hover-01: #333333;
  --cds-layer-hover-02: #474747;
  --cds-layer-hover-03: #636363;
  --cds-layer-selected-01: #393939;
  --cds-layer-selected-02: #525252;
  --cds-layer-selected-03: #6f6f6f;
  --cds-layer-selected-disabled: #a8a8a8;
  --cds-layer-selected-hover-01: #474747;
  --cds-layer-selected-hover-02: #636363;
  --cds-layer-selected-hover-03: #5e5e5e;
  --cds-layer-selected-inverse: #E8EDEE;
  --cds-link-inverse: #0f62fe;
  --cds-link-inverse-active: #161616;
  --cds-link-inverse-hover: #0043ce;
  --cds-link-primary: #78a9ff;
  --cds-link-primary-hover: #a6c8ff;
  --cds-link-secondary: #a6c8ff;
  --cds-link-visited: #be95ff;
  --cds-overlay: rgba(0, 0, 0, 0.65);
  --cds-shadow: rgba(0, 0, 0, 0.8);
  --cds-skeleton-background: #292929;
  --cds-skeleton-element: #393939;
  --cds-support-caution-major: #ff832b;
  --cds-support-caution-minor: #f1c21b;
  --cds-support-caution-undefined: #a56eff;
  --cds-support-error: #fa4d56;
  --cds-support-error-inverse: #da1e28;
  --cds-support-info: #4589ff;
  --cds-support-info-inverse: #0043ce;
  --cds-support-success: #42be65;
  --cds-support-success-inverse: #24a148;
  --cds-support-warning: #f1c21b;
  --cds-support-warning-inverse: #f1c21b;
  --cds-text-disabled: rgba(244, 244, 244, 0.25);
  --cds-text-error: #ff8389;
  --cds-text-helper: #a8a8a8;
  --cds-text-inverse: #161616;
  --cds-text-on-color: #ffffff;
  --cds-text-on-color-disabled: rgba(255, 255, 255, 0.25);
  --cds-text-placeholder: rgba(244, 244, 244, 0.4);
  --cds-text-primary: #E8EDEE;
  --cds-text-secondary: #c6c6c6;
  --cds-toggle-off: #6f6f6f;
  --cds-spacing-01: 0.125rem;
  --cds-spacing-02: 0.25rem;
  --cds-spacing-03: 0.5rem;
  --cds-spacing-04: 0.75rem;
  --cds-spacing-05: 1rem;
  --cds-spacing-06: 1.5rem;
  --cds-spacing-07: 2rem;
  --cds-spacing-08: 2.5rem;
  --cds-spacing-09: 3rem;
  --cds-spacing-10: 4rem;
  --cds-spacing-11: 5rem;
  --cds-spacing-12: 6rem;
  --cds-spacing-13: 10rem;
  --cds-fluid-spacing-01: 0;
  --cds-fluid-spacing-02: 2vw;
  --cds-fluid-spacing-03: 5vw;
  --cds-fluid-spacing-04: 10vw;
  --cds-label-01-font-size: 0.75rem;
  --cds-label-01-font-weight: 400;
  --cds-label-01-line-height: 1.33333;
  --cds-label-01-letter-spacing: 0.32px;
  --cds-helper-text-01-font-size: 0.75rem;
  --cds-helper-text-01-line-height: 1.33333;
  --cds-helper-text-01-letter-spacing: 0.32px;
  --cds-body-short-01-font-size: 0.875rem;
  --cds-body-short-01-font-weight: 400;
  --cds-body-short-01-line-height: 1.28572;
  --cds-body-short-01-letter-spacing: 0.16px;
  --cds-body-short-02-font-size: 1rem;
  --cds-body-short-02-font-weight: 400;
  --cds-body-short-02-line-height: 1.375;
  --cds-body-short-02-letter-spacing: 0;
  --cds-body-long-01-font-size: 0.875rem;
  --cds-body-long-01-font-weight: 400;
  --cds-body-long-01-line-height: 1.42857;
  --cds-body-long-01-letter-spacing: 0.16px;
  --cds-body-long-02-font-size: 1rem;
  --cds-body-long-02-font-weight: 400;
  --cds-body-long-02-line-height: 1.5;
  --cds-body-long-02-letter-spacing: 0;
  --cds-code-01-font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
  --cds-code-01-font-size: 0.75rem;
  --cds-code-01-font-weight: 400;
  --cds-code-01-line-height: 1.33333;
  --cds-code-01-letter-spacing: 0.32px;
  --cds-code-02-font-family: 'IBM Plex Mono', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', monospace;
  --cds-code-02-font-size: 0.875rem;
  --cds-code-02-font-weight: 400;
  --cds-code-02-line-height: 1.42857;
  --cds-code-02-letter-spacing: 0.32px;
  --cds-heading-01-font-size: 0.875rem;
  --cds-heading-01-font-weight: 600;
  --cds-heading-01-line-height: 1.42857;
  --cds-heading-01-letter-spacing: 0.16px;
  --cds-heading-02-font-size: 1rem;
  --cds-heading-02-font-weight: 600;
  --cds-heading-02-line-height: 1.5;
  --cds-heading-02-letter-spacing: 0;
  --cds-productive-heading-01-font-size: 0.875rem;
  --cds-productive-heading-01-font-weight: 600;
  --cds-productive-heading-01-line-height: 1.28572;
  --cds-productive-heading-01-letter-spacing: 0.16px;
  --cds-productive-heading-02-font-size: 1rem;
  --cds-productive-heading-02-font-weight: 600;
  --cds-productive-heading-02-line-height: 1.375;
  --cds-productive-heading-02-letter-spacing: 0;
  --cds-productive-heading-03-font-size: 1.25rem;
  --cds-productive-heading-03-font-weight: 400;
  --cds-productive-heading-03-line-height: 1.4;
  --cds-productive-heading-03-letter-spacing: 0;
  --cds-productive-heading-04-font-size: 1.75rem;
  --cds-productive-heading-04-font-weight: 400;
  --cds-productive-heading-04-line-height: 1.28572;
  --cds-productive-heading-04-letter-spacing: 0;
  --cds-productive-heading-05-font-size: 2rem;
  --cds-productive-heading-05-font-weight: 400;
  --cds-productive-heading-05-line-height: 1.25;
  --cds-productive-heading-05-letter-spacing: 0;
  --cds-productive-heading-06-font-size: 2rem;
  --cds-productive-heading-06-font-weight: 300;
  --cds-productive-heading-06-line-height: 1.199;
  --cds-productive-heading-06-letter-spacing: 0;
  --cds-productive-heading-07-font-size: 2.625rem;
  --cds-productive-heading-07-font-weight: 300;
  --cds-productive-heading-07-line-height: 1.19;
  --cds-productive-heading-07-letter-spacing: 0;
  --cds-expressive-paragraph-01-font-size: 1.5rem;
  --cds-expressive-paragraph-01-font-weight: 300;
  --cds-expressive-paragraph-01-line-height: 1.334;
  --cds-expressive-paragraph-01-letter-spacing: 0;
  --cds-expressive-heading-01-font-size: 0.875rem;
  --cds-expressive-heading-01-font-weight: 600;
  --cds-expressive-heading-01-line-height: 1.42857;
  --cds-expressive-heading-01-letter-spacing: 0.16px;
  --cds-expressive-heading-02-font-size: 1rem;
  --cds-expressive-heading-02-font-weight: 600;
  --cds-expressive-heading-02-line-height: 1.5;
  --cds-expressive-heading-02-letter-spacing: 0;
  --cds-expressive-heading-03-font-size: 1.25rem;
  --cds-expressive-heading-03-font-weight: 400;
  --cds-expressive-heading-03-line-height: 1.4;
  --cds-expressive-heading-03-letter-spacing: 0;
  --cds-expressive-heading-04-font-size: 1.75rem;
  --cds-expressive-heading-04-font-weight: 400;
  --cds-expressive-heading-04-line-height: 1.28572;
  --cds-expressive-heading-04-letter-spacing: 0;
  --cds-expressive-heading-05-font-size: 2rem;
  --cds-expressive-heading-05-font-weight: 400;
  --cds-expressive-heading-05-line-height: 1.25;
  --cds-expressive-heading-05-letter-spacing: 0;
  --cds-expressive-heading-06-font-size: 2rem;
  --cds-expressive-heading-06-font-weight: 600;
  --cds-expressive-heading-06-line-height: 1.25;
  --cds-expressive-heading-06-letter-spacing: 0;
  --cds-quotation-01-font-family: 'IBM Plex Serif', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', serif;
  --cds-quotation-01-font-size: 1.25rem;
  --cds-quotation-01-font-weight: 400;
  --cds-quotation-01-line-height: 1.3;
  --cds-quotation-01-letter-spacing: 0;
  --cds-quotation-02-font-family: 'IBM Plex Serif', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', serif;
  --cds-quotation-02-font-size: 2rem;
  --cds-quotation-02-font-weight: 300;
  --cds-quotation-02-line-height: 1.25;
  --cds-quotation-02-letter-spacing: 0;
  --cds-display-01-font-size: 2.625rem;
  --cds-display-01-font-weight: 300;
  --cds-display-01-line-height: 1.19;
  --cds-display-01-letter-spacing: 0;
  --cds-display-02-font-size: 2.625rem;
  --cds-display-02-font-weight: 600;
  --cds-display-02-line-height: 1.19;
  --cds-display-02-letter-spacing: 0;
  --cds-display-03-font-size: 2.625rem;
  --cds-display-03-font-weight: 300;
  --cds-display-03-line-height: 1.19;
  --cds-display-03-letter-spacing: 0;
  --cds-display-04-font-size: 2.625rem;
  --cds-display-04-font-weight: 600;
  --cds-display-04-line-height: 1.19;
  --cds-display-04-letter-spacing: 0;
  --cds-legal-01-font-size: 0.75rem;
  --cds-legal-01-font-weight: 400;
  --cds-legal-01-line-height: 1.33333;
  --cds-legal-01-letter-spacing: 0.32px;
  --cds-legal-02-font-size: 0.875rem;
  --cds-legal-02-font-weight: 400;
  --cds-legal-02-line-height: 1.28572;
  --cds-legal-02-letter-spacing: 0.16px;
  --cds-body-compact-01-font-size: 0.875rem;
  --cds-body-compact-01-font-weight: 400;
  --cds-body-compact-01-line-height: 1.28572;
  --cds-body-compact-01-letter-spacing: 0.16px;
  --cds-body-compact-02-font-size: 1rem;
  --cds-body-compact-02-font-weight: 400;
  --cds-body-compact-02-line-height: 1.375;
  --cds-body-compact-02-letter-spacing: 0;
  --cds-heading-compact-01-font-size: 0.875rem;
  --cds-heading-compact-01-font-weight: 600;
  --cds-heading-compact-01-line-height: 1.28572;
  --cds-heading-compact-01-letter-spacing: 0.16px;
  --cds-heading-compact-02-font-size: 1rem;
  --cds-heading-compact-02-font-weight: 600;
  --cds-heading-compact-02-line-height: 1.375;
  --cds-heading-compact-02-letter-spacing: 0;
  --cds-body-01-font-size: 0.875rem;
  --cds-body-01-font-weight: 400;
  --cds-body-01-line-height: 1.42857;
  --cds-body-01-letter-spacing: 0.16px;
  --cds-body-02-font-size: 1rem;
  --cds-body-02-font-weight: 400;
  --cds-body-02-line-height: 1.5;
  --cds-body-02-letter-spacing: 0;
  --cds-heading-03-font-size: 1.25rem;
  --cds-heading-03-font-weight: 400;
  --cds-heading-03-line-height: 1.4;
  --cds-heading-03-letter-spacing: 0;
  --cds-heading-04-font-size: 1.75rem;
  --cds-heading-04-font-weight: 400;
  --cds-heading-04-line-height: 1.28572;
  --cds-heading-04-letter-spacing: 0;
  --cds-heading-05-font-size: 2rem;
  --cds-heading-05-font-weight: 400;
  --cds-heading-05-line-height: 1.25;
  --cds-heading-05-letter-spacing: 0;
  --cds-heading-06-font-size: 2rem;
  --cds-heading-06-font-weight: 300;
  --cds-heading-06-line-height: 1.199;
  --cds-heading-06-letter-spacing: 0;
  --cds-heading-07-font-size: 2.625rem;
  --cds-heading-07-font-weight: 300;
  --cds-heading-07-line-height: 1.19;
  --cds-heading-07-letter-spacing: 0;
  --cds-fluid-heading-03-font-size: 1.25rem;
  --cds-fluid-heading-03-font-weight: 400;
  --cds-fluid-heading-03-line-height: 1.4;
  --cds-fluid-heading-03-letter-spacing: 0;
  --cds-fluid-heading-04-font-size: 1.75rem;
  --cds-fluid-heading-04-font-weight: 400;
  --cds-fluid-heading-04-line-height: 1.28572;
  --cds-fluid-heading-04-letter-spacing: 0;
  --cds-fluid-heading-05-font-size: 2rem;
  --cds-fluid-heading-05-font-weight: 400;
  --cds-fluid-heading-05-line-height: 1.25;
  --cds-fluid-heading-05-letter-spacing: 0;
  --cds-fluid-heading-06-font-size: 2rem;
  --cds-fluid-heading-06-font-weight: 600;
  --cds-fluid-heading-06-line-height: 1.25;
  --cds-fluid-heading-06-letter-spacing: 0;
  --cds-fluid-paragraph-01-font-size: 1.5rem;
  --cds-fluid-paragraph-01-font-weight: 300;
  --cds-fluid-paragraph-01-line-height: 1.334;
  --cds-fluid-paragraph-01-letter-spacing: 0;
  --cds-fluid-quotation-01-font-family: 'IBM Plex Serif', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', serif;
  --cds-fluid-quotation-01-font-size: 1.25rem;
  --cds-fluid-quotation-01-font-weight: 400;
  --cds-fluid-quotation-01-line-height: 1.3;
  --cds-fluid-quotation-01-letter-spacing: 0;
  --cds-fluid-quotation-02-font-family: 'IBM Plex Serif', system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', serif;
  --cds-fluid-quotation-02-font-size: 2rem;
  --cds-fluid-quotation-02-font-weight: 300;
  --cds-fluid-quotation-02-line-height: 1.25;
  --cds-fluid-quotation-02-letter-spacing: 0;
  --cds-fluid-display-01-font-size: 2.625rem;
  --cds-fluid-display-01-font-weight: 300;
  --cds-fluid-display-01-line-height: 1.19;
  --cds-fluid-display-01-letter-spacing: 0;
  --cds-fluid-display-02-font-size: 2.625rem;
  --cds-fluid-display-02-font-weight: 600;
  --cds-fluid-display-02-line-height: 1.19;
  --cds-fluid-display-02-letter-spacing: 0;
  --cds-fluid-display-03-font-size: 2.625rem;
  --cds-fluid-display-03-font-weight: 300;
  --cds-fluid-display-03-line-height: 1.19;
  --cds-fluid-display-03-letter-spacing: 0;
  --cds-fluid-display-04-font-size: 2.625rem;
  --cds-fluid-display-04-font-weight: 600;
  --cds-fluid-display-04-line-height: 1.19;
  --cds-fluid-display-04-letter-spacing: 0;
  --cds-color-scheme: dark;
  --cds-alert-stroke: none;
  --cds-layer-01-absolute: #000000;
  --cds-layer-inverse-absolute: #ffffff;
  --cds-null-state: none;
  --cds-grid-bg: #161616;
  --cds-meter-range-indicator: #6f6f6f;
  --cds-network-diagrams-background-hover: #ededed;
  --cds-tooltip-line-border: #6f6f6f;
  --cds-zone-fill-01: #262626;
  --cds-zone-stroke-01: #6f6f6f;
  --cds-zone-fill-02: #393939;
  --cds-zone-stroke-02: #6f6f6f;
  --cds-zone-fill-03: #525252;
  --cds-zone-stroke-03: #6f6f6f;
  --cds-layer: var(--cds-layer-01, #E8EDEE);
  --cds-layer-active: var(--cds-layer-active-01, #c6c6c6);
  --cds-layer-hover: var(--cds-layer-hover-01, #e8e8e8);
  --cds-layer-selected: var(--cds-layer-selected-01, #e0e0e0);
  --cds-layer-selected-hover: var(--cds-layer-selected-hover-01, #d1d1d1);
  --cds-layer-accent: var(--cds-layer-accent-01, #e0e0e0);
  --cds-layer-accent-hover: var(--cds-layer-accent-hover-01, #d1d1d1);
  --cds-layer-accent-active: var(--cds-layer-accent-active-01, #a8a8a8);
  --cds-field: var(--cds-field-01, #E8EDEE);
  --cds-field-hover: var(--cds-field-hover-01, #e8e8e8);
  --cds-border-subtle: var(--cds-border-subtle-01, #e0e0e0);
  --cds-border-subtle-selected: var(--cds-border-subtle-selected-01, #c6c6c6);
  --cds-border-strong: var(--cds-border-strong-01, #8d8d8d);
}

.cds--chart-holder.fullscreen,
.cds--chart-holder:-webkit-full-screen {
  /* !important is used here to get closer to
  a truly fullscreen experience */
  width: 100% !important;
  height: 100% !important;
  max-width: unset !important;
  max-height: unset !important;
  padding: 2em;
}

.cds--cc--chart-wrapper {
  overflow: visible;
}

/*# sourceMappingURL=styles.css.map */