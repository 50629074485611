

//Used to indicate a question block within the various internal forms
.internalFormPanel {
  width: 50%;
  padding-left: 1rem;
  border-left: 3px solid #005EB8;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

//Some form elements want some extra gappage, assign this extra class to them
.extraSpaceBelow {
  margin-bottom: 2rem;
  margin-top: 1rem;
}

//On our form style pages adds in some padding at the top of the page to ensure the first lines of text all match
.rightBodyContent {
 padding-top: 1rem;
}

.rightBodyContent p {
  margin-bottom: 5px;
  font-size: 14px;
}

.rightBodyContent h3 {
  margin-bottom: 5px;
}



.fileList {
  width: 100%;
  // background-color: yellow;
}

.ms-GroupHeader-title {
  font-size: 14px !important;

}

h3 {
  font-size: 16px;
  font-weight: bold;
}

.tooltipIcon {
  position: relative;
  top: 3px;
}

.bx--file--label {
  outline: transparent none medium;
  position: relative;
  align-items: center;
  font-size: 14px;
  font-family: inherit;
  color: #323130;
  display: inline-block;
  flex-grow: 1;
  font-weight: 600;
  padding: 0px 4px 0px 10px;
  border: medium none;
  background-color: transparent;
  border-radius: 2px;
  line-height: 28px;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
}

.noPadding {
  padding: 0;
}

.bx--grid {
  margin-left: 0 !important;
}

//left bar against calendar
.filesystemContent {
  border-left: unset;
}

.bx--content {
  margin-top: 0 !important;
  padding-top: 1rem !important;
}

.ms-GroupHeader {
  border-top: none;
  border-bottom: 1px solid #E8EDEE;
  //  background-color: #E8EDEE;
}

.ms-GroupSpacer {
  width: 1rem !important;

}

g.gauge-numbers {
  display: none;
}

.ms-Slider-value {
  width: 75px !important;
}


.mainLogo {
  //margin-left: 2rem;
  //margin-top: 1rem;
  //margin-bottom: 1rem;
  //margin-right: 1rem;
  //height: 5rem;
}

.aeccLogo {
  z-index: 1;
  position:  absolute;
  top: 0;
  right: 0;
  padding-right: 1rem;
  padding-top: 1rem;

}

.mainLogoContainer {
  background-color: #006ab4;
  width: 100%;
  color: white;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom:1rem;
  padding-right: 2rem;
}

.mainLogoContainerRestrictWidth {
//  max-width: 1600px;
}

.mainLogoContainer p {
  color: white;

}



.isGrey {
  cursor: not-allowed;
  color: #4c4c4c;
}


.bx--grid {
  max-width: unset !important;
}

